import React from 'react'
import PostGrid from '../ChatBotRetrievalDocs/RetrievalBot/PostGrid/PostGrid'

const MyBots = ({ combinations, setCombinations, bot_id, reload, setTabName, reloadUser, user_id, selected_combinations, set_selected_combinations }) => {

  return (
    <div>
      <PostGrid combinations={combinations} setCombinations={setCombinations} bot_id={bot_id} reload={reload} reloadUser={reloadUser} user_id={user_id} selected_combinations={selected_combinations} set_selected_combinations={set_selected_combinations} setTabName={setTabName} />
    </div>
  )
}

export default MyBots