import axios from "axios";

const API = axios.create({
  baseURL: "/api/eval_bot",
});

//register
const createBot = async (botData, token) => {

  const response = await API.post("/create", botData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};


//get_bot by creator
const getBotbyOrg = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/${id}`, config);
  return response.data;
};


//get combinations
const getCombinations = async (id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/combinations/${id}`, config);
  return response.data.combinations;
};




//run evals
const runEvals = async (ids, token) => {
  const { orgId, user_id, bot_id, combination_id } = ids

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/runeval/${orgId}/${user_id}/${bot_id}/${combination_id}`, config);
  return response.data;
};





//run evals
const generatePrompts = async (bot_id, token) => {

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/generate/mutations/${bot_id}`, config);
  localStorage.setItem("prompts", JSON.stringify(response.data))
  return response.data;
};


//run evals
const generateBasePrompt = async (problem_statement, token) => {

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/generate/mutations/${""}`, config);
  localStorage.setItem("prompts", JSON.stringify(response.data))
  return response.data;
};





//run evals
const initializeCombinations = async (data, token) => {
  const { user_id, bot_id, orgId } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/init/${orgId}/${user_id}/${bot_id}`, config);
  return response.data;
};




//Initialize Mutations
const initializeMutations = async (data, token) => {
  const { bot_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(`/init/mutations/${bot_id}`, data, config);
  return response.data;
};

//Get Selected Combinations
const get_selected_combinations = async (bot_id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/selectedCombinations/${bot_id}`, config);
  return response.data;
};


//Get Selected Combinations
const makeDefault = async (data, token) => {
  const { bot_id, combination_id } = data;
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.get(`/makedefault/${bot_id}/${combination_id}`, config);
  return response.data;
};


//delete chatbot
const deleteChatbot = async (id, token) => {

  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await API.delete(`/combinationset/${id}`, config);
  return response.data;
};



//upload sources
const uploadSources = async (data, token) => {
  const { orgId, user_id, bot_id } = data.ids
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await API.post(`/update/${orgId}/${user_id}/${bot_id}`, data.formData, config);
  return response.data;
};


//get data types
const getDataTypes = async (data, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"))
  }
  console.log(data);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(`/datatypes`, data, config);
  return response.data;
};



const chatbotService = {
  createBot,
  getBotbyOrg,
  getCombinations,
  runEvals,
  deleteChatbot,
  initializeCombinations,
  generatePrompts,
  initializeMutations,
  get_selected_combinations,
  makeDefault,
  uploadSources,
  getDataTypes
};

export default chatbotService;
