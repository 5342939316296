import React from 'react';
import './CurrentPlan.css'; // Make sure to create this CSS file
import { useSelector } from 'react-redux';
import standard from "../../../../Assets/standard.jpg"
import pro from "../../../../Assets/pro.jpg"
import enterprise from "../../../../Assets/enterprise.jpg"
import axios from 'axios';
const CurrentPlan = ({ setValue, userId }) => {
  // Dummy items data
  const { user } = useSelector(state => state.auth);
  const items_free = [
    { name: 'Access to all models', included: false },
    { name: 'Embeddings', included: false },
    { name: 'Prompt optimization', included: false },
    { name: 'Deploy application', included: false }
  ];

  const items_standard = [
    { name: 'Retrieval optimization including the identification of the right chunking strategy, retrieval/reranking, embedding model, and LLM.', included: true },
    { name: 'Prompt optimization to automatically give the right instruction to the LLM.', included: true },
    { name: 'Metadata filtering to retrieve only the documents you want.', included: true },
    { name: 'Problem statement information extraction to land on the best parameters quickly.', included: true }
  ];

  const items_pro = [
    { name: 'Everything in Starter Package plus the following:', included: true },
    { name: 'Access to dual fine-tuning of retrieval, and LLM', included: true },
    { name: 'Access to Beta Features', included: true }
  ];


  const handleCancel = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // Other headers if needed
      },
    };

    axios.get(`/api/payments/subscribe/cancel/${userId}`,
      config
    )
      .then((response) => {
        console.log(response.data)

      })
      .catch((error) => {
        console.log(error);

      });
  }


  return (
    <div className='currentplan'>
      {user?.user?.subscription === "starter" &&
        <div className='currentplan-container'>
          <div className="current-plan">
            <h2>Free Plan</h2>
            <div className="plan-details">
              <ul className="plan-items">
                {items_free.map((item, index) => (
                  <li key={index} className={`plan-item ${!item.included ? 'excluded' : ''}`}>
                    <span className="icon">{!item.included ? '✖' : '✔'}</span>
                    {item.name}
                  </li>
                ))}
              </ul>
              <button onClick={() => setValue("2")}>Change Plan</button>
            </div>
          </div>
        </div>
      }
      {user?.user?.subscription === "standard" &&
        <div className='package-container'>
          <div className='package_'>
            <div className='package_header'>
              <img src={standard} />
              <h2>Standard</h2>
            </div>

            <div className='package_price'>
              <div className='package_price_info'>
                <p className='priceStyles'>USD 400</p>
                <div className='per-month'>
                  <p>per</p>
                  <p>month</p>
                </div>
              </div>

            </div>

            <div className='package_includes'>
              <p className='this-inlcudes'>This includes:</p>
              <p > <span className="checkmark">✔</span> Retrieval optimization including the identification of the right chunking strategy, retrieval/reranking, embedding model, and LLM.</p>
              <p ><span className="checkmark">✔</span> Prompt optimization to automatically give the right instruction to the LLM.</p>
              <p ><span className="checkmark">✔</span> Metadata filtering to retrieve only the documents you want.</p>
              <p ><span className="checkmark">✔</span> Problem statement information extraction to land on the best parameters quickly.</p>
            </div>
          </div>
          <button onClick={handleCancel}>Cancel</button>


        </div>
      }
      {/* ********************** PRO ******************* */}
      {user?.user?.subscription === "pro" &&
        <div className='package-container'>
          <div className='package_'>
            <div className='package_header'>
              <img src={pro} />
              <h2>Pro</h2>
            </div>

            <div className='package_price'>
              <div className='package_price_info'>
                <p className='priceStyles'>USD 700</p>
                <div className='per-month'>
                  <p>per</p>
                  <p>month</p>
                </div>
              </div>
            </div>

            <div className='package_includes'>
              <p className='this-inlcudes'>This includes:</p>
              <p > <span className="checkmark">✔</span> Everything in Starter Package plus the following:</p>
              <p ><span className="checkmark">✔</span> Access to dual fine-tuning of retrieval, and LLM</p>
              <p ><span className="checkmark">✔</span> Access to Beta Features</p>
            </div>
            <button onClick={handleCancel}>Cancel</button>

          </div>

        </div>
      }

      {
        user?.user?.subscription === "enterprise" && <div className='package-container'>
          <div className='package_'>
            <div className='package_header'>
              <img src={enterprise} />
              <h2>Enterprise</h2>
            </div>

            <div className='package_price'>
              <div className='package_price_info'>
                <p className='priceStyles'>Contact to sales</p>

              </div>
              <button onClick={() => { }}>Contact to sales</button>
              <button onClick={handleCancel}>Cancel</button>
            </div>

            <div className='package_includes'>
              <p className='this-inlcudes'>This includes:</p>
              <p > <span className="checkmark">✔</span>Everything in Pro Package plus the following:</p>
              <p ><span className="checkmark">✔</span> Extensive Customer Support</p>

            </div>
          </div>

        </div>
      }
    </div>

  );
};

export default CurrentPlan;
