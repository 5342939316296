import React from "react";
import "./Sidebar.css"
const SideBar = ({ type, open_sidebar, set_open_sidebar, selectedKey }) => {
    const metadataFilteringInfo = "Metadata such as tags or fields can be associated with documents when they are indexed in a vector database. For example, a document could have metadata like {'field': 'LLM development'} {'field': 'Business'}."
    const apikey = selectedKey || "";
    const cmd = `curl -X POST https://app.queryloop.com/api/developer/use/ -H "Content-Type: application/json" -H "Authorization: SecretKey ${apikey}" -d "{\"query\": \"What is this about?\"}"`

    const handle_close_sidebar = () => {
        set_open_sidebar(false);
        console.log(open_sidebar)
    }

    return (
        <div className={`side-bar ${open_sidebar ? 'open' : ''}`}>
            <div className='side-bar_contents'>
                <button id="btn-close-sidebar" className="close-btn" onClick={handle_close_sidebar}>X</button>
                {type === "chunk_size" && <div className='side-bar_contents_info chunk'>
                    <h1>Chunk Size</h1>
                    <p>When you're dealing with long pieces of text, it's important to break them into smaller parts. This might sound simple, but there can be some complexity involved. Essentially, you want to keep related pieces of text together, and what's considered 'related' can vary depending on the text type. This article explores different methods for doing that.</p>
                    <p>At a basic level, text splitters work like this:</p>
                    <p><span>1.</span> Break the text into small, meaningful chunks, often sentences.</p>
                    <p><span>2.</span> Combine these small chunks into a larger chunk until it reaches a certain size (measured by some criteria).</p>
                    <p><span>3.</span> Once it reaches that size, make it a separate piece of text and start a new chunk with some overlap to maintain context between them</p>
                </div>}
                {type === "metrics_type" && <div className='side-bar_contents_info'>
                    <h1>Metric</h1>
                    <p><span>Cosine Similarity:</span> Cosine similarity measures the cosine of the angle between two vectors. It is often used in text similarity as it cares about the direction but not the magnitude of the vectors. Cosine similarity ranges from -1 to 1, with 1 indicating identical vectors. It is computed as the dot product of the vectors divided by the product of their L2 norms. Cosine similarity excels at judging semantic textual similarity.</p>
                    <p><span>Euclidean Distance:</span>
                        The Euclidean distance between two vectors is the L2 norm of their difference. It corresponds to the straight line distance between two points in multi-dimensional space. Lower Euclidean distances indicate more similarity. It is sensitive to the magnitude of the vectors. Euclidean distance is commonly used in recommendation systems and retrieval tasks.</p>
                    <p><span>Dot Product:</span> The dot product between two vectors measures how aligned they are. It is computed by multiplying corresponding elements and summing them. A higher dot product indicates greater similarity. Dot product combines both magnitude and orientation information. It is used in neural networks and similarity calculation.</p>
                    <p><span>Hybrid Metrics:</span> Hybrid similarity metrics combine two or more metrics, getting the best of both worlds. For example, you can take a weighted average of cosine similarity and Euclidean distance. This allows using orientation from cosine and magnitude from Euclidean. Hybrids can also combine semantic similarity with string similarity. Overall, hybrids allow customizing similarity to fit the use case.</p>

                </div>}
                {type === "post_retrieval" && <div className='side-bar_contents_info post-retrieval'>
                    <h1>Post Retrieval</h1>
                    <p><span>Maximal Marginal Relevance (MMR):</span> MMR, or Maximal Marginal Relevance, is a retrieval technique designed to strike a balance between relevance and diversity in selecting a set of documents. It operates by iteratively choosing the document with the highest marginal relevance, which means it's the most relevant but also the least similar to the documents already selected. This approach prevents the retrieval of multiple duplicates or documents covering identical content. The MMR score itself balances the similarity to the query and the dissimilarity to previously picked documents, with the flexibility to configure this tradeoff. It's a valuable strategy used in the Vald vector store for effective document retrieval.</p>

                </div>}
                {type === "embedding_model" && <div className='side-bar_contents_info embedding'>
                    <h1>Embedding Model</h1>
                    <p>Embedding optimization encompasses a range of techniques aimed at enhancing the quality of vector embeddings to better capture semantic meaning and similarities. Several key approaches include leveraging more extensive and diverse datasets during training to produce higher-quality embeddings with broader coverage. Additionally, increasing the model size, such as using larger transformer models like GPT-3, can lead to embeddings that generalize more effectively. Fine-tuning embeddings on domain-specific data is another effective strategy for improving performance within a particular domain. Dimensionality reduction techniques, such as Principal Component Analysis (PCA) and autoencoders, can concentrate semantic information by reducing embedding dimensions, potentially enhancing results. Quantization methods transform embeddings into low-precision quantized formats, conserving space while retaining accuracy. Ensembling involves averaging embeddings from different models and sources to enhance robustness. Iterative re-embedding of vectors can be employed for quality enhancement through self-supervision. Finally, creating richer embeddings is possible by combining various multimodal signals, such as text, image, and audio. Additionally, hardware optimization using GPUs, TPUs, and specialized hardware can accelerate the generation of embeddings.</p>
                </div>}
                {type === "retrieval_method" && <div className='side-bar_contents_info retrieval-method'>
                    <h1>Retrieval Methods</h1>
                    <p><span>Basic: </span> Basic retrieval methods like vector similarity search embed queries and documents in a high-dimensional vector space. Retrieval is done by finding the closest embedded documents to the query based on distance metrics like cosine similarity.</p>
                    <br />
                    <p><span>Some limitations of basic retrieval:</span></p>
                    <p>The quality is dependent on the embeddings properly capturing semantics. Small changes in wording of the query or document can change results.</p>
                    <p>Only retrieving the top few most similar documents can miss relevant results further away in vector space.</p>
                    <br />
                    <br />
                    <p><span>Sentence Window: </span> Sentence Window Addresses some of these limitations by embedding smaller units of text - sentences or paragraphs rather than full documents. This allows retrieving specific relevant sections of documents rather than full documents.</p>
                    <p><span>Multi Query: </span> generates multiple variations of the original query to get different perspectives. Each perspective retrieves a set of results, which are combined into a single unique set. This helps overcome issues with small query wording changes and improves coverage beyond just the top few nearest neighbors.</p>
                </div>}
                {type === "llm" && <div className='side-bar_contents_info llm'>
                    <h1>LLM</h1>
                    <p>LLM (large language model) is a neural network trained on a large corpus of text data to generate coherent natural language.</p>
                    <p>Currently we support GPT 3.5 Turbo model</p>
                    <p>LLama 2 and other LLMs are coming soon.</p><br />
                    <p><span>Some key characteristics of LLMs:</span></p>
                    <p><span>Large scale - </span>trained on gigabytes to terabytes of text data. Popular examples include GPT-3, Jurassic-1, PaLM.</p>
                    <p><span>Generative - </span>can generate fluent continuations of text prompts. This allows conversational applications like chatbots.</p>
                    <p><span>Pretrained - </span>LLMs are first pretrained on large unlabeled corpora before fine-tuning for downstream tasks. Pretraining learns general language representations.</p>
                    <p><span>Self-supervised - </span>LLMs are trained via self-supervision objectives like auto-regressive language modeling, where the model predicts the next token in a sequence. No human labeling needed.</p>
                    <p><span>Transfer learning - </span>LLMs can be fine-tuned on smaller labeled datasets to adapt to specific NLP tasks like classification. The pretrained weights provide a strong initialization.</p>
                    <p><span>Transformer-based - </span>most modern LLMs use a transformer architecture. This allows modeling long-range dependencies in text.</p>
                </div>}
                {type === "top_k" && <div className='side-bar_contents_info top-k'>
                    <h1>Retrieve top "K" number of documents</h1>
                    <p>This defines a limit to retrieved number of documents. For top k = 5, only 5 documents will be retreived.</p>
                </div>}
                {type === "metadata_filtering" && <div className='side-bar_contents_info top-k'>
                    <h1>Metadata Filtering</h1>
                    <p>{metadataFilteringInfo}</p>
                    <p>When doing a similarity search, you can specify filters on the metadata to narrow down the results</p>
                    <h3 style={{ width: "250px" }}>{"filter = {'field': 'fullstack'}"}</h3>
                    <p>This would retrieve only resumes of field "fullstack". Other documents would be filtered out, even if they were close matches textually.</p>
                    <br /><br />
                    <h1>Current Available Metadata:</h1><br />
                    <p><span>Creation Date - </span> Document creation date.</p>
                    <p><span>Document Category - </span> A document category is like putting documents into big groups, such as "technology," "experience," or "education." Metadata filtering tags are like little labels that we can add to a document to tell us more about what's inside. For instance, we can tag an article with labels like "work experience" and "skills" to show what it's about in detail.</p>
                    <p>We can also tag document as "Resume" or "Cover letter" to differentiate between them.</p>
                    <p>These tags help us find and organize documents in different ways. For example, if we want to see all the resumes about "education" in "Computer Science" we can use the tag to filter and get a list. Or, if we're interested in "artificial intelligence" we can use its tag to find all documents related to AI.</p>

                </div>}
                {type === "problem_statement" && <div className='side-bar_contents_info top-k'>
                    <h1>Problem Statement</h1>
                    <p>A problem statement is a clear and concise description of an issue or challenge that needs to be addressed or solved. It outlines the problem's key aspects, the context in which it occurs, and its significance.</p>
                    <br />
                    <p><span>Example Problem Statement:</span></p>
                    <p>I want to develop an AI-powered system specialized in retrieving resumes from a large database.</p>
                    <p>The system should proficiently handle resumes in multiple formats, including PDF and Word Docs, and be capable of processing large volumes of documents.</p>
                    <p>System should be able to retrieve correct resume based on user query and generate accurate responses.</p>
                    <p><span>For Example: </span></p>
                    <p><span>Question:- </span> Retrieve candidates with 4 years of experience in Python, and Javascript for Fullstack Development.</p>
                    <p><span>Answer:- </span> Based on the resumes 2 candidates fit the criteria for Fullstack Development.</p>
                    <p>John Doe, with six years of experience in JavaScript and Python, has worked on the development and deployment of multiple full-stack projects.</p>
                    <p>James A., possessing four years of experience in JavaScript and Python, has contributed to the development and deployment of multiple full-stack projects.</p>
                </div>}
                {type === "golden_responses" && <div className='side-bar_contents_info top-k'>
                    <h1>Golden Responses</h1>
                    <p>Golden responses are essential for evaluation as they serve as benchmark answers against which chatbot-generated responses are measured to assess their performance and accuracy.</p>
                    <p><span style={{ fontSize: "1.3rem" }}>Example:</span></p>
                    <p><span>Question:- </span> Retrieve candidates with 4 years of experience in Python, and Javascript for Fullstack Development.</p>
                    <p><span>Answer:- </span> Based on the resumes 2 candidates fit the criteria for Fullstack Development.</p>
                    <p>John Doe, with six years of experience in JavaScript and Python, has worked on the development and deployment of multiple full-stack projects.</p>
                    <p>James A., possessing four years of experience in JavaScript and Python, has contributed to the development and deployment of multiple full-stack projects.</p>
                </div>}
                {type === "base_prompt" && <div className='side-bar_contents_info top-k'>
                    <h1>Prompt</h1>
                    <p>A prompt is essentially a set of instructions or a query that you provide to a language model to specify the kind of response you want from it. It's like telling the model what you'd like to know or discuss. For instance, you can use a prompt to ask a language model to summarize a text, translate a sentence, or answer a question. The quality and clarity of the prompt are crucial in obtaining the desired output from the model. It serves as a way to guide the model's responses, making it a key component in interacting with AI systems.</p>
                </div>}
                {type === "optimization_method" && <div className="side-bar_contents_info top-k">
                    <h1>Optimization Method</h1><br />
                    <p><span>Grid Search:</span></p>
                    <p>This method involves methodically testing every possible combination of parameters you've selected. Imagine it like checking every square on a chessboard to find the best move. The app will use your ideal answers as a target and try each set of parameters to see which one gets the closest to your target response. It's thorough but can take longer, especially if there are many combinations to check.</p>
                    <br /><br />
                    <p><span>1-D Search:</span></p>
                    <p>Think of this method as a step-by-step approach. It starts by adjusting one parameter at a time and finding the best setting for that specific parameter. Once it finds the best setting for the first parameter, it 'locks' that in place and moves on to optimize the next one, building on the success of the previous step. This way, it goes through each parameter individually, which can be faster than checking all possible combinations at once but might not be as thorough as the Grid Search.</p>
                    <br /><br />
                    <p><span>Random Search:</span></p>
                    <p>This method is a bit like a lottery. Instead of methodically checking each combination, it randomly selects different parameter settings to try. Behind the scenes, the app uses smart algorithms to guide this random selection, learning from each attempt to improve the chances of finding a good combination. This can sometimes find a good solution faster than Grid Search, especially when there are too many combinations to test each one individually. However, it relies on chance, so it might not always find the best possible combination like Grid Search does.</p>
                    <br /><br />
                    <p><span>Each method has its pros and cons, and the right choice might depend on how many parameters you're working with, how much time you have, and what kind of precision you need.</span></p>
                </div>}
                {type === "question_metadata" && <div className="side-bar_contents_info top-k">
                    <h1>Document Categorization</h1><br />
                    <p>Will your queries focus on specific document types? If so, consider using metadata filtering to enhance retrieval accuracy and ensure your queries target the most relevant documents</p>
                </div>}
                {type === "question_metadata-subcontents" && <div className="side-bar_contents_info top-k">
                    <h1>Query Focus</h1><br />
                    <p>Are your queries designed to explore a single document type per query, or do they encompass all document types simultaneously? Your choice here helps determine the precision and scope of the retrieval process</p>
                </div>}
                {type === "question_metadata_info" && <div className="side-bar_contents_info top-k">
                    <h1>Document Category Weighting</h1><br />
                    <p>Indicate whether you assign equal significance to each document category or prioritize certain ones for credibility and relevance. This helps tailor the LLM's prompt to better align with your specific requirements</p>
                </div>}
                {type === "question_twr" && <div className="side-bar_contents_info top-k">
                    <h1>Recent Documents</h1><br />
                    <p>Your preference will be incorporated into the prompt provided to the LLM</p>
                </div>}
                {type === "question_embedding-model" && <div className="side-bar_contents_info top-k">
                    <h1>Embedding Optimization</h1><br />
                    <p></p>
                </div>}
                {type === "question_sentence-window" && <div className="side-bar_contents_info top-k">
                    <h1>Text Chunking and Retrieval Impact</h1><br />
                    <p>All documents are segmented into chunks before being stored. The chunks most relevant to a user's query are retrieved and passed to a language model. If you think that segmenting the text might hinder its comprehensibility, we recommend using a <b>'sentence window'</b> retrieval method. This approach appends the text preceding and following the retrieved segment to provide better context.</p>
                    <p>If your documents are small, it's advisable to opt for larger chunk sizes to ensure that your documents remain unchunked.</p>
                </div>}
                {type === "question_response-length" && <div className="side-bar_contents_info top-k">
                    <h1>Response Length</h1><br />
                    <p>Your preference will be incorporated into the prompt provided to the LLM</p>
                </div>}
                {type === "question_provide-sources" && <div className="side-bar_contents_info top-k">
                    <h1>Sources</h1><br />
                    <p>Your preference will be incorporated into the prompt provided to the LLM</p>
                </div>}
                {type === "question_response-tone" && <div className="side-bar_contents_info top-k">
                    <h1>Tone of the response</h1><br />
                    <p>Your preference will be incorporated into the prompt provided to the LLM</p>
                </div>}
                {type === "question_MMR" && <div className="side-bar_contents_info top-k">
                    <h1>Handling Repetitive Content</h1><br />
                    <p>For minimizing content repetition and enhancing content diversity in retrieval, we suggest implementing the MMR <b>(Maximal Marginal Relevance)</b> reranking method</p>
                </div>}
                {type === "question_memory" && <div className="side-bar_contents_info top-k">
                    <h1>Memory</h1><br />
                    <p>This option will enable memory feature in the LLM</p>
                </div>}
                {type === "question_chunk-size" && <div className="side-bar_contents_info top-k">
                    <h1>Chunking Strategy</h1><br />
                    <p>For shorter documents, consider using larger chunk sizes to fit entire documents in one chunk, thus avoiding potential context loss from chunking</p>
                </div>}
                {type === "question_latency" && <div className="side-bar_contents_info top-k">
                    <h1>Latency</h1><br />
                    <p>For applications where delay is a critical factor, it's advised to avoid using a multiquery retriever. Although it's designed to enhance retrieval accuracy by rephrasing queries for better results, it involves multiple LLM calls and consequently increases latency</p>
                </div>}
                {type === "question_references" && <div className="side-bar_contents_info top-k">
                    <h1>Cultural Sensitivity and Bias Management</h1><br />
                    <p>It's crucial to identify and manage cultural nuances, biases, or any discriminatory language in your documents. Your specific requirements, like avoiding hate speech, will be considered to ensure the application processes content responsibly and aligns with ethical standards</p>
                </div>}
                {type === "additional-information" && <div className="side-bar_contents_info top-k">
                    <h1>Understanding the Additional Details for LLM Prompt</h1><br />
                    <br /><p><span>Tone: </span> The tone of the LLM's responses is influenced by your answer to the question: "Do you have specific preferences for the tone or style of the responses?" Depending on whether you select a formal, informal, or persuasive tone, the LLM will adjust its language style accordingly to meet your specified preference.</p>
                    <br /><p><span>Response Length: </span> The length and detail of the LLM's responses are tailored based on your input to the question: "Would you prefer the LLM to provide detailed or concise answers?" If you opt for concise answers, the LLM aims to be brief and to the point. For detailed answers, expect more in-depth and elaborate responses.</p>
                    <br /><p><span>Return Sources: </span> This aspect is determined by your response to: "Do you want responses to reference or cite their sources for added reliability?" If you choose 'Yes', the LLM will include references or citations in its responses to enhance credibility and reliability.</p>
                    <br /><p><span>Recent Documents: </span> The focus on recent documents in the LLM's responses comes from your answer to: "Do you prefer to prioritize more recent documents in your responses?" Selecting 'Yes' will prompt the LLM to give more weight to newer documents in its retrieval and response process, ensuring that the most current information is used.</p>
                </div>}
                {type === "eval" && <div className="side-bar_contents_info top-k">
                    <h1>Evaluation</h1><br />
                    <p>Evaluation is based on the following metrics: </p>
                    <br />
                    <p><span>Overall Accuracy: </span> The overall accuracy is the total of all the scores from the metrics below, adjusted to be out of 10.</p>
                    <p><span>Feedback: </span> Overall feedback on how the response compares to the golden response.</p>
                    <p><span>Retrieval Score (0-2): </span> If the documents provided to the LLM through this flow contain sufficient information to answer the question.</p>
                    <h4 className="select-info">
                        <span>0 - </span> If there was no information in the sources. <br />
                        <span>1 - </span> If only partial information was present. <br />
                        <span>2 - </span> If complete information was available.</h4>
                    <br />

                    <p><span>Correctness (0,1,2): </span> Evaluates whether the response includes all the information provided in the golden response:</p>
                    <h4 className="select-info">
                        <span>0 - </span> If the response contains none or minimal relevant information from the golden response.<br />
                        <span>1 - </span> If the response includes partial information from the golden response. <br />
                        <span>2 - </span> If the response perfectly aligns with the golden response.
                    </h4>
                    <br />

                    <p><span>Prompt Adherence (0-1): </span> How well did the answer follow the instructions you chose during creation, such as returning sources, following tone, and prioritizing recent documents?</p><br />
                    <p><span>Response Length (0,1): </span> Evaluates whether the answer adheres to your guidelines for output length, whether you prefer brief, concise, or detailed responses.</p><br />
                    <p><span>Faithfulness (0,1): </span> Evaluates whether the generated answer truly derives from the provided context or if the bot is creating responses independently, without relying on the given information.</p><br />
                </div>}

                {
                    type === "apikey" && <div className="side-bar_contents_info top-k">
                        <h1>Interface</h1><br />
                        <p className="code-terminal">
                            {`<iframe src="http://localhost:3000/chat-interface" width="200" height="300" className='custom-iframe'></iframe>`}
                        </p>
                        <h1>API Key</h1><br />
                        <p>Test your key</p>
                        <br />
                        <h4 className="code-terminal">
                            <span>CMD - </span> <br /> {cmd} <br />
                        </h4>
                        <h4 className="code-terminal">
                            <span>MAC Terminal - </span> <br /> {cmd}
                            <br />
                        </h4>

                        <br />

                        <br />


                    </div>
                }
            </div>
        </div>
    )
}

export default SideBar