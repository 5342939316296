import React, { useEffect, useState } from 'react'
import "./SettingsPage.css";
import { Box } from '@mui/material';
import colors from '../../../../Constants/colors';
import CurrentPlan from '../CurrentPlan/CurrentPlan';
import Usage from '../RetrievalBot/Usage/Usage';
import PricingPage from '../../PricingPage/PricingPage';
import GenerateApiKeys from '../GenerateApiKeys/GenerateApiKeys';
import MyKeys from '../MyKeys/MyKeys';


const SettingsPage = ({ userId, usage }) => {
    const [value, setValue] = useState("0");

    useEffect(() => {
        const valueIndex = JSON.parse(localStorage.getItem("valueIndex"));
        if (valueIndex === 2) {
            setValue("2");
            localStorage.setItem("valueIndex", 0);
        } else if (valueIndex === 3) {
            setValue("4");
            localStorage.setItem("valueIndex", 0);
        }
    }, [])
    return (
        <div className='settings-page'>
            <Box display={"flex"} flexDirection={"row"} width={"100%"} padding={4} justifyContent={"center"} sx={{ backgroundColor: colors.bgDark }}>
                <div className="tabs">
                    <button onClick={() => setValue("0")} className={`tab-btn-grid ${value === "0" ? 'active' : ''}`} >My Keys</button>
                    <button onClick={() => setValue("1")} className={`tab-btn-grid ${value === "1" ? 'active' : ''}`} >Usage</button>
                    <button onClick={() => setValue("3")} className={`tab-btn-grid ${value === "3" ? 'active' : ''}`}>Current Plan</button>
                    <button onClick={() => setValue("2")} className={`tab-btn-grid ${value === "2" ? 'active' : ''}`}>Subscription</button>
                    <button onClick={() => setValue("4")} className={`tab-btn-grid ${value === "4" ? 'active' : ''}`} >API Keys</button>

                </div>
            </Box>
            {
                value === "0" && (<MyKeys userId={userId} />)
            }
            {
                value === "1" && (<Usage userId={userId} usage={usage} />)
            }
            {
                value === "2" && <PricingPage />
            }
            {
                value === "3" && <CurrentPlan userId={userId} setValue={setValue} />
            }
            {
                value === "4" && <GenerateApiKeys userId={userId} setValue={setValue} />
            }

        </div>
    )
}

export default SettingsPage