import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Addkeys.css';


const AddKeys = ({ openKeys, setOpenKeys, botData, setBotData, info, infoHeading, fileAlert }) => {
    const [open, setOpen] = useState(false);
    const [openAIKey, setOpenAIKey] = useState('');
    const [pineconeKey, setPineconeKey] = useState('');


    useEffect(() => {
        setOpen(openKeys);
    }, [openKeys])

    const handleClose = () => {
        setOpenKeys(false);
        setOpen(false);
    };

    const handleSave = () => {

        setBotData(prevData => ({
            ...prevData, keys: { ...prevData.keys, open_ai: openAIKey, pinecone: pineconeKey }
        }))


        handleClose();
    };

    const accordioStyles = {
        boxShadow: '0px 0px 10px rgba(52, 102, 255, 0.3)',
        backgroundColor: '#050505',
        color: 'var(--bgLight)',
        border: '1px solid var(--buttonBackground)'
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <div className='open_keys'>
                    <DialogTitle>{infoHeading.info}</DialogTitle>
                    <DialogContent>
                        <Accordion sx={accordioStyles} defaultExpanded={true}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon color='info' />}>
                                <Typography>{infoHeading.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul>
                                    {info.map((i, index) => <li key={index}>{i}</li>)}
                                </ul>
                            </AccordionDetails>
                        </Accordion>

                        {fileAlert && <div class="trial-notice">
                            Your trial files are stored in a temporary database for a maximum of 5 days. To prevent data deletion, please subscribe before the 5th day. You'll receive an email reminder.
                        </div>}

                        <div class="trial-notice">
                            You can define OpenAI and Pinecone usage limits in Settings. ($20 is the default usage limit.)
                        </div>
                    </DialogContent>
                    <DialogContent>
                        {infoHeading.for === "open_ai" && < TextField
                            label="OpenAI Key"
                            value={openAIKey}
                            onChange={(e) => setOpenAIKey(e.target.value)}
                            margin="dense"
                            fullWidth
                            variant="outlined"
                            sx={{ backgroundColor: 'var(--bgLight)' }}
                        />}
                        {infoHeading.for === "pinecone" && <TextField
                            label="Pinecone Key"
                            value={pineconeKey}
                            onChange={(e) => setPineconeKey(e.target.value)}
                            margin="dense"
                            fullWidth
                            variant="outlined"
                            sx={{ backgroundColor: 'var(--bgLight)' }}
                        />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Skip for now</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </div>

            </Dialog>
        </div>
    );
};

export default AddKeys;