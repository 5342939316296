import React from 'react'
import "./navbuttons.css"
const NavigationButtons = ({nextButtonName, handleCancel, handlePrevious, handleNext, hideNext, hidePrevious, previousName, disableNext, hideCancel}) => {
  const next_button_disable = disableNext
  return (
    <div className='navigation-btn'>
        {<div className='cancel'></div>}
        <div className='next-prev'>
        {!hidePrevious && <button className='previous' onClick={handlePrevious}>{previousName}</button>}
     {!hideNext && <button 
      className={`next ${next_button_disable ? 'button-disabled' : ''}`} 
      onClick={handleNext}  
      disabled={next_button_disable}
      style={{
        cursor: next_button_disable ? 'not-allowed' : 'pointer',
        opacity: next_button_disable ? 0.5 : 1
      }}
    >
      {nextButtonName ? nextButtonName : "Next"}
    </button>}</div>
    </div>
  )
}

export default NavigationButtons