import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "./BotsList.css"
import { deleteChatbot, deleteCombinatioSet, getBotbyOrg, getCombinations, setCombinationUsed } from '../../../../features/chatbots/chatbotSlice';
import { useState } from 'react';
import { Dialog } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


const BotsList = ({ setValue, handleReload, setLoading_message, setLoading, setCombinations, set_selected_combinations }) => {
    const chatbot = useSelector(state => state.chatbot);
    const organization = useSelector(state => state.organization);
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {

        const check = organization?.organization?.bot?.length !== chatbot?.chatbots?.combinations?.length;
        const orgId = organization?.organization?._id["$oid"];
        const get_bots = async () => {
            setLoading_message("We are retrieving all the LLM applications you have created. Please wait.");
            setLoading(true);
            await dispatch(getBotbyOrg(orgId));
            setLoading_message("");
            setLoading(false);
            const combinationsWithChecked = chatbot?.combinations?.map(combination => ({
                ...combination,
                checked: true,
            }));

            setCombinations(combinationsWithChecked);
            set_selected_combinations(combinationsWithChecked)
        }

        if (check) {
            get_bots();
        }

    }, [])

    // useEffect(() => {
    //     const value = JSON.parse(localStorage.getItem("value"));
    //     const call = async () => {
    //         const index = chatbot?.chatbots?.combinations?.length - 1;
    //         await handleUse(index);
    //     }
    //     if (value && value !== "0") {

    //         call();
    //         setTimeout(() => { localStorage.setItem("value", JSON.stringify("0")); }, 1000);
    //     }
    // }, [setValue])

    const handleUse = async (index) => {
        setLoading_message("Fetching combinations for the selected application. Please wait.");
        setLoading(true);
        const id = chatbot.chatbots.combinations[index]._id["$oid"];
        if (id) {
            await dispatch(setCombinationUsed(index));
            const response = await dispatch(getCombinations(id));
            const combinationsWithChecked = chatbot?.combinations?.map(combination => ({
                ...combination,
                checked: true,
            }));

            setCombinations(combinationsWithChecked);
            set_selected_combinations(combinationsWithChecked)
            setLoading_message("");
            setLoading(false);
            if (response.meta.requestStatus === "fulfilled") {
                setValue("1");
            }
        }
    }

    const handleDelete = async (index) => {
        setLoading_message("Deleting the LLM Application. Please wait.");
        setLoading(true);
        const id = chatbot.chatbots.combinations[index]._id["$oid"];
        const response = await dispatch(deleteChatbot(id));

        const filtered_combinations = chatbot.chatbots.combinations.filter(comb => comb._id["$oid"] !== id)
        // console.log("Filtered: ", filtered_combinations)
        let chatbot_ = { ...chatbot, chatbots: { ...chatbot.chatbots, combinations: filtered_combinations } };
        // console.log("Chatbot_: ", chatbot_);
        await dispatch(deleteCombinatioSet(chatbot_));
        setLoading(false);
        handleReload();

    }

    const handleGotoDepoyedVersion = async () => {
        // handle deploy version redirection here
    }

    return (
        <div className='botslist-container'>

            <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
                <div className='generate-dialog'>
                    <div className='generate-dialog_title'>
                        <h2>Deployed Version?</h2>
                    </div>
                </div>
            </Dialog>
            <div className='botslist'>
                <div className="scrollable-sticky-head">
                    <div className="scrollable-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th align="left">#</th>
                                    <th align="left" id='combination-name'>Application</th>
                                    <th align="left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(chatbot?.chatbots?.combinations || [])?.map((combination, index) =>

                                    <tr key={index}>
                                        {//console.log(parseInt(chatbot.combinationUsed) === index)
                                        }
                                        <td align="right" >{index}</td>
                                        <td align="right" id='combination-name' >{combination.name}</td>
                                        <td align="left" >
                                            <div className='list-actions'>
                                                <button disabled={parseInt(chatbot.combinationUsed) === index ? true : false} className={parseInt(chatbot.combinationUsed) === index ? "disabled-use" : "active-use"} onClick={() => handleUse(index)}>{chatbot.combinationUsed === index ? "Application in use" : "Use this application"}</button>
                                                <button disabled={combination.default_combination ? false : true} className={!combination.default_combination ? "disabled" : "active"} onClick={() => setOpenDialog(true)} >Go to deployed version</button>
                                                <DeleteIcon onClick={() => handleDelete(index)} sx={{ paddingLeft: '5px', cursor: 'pointer' }} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BotsList