import React, { useEffect, useState } from 'react';
import "./Pricing.css";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import axios from 'axios';

import standard from "../../../Assets/standard.jpg"
import pro from "../../../Assets/pro.jpg"
import enterprise from "../../../Assets/enterprise.jpg"

const PrincingPage = () => {

  const stripePromise = loadStripe("pk_test_51O0HVVDPmzXw5VvBq64XL5UGXjLSGmFi9FNMjBkVwRxbxKUGfXBHWLXzhw8KUV7N8wshTvuxE8JzQ6bHhoJ0XaWj00TjcTPq7f");
  const [clientSecret, setClientSecret] = useState('');
  const [loadCheckout, setLoadCheckout] = useState(false);
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');


  const handleSubscribe = async (plan) => {
    localStorage.setItem("package", plan)
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        // Other headers if needed
      },
    };

    axios.post(`/api/payments/subscribe/${plan}`, {},
      config
    )
      .then((response) => {
        console.log(response.data)
        setClientSecret(response.data.clientSecret)
        setLoadCheckout(true)
      })
      .catch((error) => {
        console.log(error);

      });
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.

          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    )
  }

  const handleCancel = async () => {

  }

  return (
    loadCheckout === false ?
      <div className='pricepackage-container'>

        <div className='pricing-page'>
          {/* ************************* Starter ************************ */}
          <div className='package-container'>
            <div className='package_'>
              <div className='package_header'>
                <img src={standard} />
                <h2>Standard</h2>
              </div>

              <div className='package_price'>
                <div className='package_price_info'>
                  <p className='priceStyles'>USD 400</p>
                  <div className='per-month'>
                    <p>per</p>
                    <p>month</p>
                  </div>
                </div>
                <button onClick={() => handleSubscribe("standard")}>Subscribe</button>
              </div>

              <div className='package_includes'>
                <p className='this-inlcudes'>This includes:</p>
                <p > <span className="checkmark">✔</span> Retrieval optimization including the identification of the right chunking strategy, retrieval/reranking, embedding model, and LLM.</p>
                <p ><span className="checkmark">✔</span> Prompt optimization to automatically give the right instruction to the LLM.</p>
                <p ><span className="checkmark">✔</span> Metadata filtering to retrieve only the documents you want.</p>
                <p ><span className="checkmark">✔</span> Problem statement information extraction to land on the best parameters quickly.</p>
              </div>
            </div>

          </div>

          {/* ************************* Pro ************************ */}
          <div className='package-container'>
            <div className='package_'>
              <div className='package_header'>
                <img src={pro} />
                <h2>Pro</h2>
              </div>

              <div className='package_price'>
                <div className='package_price_info'>
                  <p className='priceStyles'>USD 700</p>
                  <div className='per-month'>
                    <p>per</p>
                    <p>month</p>
                  </div>
                </div>
                <button onClick={() => handleSubscribe("pro")}>Subscribe</button>
              </div>

              <div className='package_includes'>
                <p className='this-inlcudes'>This includes:</p>
                <p > <span className="checkmark">✔</span> <b>Everything in Starter Package plus the following:</b></p>
                <p ><span className="checkmark">✔</span> Access to embedding optimization</p>
                <p ><span className="checkmark">✔</span> Access to dual fine-tuning of retrieval, and LLM (coming soon)</p>
                <p ><span className="checkmark">✔</span> Access to Beta Features</p>
              </div>
            </div>

          </div>

          {/* ************************* Enterprise ************************ */}
          <div className='package-container'>
            <div className='package_'>
              <div className='package_header'>
                <img src={enterprise} />
                <h2>Enterprise</h2>
              </div>

              <div className='package_price'>
                <div className='package_price_info'>
                  <p className='priceStyles'>Contact us for pricing</p>

                </div>
                <button onClick={() => handleCancel}>Contact us</button>
              </div>

              <div className='package_includes'>
                <p className='this-inlcudes'>This includes:</p>
                <p > <span className="checkmark">✔</span> <b>Everything in Pro Package plus the following:</b></p>
                <p ><span className="checkmark">✔</span> Extensive Custom Support by top LLM experts</p>

              </div>
            </div>

          </div>

        </div>
        <div className='pricepackage-info'>
          <p>Please note that the costs for the LLM API and Vector Database API are exclusive and not included in the base subscription fee</p>
        </div>
        <div></div>
      </div> : <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ clientSecret }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>

  );
};

export default PrincingPage;
