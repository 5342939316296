import bgimage from '../Assets/backgroundimg.jpg'

const colors = {
    bgDark: "#050505",
    bgLight: "#E5EFFF",
    colorPrimary: "#434343",
    colorLight: "rgb(67, 67, 67)",
    fontprimary: "'Oxygen', sans-serif",
    bgbrown: '#050505',
    bglightbrown: '#FAF5EA',
    bgBlue: '#443DFF'
}



export default colors 