import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const styles = { width: "100%", display: "flex", marginLeft: "20px", marginTop: "10px" }

const CustomSelect = ({ options, value, onChange, label, ...props }) => {
    return (
        <div style={styles}>
            <FormControl fullWidth size="small" variant="outlined"> {/* Apply MUI styles */}
                <InputLabel id="mui-select-label">{label}</InputLabel>
                <Select
                    labelId="mui-select-label"
                    label={label}
                    defaultValue={value}
                    onChange={onChange}
                    {...props}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default CustomSelect;
