import React, { useEffect, useState } from 'react';
import "./GenerateApiKeys.css"
import { useDispatch, useSelector } from 'react-redux';
import { deleteApi, generateApi } from '../../../../features/auth/authSlice';
import { Dialog, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import CustomTextArea from '../../../../Components/CustomTextArea/CustomTextArea';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';
import SideBar from '../../../../Components/SideBar/SideBar';
import { modifyKeys } from '../../../../features/organization/orgSlice';

const GenerateApiKeys = () => {

  const { organization } = useSelector(state => state.organization);
  const { user } = useSelector(state => state.auth);
  const chatbot = useSelector(state => state.chatbot);
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [open_sidebar, set_open_sidebar] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");

  const [items, setItems] = useState([]);


  useEffect(() => {

    const apiKeysArray = chatbot.chatbots.combinations.map((keyObj) => keyObj.name);
    console.log(apiKeysArray)
    setItems(apiKeysArray);

  }, [user]);

  const openGenerateKey = () => {
    setOpenDialog(true);
  }

  const generateKey = async () => {
    const orgId = organization?._id["$oid"];
    const comb_ = chatbot.chatbots.combinations.find(comb => comb.name === selectedKey);
    const bot_id = comb_._id["$oid"];
    const response = await dispatch(generateApi({ orgId, bot_id }));
    console.log(response.payload);
    if (response?.payload?.api_key?.substring(0, 3) === "qlp") {
      let api_keys_temp = [...organization.api_keys, response.payload];
      var org_with_keys = { ...organization };
      org_with_keys["api_keys"] = api_keys_temp;
      dispatch(modifyKeys(org_with_keys));
      alert("Key generated successfully.")
    }

    setOpenDialog(false);
  }


  const deletekey = async (key) => {
    // console.log("key is",key)
    const response = await dispatch(deleteApi(key));
    if (response.payload.status === "API key deleted successfully") {
      let api_keys_temp = [...organization?.api_keys];
      var org_with_keys = { ...organization };
      org_with_keys["api_keys"] = api_keys_temp.filter(k => k !== key);
      dispatch(modifyKeys(org_with_keys));
      alert("API key deleted successfully");
    }
    else {
      alert("Unable to delete API key please try again");
    }
  }

  const handleChange = (e) => {
    console.log(e.target.value);
    setSelectedKey(e.target.value);
  }

  return (
    <div className='generate-key-container'>

      <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
        <div className='generate-dialog'>
          <div className='generate-dialog_title'>
            <h2>API key information</h2>
          </div>
          <div className='generate-dialog_input'>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2, width: "60%" }}>
              <InputLabel id="month-select-label">Select application name</InputLabel>
              <Select
                labelId="application-select-label"
                id="application-select"
                value={selectedKey}
                onChange={handleChange}
                label="Select application name"
                sx={{ backgroundColor: '#fff' }}
              >
                {items.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className='generate-dialog_actions'>
            <button className='btn-cancel' onClick={() => setOpenDialog(false)}>Cancel</button>
            <button className='btn-done' onClick={generateKey}>Generate API Key</button>
          </div>
        </div>
      </Dialog>

      {open_sidebar && <SideBar open_sidebar={open_sidebar} type={"apikey"} set_open_sidebar={set_open_sidebar} selectedKey={selectedKey} />}

      <div className='generate-key'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Secret Key</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              organization?.api_keys?.map((key, index) => (
                <tr key={index}>
                  <td>{key.name}</td>
                  <td id='apikey-col'>{key.api_key}</td>
                  <td>
                    <div className='key-table-actions'>
                      <button onClick={() => {
                        setSelectedKey(key);
                        set_open_sidebar(true);
                      }}>Use</button>
                      <button onClick={(e) => deletekey(key.api_key, e)}>
                        <DeleteOutlineRounded color='error' fontSize='small' />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>

        <div className='btn-generate'>
          <button onClick={openGenerateKey}>Create new key</button>
        </div>
      </div>
    </div>
  );
};

export default GenerateApiKeys;
