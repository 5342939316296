import React from 'react';

const Checkbox = ({ label, checked, onChange, disabled }) => {
    const disable = disabled ? true : false;
  return (
    <label style={{cursor:'pointer'}}>
      <input type="checkbox" checked={checked} onChange={onChange} disabled={disable} />
      {label}
    </label>
  );
};

export default Checkbox;