import React, { useCallback, useState, useRef } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // Import the upload icon from Material UI icons
import "./UploadSources.css"
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSources } from '../../../../features/chatbots/chatbotSlice';

const UploadSources = () => {

    const { user } = useSelector(state => state.auth);
    const { organization } = useSelector(state => state.organization);
    const chatbot = useSelector(state => state.chatbot);
    const dispatch = useDispatch();

    const fileInputRef = useRef();

    const [botData, setBotData] = useState({ datasets: [] });

    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-based
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleFileRead = (file, reader) => {
        // Assuming you have a function getCurrentDate that returns the current date in your desired format
        const uniqueId = Date.now() + "_" + file.name;
        const newFile = {
            filedata: reader.result,
            id: uniqueId,
            fileName: file.name,
            fileType: file.type,
            creationDate: getCurrentDate(),
            category: "",
        };

        setBotData(prevData => ({
            ...prevData,
            datasets: [...prevData.datasets, newFile],
        }));
    };

    const onDrop = (event) => {
        event.preventDefault();
        const files = [...event.dataTransfer.files];

        files.forEach(file => {
            const reader = new FileReader();
            const fileSize = file.size / (1024 * 1024); // Size in MB
            if (fileSize > 20 && user?.user?.subscription === "starter") {
                alert("Maximum file size is 20MB for starter package.\nTo increase the limit please subscribe to Enterprise Package");
                return;
            }
            if (["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain", "text/csv"].includes(file.type)) {
                reader.onload = () => handleFileRead(file, reader);
                reader.readAsDataURL(file);
            } else {
                alert(`${file.type} is not a supported file type.\nSupported file types are: PDF, DOCX, TXT, CSV`);
            }
        });
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const openFileDialog = () => {
        fileInputRef.current.click();
    };

    const onFileInputChange = (event) => {
        const files = [...event.target.files];

        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = (event) => {
                // When the file is read it triggers the `onload` event.
                setBotData(prevData => ({
                    ...prevData,
                    datasets: [
                        ...prevData.datasets,
                        {
                            filedata: event.target.result, // Contains the file data as a base64 encoded string
                            id: Date.now() + "_" + file.name,
                            fileName: file.name,
                            fileType: file.type,
                            creationDate: getCurrentDate(), // Ensure you have this function defined or replace with new Date().toISOString()
                            category: "",
                            confidentiality: "Public"
                        },
                    ],
                }));
            };
            reader.readAsDataURL(file); // Read the file as Data URL (base64)
        });
    };

    const removeFile = (fileIdToRemove) => {
        setBotData(prevData => ({
            ...prevData,
            datasets: prevData.datasets.filter(file => file.id !== fileIdToRemove)
        }));
    };



    const handleUpload = async () => {
        console.log(botData)
        const formData = new FormData();

        //extracting from botData and appending in formData as a propper json
        for (const [key, value] of Object.entries(botData)) {

            let d_ = {}
            if (key === 'datasets') {
                botData.datasets.map((d, index) => (d_[key + index] = d))
                formData.append(key, JSON.stringify(d_))
                continue
            }
        }


        //print
        for (const entry of formData.entries()) {
            console.log(entry);
        }

        const index = chatbot.combinationUsed;
        const orgId = organization._id["$oid"];
        const user_id = user.user._id["$oid"];
        const bot_id = chatbot.chatbots.combinations[index]._id["$oid"];

        const response = await dispatch(uploadSources({ formData: formData, ids: { user_id, bot_id, orgId } }));
        if (response.payload.status == "File Successfully uploaded") {
            alert("File has been uploaded successfully")
            setBotData({ datasets: [] })
        }
        else {
            alert("File could not be uploaded. Please Retry")
        }
        console.log("response:", response);
    }


    return (
        <div className='sources-container'>
            <div className='sources'>
                <div className='sources-title'>
                    <h2>Upload documents</h2>
                </div>

                <div className='upload-dropbox' onDrop={onDrop} onDragOver={onDragOver} onClick={openFileDialog}>

                    <CloudUploadIcon style={{ fontSize: '2rem' }} />
                    <p>Drag & drop files here, or click to upload</p>
                    <input
                        ref={fileInputRef}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={onFileInputChange}
                    />
                    <p>Supported File Types: .pdf, .doc, .docx, .txt</p>
                </div>

                {botData.datasets.length > 0 && <div className='included-files'>
                    <h3>Files added:</h3>
                    {botData.datasets.map((file, index) => (
                        <div className='uploaded'>
                            <p key={index}>{file.fileName}</p>
                            <DeleteOutlineRounded onClick={() => removeFile(file.id)} fontSize='small' color='error' style={{ cursor: 'pointer' }} />
                        </div>
                    ))}
                    <div className='upload-button'>
                        <button onClick={handleUpload}>Upload added files</button>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default UploadSources;
