import React, { useEffect, useState } from "react";
import { login } from "../../../features/auth/authSlice";
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomInput from '../../../Components/Input/CustomInput'
import "./login.css"
import { getOrganization } from "../../../features/organization/orgSlice";
import Loader from "../../../Components/Loader";
const Login = () => {

  // const styles = {
  //   backgroundColor: '#424449',
  //   height: '40px',
  //   width: '300px',
  //   outline: 'none',
  //   border: 'none',
  //   padding: '10px',
  //   borderRadius: '10px',
  //   fontSize: '18px',
  //   color: '#c3c3c3',
  //   marginBottom: '18px'
  // }

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const { email, password } = userData;



  const handleChange = (e) => {
    setUserData((inputTexts) => ({
      ...inputTexts,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await dispatch(login(userData));
    const orgId = response?.payload?.user?.organization?.["$oid"]
    if (response?.payload?.user && orgId) {
      await dispatch(getOrganization(orgId));
      navigate("/dashboard/create-bot");
    } else if (response.payload === "Request failed with status code 401") {
      alert("Your Email and Password doesn't match. Please input correct email and password!")
    }

    setLoading(false);
  };

  if (user === null) {
    return (
      <Box
        height={"100vh"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >

      </Box>
    );
  }

  const handleGotoSignup = () => {
    navigate('/add/organization')
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="login-container">
      <div className="login">
        <div className="login-heading">
          <p>Login to Queryloop</p>
        </div>
        <CustomInput multiple={false} className={'customColor'} placeholder={"Enter Your Email"} type={"email"} value={email} name={"email"} onHandleChange={handleChange} />
        <CustomInput multiple={false} className={'customColor'} placeholder={"Enter Password"} type={"password"} value={password} name={"password"} onHandleChange={handleChange} />
        <div className="btn btn-login">
          <button style={{ backgroundColor: "var(--buttonBackground)", color: "white", width: "150px" }} onClick={handleSubmit}>Login</button>
          <p className="not-a-user">Not a user? <span onClick={handleGotoSignup} className="create-account">Create an account</span></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
