import React, { useEffect, useRef, useState } from 'react'
import "./PostGrid.css"
import { Box, Dialog, Snackbar, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { deleteChatbot, deleteCombinatioSet, getBotbyOrg, getCombinations, initializeCombinations, resetChatbot, runEvals, sortByAccuracy } from '../../../../../features/chatbots/chatbotSlice'
import colors from '../../../../../Constants/colors'
import userIcon from "../../../../../Assets/user.png"
import queryloopIcon from "../../../../../Assets/queryloop.png"
import PromptReader from '../../../MyBots/PromptReader/PromptReader'
import DetailsView from '../../../../../Components/DetailsView/DetailsView'
import PromptGrid from '../../../MyBots/PromptGrid/PromptGrid'
import axios from 'axios'
import { getUser } from '../../../../../features/auth/authSlice'
import PostPromptGrid from '../../../MyBots/PromptGrid/PostPromptGrid'
import TestDeploy from '../../../MyBots/TestDeploy/TestDeploy'
import SideBar from '../../../../../Components/SideBar/SideBar'
import UploadSources from '../../UploadSources/UploadSources'
import BotsList from '../../../MyBots/BotsList/BotsList'
import { current } from '@reduxjs/toolkit'
import AddKeys from '../../../../../Components/PopUp/AddKeys'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';



const PostGrid = ({ combinations, setCombinations, reload, bot_id, reloadUser, user_id, selected_combinations, set_selected_combinations, setTabName }) => {

    const chatbot = useSelector(state => state.chatbot);
    const { user } = useSelector(state => state.auth);
    const organization = useSelector(state => state.organization);
    const [open_details, set_open_details] = useState(false);
    const dispatch = useDispatch();
    const [metrics, set_metrics] = useState();
    const [enableRun, set_enableRun] = useState(false);
    const [value, setValue] = useState("0");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading_message, setLoading_message] = useState("");
    const [sortOrder, setSortOrder] = useState('asc');
    const [openChat, setOpenChat] = useState(false);
    const [openChatDefault, setOpenChatDefault] = useState(false);
    const [defaultHistory, setDefaultHistory] = useState([]);
    const [defaultHuman, setDefaultHuman] = useState([]);
    const [open_sidebar, set_open_sidebar] = useState(false);
    const textareaRef = useRef(null);
    const [open_tnd_tab, set_open_tnd_tab] = useState(false);
    const [selectAll, setSelectAll] = useState(true);
    const [clear_history, set_clear_history] = useState(false);
    const [openKeys, setOpenKeys] = useState(false);

    const [info, setInfo] = useState([]);
    const [infoHeading, setInfoHeading] = useState({});

    const infoRunHeading = { "for": "open_ai", "title": "Choosing to proceed without an OpenAi API Key will result in the following limitations:" }
    const infoRun = ["Only execute 5 combinations", "GPT-3.5-turbo used for evaluation which can affect its accuracy", "Cannot access Chat Playground"]

    // useEffect(() => {
    //     // Check if all combinations are selected to update the selectAll state
    //     const areAllSelected = combinations.length && combinations.every(c => c.checked);
    //     setSelectAll(areAllSelected);

    // }, [combinations]);


    useEffect(() => {
        setLoading_message("Getting combinations, please wait.")
        setLoading(true)

        if (combinations?.length === 0 && (chatbot?.combinations[chatbot.combinationUsed]?.combination_set["$oid"] === chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?._id["$oid"])) {
            // Clearing previous combinations
            setCombinations(chatbot?.combinations?.length > 0 ? [...chatbot?.combinations] : []);
        }

        if (selected_combinations?.length === 0 && (chatbot?.combinations[0]?.combination_set["$oid"] === chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?._id["$oid"])) {
            // Setting selected combinations if they exist
            set_selected_combinations(chatbot?.selected_combinations);
        }
        setLoading(false)
        setLoading_message("")
    }, [chatbot?.selected_combinations, chatbot?.combinations, combinations]);

    // Effect for handling combinations initialization
    useEffect(() => {
        setLoading_message("Getting combinations, please wait.")
        setLoading(true)
        if (chatbot?.combinations[0]?.combination_set?.["$oid"] !== chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?._id?.["$oid"]) {
            handleReload();
        }
        if ((combinations?.length === 0 && (!chatbot?.gettingCombinations || !chatbot?.isLoading) && organization?.organization?.bot?.length > 0)) {

            handleReload();
        } else {

            const combinationsWithChecked = (chatbot?.combinations || []).map(combination => ({
                ...combination,
                checked: true,
            }));

            setCombinations(combinationsWithChecked);
            set_selected_combinations(combinationsWithChecked)
        }

        setLoading(false)
        setLoading_message("")
    }, []);


    const handleOpenDetails = (index) => {
        const prevCombination = localStorage.getItem("prevCombination")
        if (!prevCombination) {
            localStorage.setItem("prevCombination", chatbot?.combinations[index]._id['$oid']);
        }
        if (chatbot?.combinations[index]._id['$oid'] !== prevCombination) {
            localStorage.setItem("prevCombination", chatbot?.combinations[index]._id['$oid']);
            set_clear_history(true);
        } else {
            set_clear_history(false);
        }
        set_metrics(chatbot?.combinations[index])
        set_open_details(true)
    }

    const handleReload = async () => {
        await setLoading_message("Getting combinations, please wait.");
        await setLoading(true);
        await reloadUser();
        await reload();
        // await dispatch(getCombinations(chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?._id["$oid"]))

        await setLoading(false);
        await setLoading_message("");
    }

    const handleCloseDetails = () => {
        set_open_details(false)
    }

    const handleRetry = async (combination) => {
        setLoading_message("Running combination again. Please wait...")
        setLoading(true);
        let combination_id = combination._id['$oid'];
        const orgId = organization?.organization?._id["$oid"]
        if (combination_id && orgId) {
            const response = await dispatch(runEvals({ orgId, user_id, bot_id, combination_id }));
            await handleReload();
            setLoading(false);
            setLoading_message("");
            const combinationsWithChecked = chatbot?.combinations?.map(combination => ({
                ...combination,
                checked: true,
            }));

            setCombinations(combinationsWithChecked);
            set_selected_combinations(combinationsWithChecked)
        } else {
            alert("An error occurred while running the combination. Please retry!")
        }

    }


    const handleRunAll = async () => {
        const checkKeys = !organization?.organization?.keys?.open_ai;
        const chatbot_length = chatbot.combinations.length > 5;
        if (organization?.organization?.subscription === "starter" && checkKeys && chatbot_length) {
            setInfo(infoRun);
            setInfoHeading(infoRunHeading);
            setOpenKeys(true);

            await new Promise(resolve => {
                const intervalId = setInterval(() => {
                    if (!openKeys) {
                        clearInterval(intervalId);
                        resolve();
                    }
                }, 500)
            });
        }

        setLoading_message("We're testing all combinations with the golden responses. Hang tight, we'll have your optimized results ready shortly.")
        setLoading(true);

        // Check the user's subscription type and set a limit accordingly
        const limit = organization?.organization?.subscription === "starter" ? 5 : chatbot?.combinations.length;
        const combinationsToRun = chatbot?.combinations.slice(0, limit); // Slice the array to run only a specific number of combinations based on the limit
        const orgId = organization?.organization?._id["$oid"]
        let i = 0;
        for (const combination of combinationsToRun) {

            try {
                setLoading_message("We're testing all combinations with the golden responses. Hang tight, we'll have your optimized results ready shortly.")
                setLoading(true);
                let combination_id = combination._id['$oid'];
                const response = await dispatch(runEvals({ orgId, user_id, bot_id, combination_id }));

                await handleReload();
            } catch (error) {
                console.error('Error running evaluation:', error);
                // Handle error appropriately
            }

            i++;

        }

        setLoading(false);
        setLoading_message("");
        handleReload();

        const combinationsWithChecked = chatbot?.combinations?.map(combination => ({
            ...combination,
            checked: true,
        }));

        setCombinations(combinationsWithChecked);
        set_selected_combinations(combinationsWithChecked)
    };

    const handleInit = async () => {
        setLoading_message("Setting up indexes and storage, securing data in vector database. Hang tight!")
        setLoading(true);
        const orgId = organization?.organization?._id["$oid"];
        const initComb = await dispatch(initializeCombinations({ user_id, bot_id, orgId }));
        console.log(initComb);
        await dispatch(getBotbyOrg(orgId))
        setLoading_message("Setting up combinations")
        set_enableRun(true);
        setLoading_message('')
        setLoading(false);
    }


    const handleSortByAccuracyState = () => {
        let combs = chatbot?.combinations
        const sortedCombinations = [...combs].sort((a, b) => {
            // Assuming that accuracy is a number and directly comparable
            const accuracyA = a.average_rating || 0;
            const accuracyB = b.average_rating || 0;

            if (sortOrder === 'asc') {
                return accuracyA - accuracyB;
            } else {
                return accuracyB - accuracyA;
            }
        });

        dispatch(sortByAccuracy(sortedCombinations));
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle the sort order for the next click
    };


    const handleDeleteBot = async () => {

        setLoading_message("Deleting the LLM Application. Please wait.");
        setLoading(true);
        const id = chatbot?.chatbots?.combinations?.[chatbot?.combinationUsed]?._id?.["$oid"];
        if (id) {
            const response = await dispatch(deleteChatbot(id));

            const filtered_combinations = chatbot?.chatbots?.combinations?.filter(comb => comb?._id?.["$oid"] !== id)
            // console.log("Filtered: ", filtered_combinations)
            let chatbot_ = { ...chatbot, chatbots: { ...chatbot.chatbots, combinations: filtered_combinations } };
            // console.log("Chatbot_: ", chatbot_);
            await dispatch(deleteCombinatioSet(chatbot_));
            setLoading_message("");
            setLoading(false);
            handleReload();
            set_selected_combinations([])
            setCombinations([])
        } else {
            alert("Error deleting the LLM Application, please try again later.")
        }
    }

    const handleChat = async () => {
        if (defaultHuman.length > 0) {
            setDefaultHistory([...defaultHistory, { human: defaultHuman, ai: "Generating ..." }])
            const token = JSON.parse(localStorage.getItem("token"))
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    // Other headers if needed
                },
            };
            const orgId = organization.organization._id["$oid"]
            axios
                .post(
                    `/api/eval_bot/chatdefault/${orgId}/${user_id}/${bot_id}`,
                    { query: defaultHuman },
                    config
                )
                .then((response) => {
                    setDefaultHistory([...defaultHistory, { ai: response.data.answer, human: defaultHuman }]);
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            alert("Please type any query!")
        }
    }

    const handlekeydown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevents the default behavior of the Enter key
            setDefaultHistory([...defaultHistory, { ai: "thinking... ", human: defaultHuman }]);
            setDefaultHuman("")
            handleChat()

        }
    };


    const adjustTextareaRows = () => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = "auto"; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content
        }
    };



    if (organization?.organization?.bot.length === 0) {
        return <div style={{ display: "flex", flexDirection: "row", backgroundColor: colors.bgLight, height: "100vh" }}>
            <div className='postgrid'>
                <p style={{ fontSize: "1.5rem" }}><span onClick={() => setTabName("createChatbot")} style={{
                    margin: "0px 0px 0px 2px",
                    padding: "4px",
                    cursor: "pointer",
                    backgroundColor: "var(--buttonBackround)",
                    color: "white",
                    borderRadius: "5px"
                }}>Please create LLM application</span></p>
                <p style={{ fontSize: "1.5rem" }}><span onClick={() => reloadUser()} style={{
                    margin: "0px 0px 0px 2px",
                    padding: "4px",
                    cursor: "pointer",
                    backgroundColor: "var(--buttonBackround)",
                    color: "white",
                    borderRadius: "5px"
                }}>Reload</span> if already created but not visible </p>

            </div>
        </div>
    }

    const isAnyCombinationCompleted = () => {

        const isCompleted = chatbot?.combinations?.some(combination => combination?.completed === true);
        return isCompleted
    };

    const checkCombinationValidity = (id) => {
        const targetCombination = (chatbot?.combinations || []).find(
            comb => comb?._id?.$oid === id
        );


        if (targetCombination && targetCombination?.completed === false) {
            if (chatbot?.combinations?.length === 1 && chatbot?.isError) {
                return "This combination failed. Please retry or try another combination.";
            }

            const otherCompletedCombinations = (chatbot?.combinations || []).filter(
                comb => comb?._id?.$oid !== id && comb?.completed
            );

            if (otherCompletedCombinations.length > 0) {
                return "This combination failed. Please retry or try another combination.";
            }
        }

        const allCombinationsCompleted = chatbot?.combinations?.every(comb => comb?.completed);

        if (!allCombinationsCompleted) {
            return ""; // No combinations have run yet, so it's valid
        }

        return "";
    };


    return (
        <div className='postgrid-container'>
            <AddKeys openKeys={openKeys} setOpenKeys={setOpenKeys} botData={{}} setBotData={{}} info={info} infoHeading={infoHeading} />
            <Dialog fullScreen open={openChatDefault} onClose={() => setOpenChatDefault(false)}>
                <div className='chat-container'>
                    <div className='action-back'>
                        <button onClick={() => setOpenChatDefault(false)}>X</button>
                    </div>

                    <div className='chat-point'>
                        {defaultHistory.map((msg, index) => (
                            <div key={index}>
                                <div className="human-message">
                                    <img src={userIcon} alt="user" />
                                    <p>{msg.human}</p>
                                </div>
                                <div className="ai-message">
                                    <img src={queryloopIcon} alt="queryloop" />
                                    <p>{msg.ai}</p>
                                </div>
                            </div>
                        ))}
                        <div>

                            <textarea
                                ref={textareaRef}
                                value={defaultHuman}
                                onChange={(e) => {
                                    setDefaultHuman(e.target.value);
                                    adjustTextareaRows();
                                }}
                                autoFocus={true}
                                onKeyDown={handlekeydown}
                                placeholder="Start typing"
                                className="user-input-area"

                            ></textarea>
                        </div>
                    </div>
                    <div></div>
                </div>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={user?.user?.subscription === "starter" ? "Your current package does not allow Prompt Optimization. Please subscribe to the Standard Package." : "Please initialize the combinations, then run all combinations before proceeding to the next step."}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open_tnd_tab}
                autoHideDuration={6000}
                onClose={() => set_open_tnd_tab(false)}
                message={user?.user?.subscription === "starter" ? "Your current package does not allow deployment. Please subscribe to the Standard Package." : "Please complete the retrieval optimization and prompt optimization processes to test and deploy the application."}
            />
            {open_sidebar && <SideBar type={"eval"} open_sidebar={open_sidebar} set_open_sidebar={set_open_sidebar} closeEvalBar={() => set_open_sidebar(false)} />}
            <DetailsView metrics={metrics} open={open_details} handleClose={handleCloseDetails} openChat={openChat} setOpenChat={setOpenChat} bot_id={bot_id} user_id={user_id} orgId={organization?.organization?._id?.["$oid"]} set_open_sidebar={set_open_sidebar} clearHistory={clear_history} />
            <Box display={"flex"} flexDirection={"row"} width={"100%"} padding={4} justifyContent={"center"} sx={{ backgroundColor: colors.bgDark }}>
                <div className="tabs">
                    <button className={`tab-btn-grid ${value === "0" ? 'active' : ''}`} onClick={() => setValue("0")} >1. My Applications</button>
                    <button className={`tab-btn-grid ${value === "1" ? 'active' : ''}`} onClick={() => setValue("1")} >2. Retrieval Optimization</button>
                    <button className={`tab-btn-grid ${(value === "3" || value === "2" || value === "4") ? 'active' : ''}`} onClick={() => {
                        if (chatbot?.combinations[0]?.completed === true) {
                            if (chatbot?.selected_combinations?.length > 0) {
                                set_selected_combinations(chatbot?.selected_combinations || []);

                                setValue("4");

                            } else {
                                const combinationsWithChecked = chatbot?.combinations?.map(combination => ({
                                    ...combination,
                                    checked: true,
                                }));

                                setCombinations(combinationsWithChecked);
                                set_selected_combinations(combinationsWithChecked)
                                setValue("3")
                            }
                        } else {
                            setSnackbarOpen(true)
                        }
                    }} >3. Prompt Optimization</button>
                    <button className={`tab-btn-grid ${value === "5" ? 'active' : ''}`} onClick={() => {
                        if (chatbot?.combinations[0]?.completed === true) {
                            setValue("5");
                        } else {
                            set_open_tnd_tab(true)
                        }
                    }} >4. Test & Deploy</button>

                    <button className={`tab-btn-grid ${value === "6" ? 'active' : ''}`} onClick={() => {
                        if (chatbot?.combinations[0]?.completed === true) {
                            setValue("6");
                        } else {
                            setSnackbarOpen(true)
                        }
                    }} >5. Add more sources</button>


                </div>

            </Box>

            {loading &&
                <div className='loading-prompt'>
                    <div className="loading-spinner"></div>
                    <p>{loading_message}</p>
                </div>

            }
            {(value === "1" || value === "4") && loading === false &&
                <div className='postgrid-info'>
                    {
                        chatbot?.chatbots?.combinations[chatbot.combinationUsed]?.initialised === false && <p>Please initialize combinations.</p>
                    }
                    {
                        value === "1" && (chatbot?.chatbots?.combinations[chatbot.combinationUsed]?.initialised === true) && chatbot?.combinations[0]?.completed === false && <p>Click on Run All button.</p>
                    }
                    {
                        value === "1" && (chatbot?.chatbots?.combinations[chatbot.combinationUsed]?.initialised === true) && (chatbot?.combinations[0]?.completed === true) && <p>View results, deploy the application, or navigate to Prompt Optimization page for further optimization.</p>
                    }

                    {
                        value === "4" && chatbot?.selected_combinations?.length > 0 && chatbot?.selected_combinations[0]?.completed === true && <p>View prompt with results, deploy combination, and run other questions.</p>
                    }
                    {
                        value === "4" && chatbot?.selected_combinations?.length > 0 && chatbot?.selected_combinations[0]?.completed === false && chatbot?.combinations[0]?.completed === false && <p>Click Run All to view results with selected prompts.</p>
                    }
                    {
                        value === "5" && chatbot?.selected_combinations?.length > 0 && chatbot?.selected_combinations[0]?.completed === true && <p>Click 'Run' to test the combination. Click 'Deploy' to deploy the combination.</p>
                    }
                </div>}
            {value === "1" &&
                <div className='postgrid'>

                    <div className='grid-btns'>
                        <div className='run-btns'>
                            {(chatbot?.chatbots?.combinations[chatbot.combinationUsed]?.initialised === true) && chatbot?.combinations[0]?.completed === false && <button onClick={handleRunAll}>Run All</button>}
                            {
                                // chatbot?.chatbots?.combinations?.combinations[chatbot?.combinationUsed]?.completed===false &&
                                chatbot?.chatbots?.combinations[chatbot.combinationUsed]?.initialised === false && <button onClick={handleInit}>Initialize Combinations</button>
                            }
                        </div>
                        {
                            <button className='delete-btn-' onClick={handleDeleteBot}>Delete all combinations</button>
                        }
                        {
                            /* chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.default_combination &&
                            <button className='chat-default' onClick={() => setOpenChatDefault(true)}>Test deployed version</button> */
                        }
                        <button className='refresh-btn' onClick={handleReload}>Refresh</button>
                    </div>

                    <div className="scrollable-sticky-head">
                        <div className="scrollable-table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th align="left">#</th>
                                        <th align="left">Chunk Size</th>
                                        <th align="left">Metric Type</th>
                                        <th align="left">Retrieval Method</th>
                                        <th align="left">Metadata Filtering</th>
                                        <th align="left">Post Retrieval</th>
                                        <th align="left">Top K</th>
                                        <th align="left">LLM</th>
                                        <th align="left">Embedding Model</th>
                                        <th align="left" style={{ cursor: "pointer" }} onClick={handleSortByAccuracyState}>
                                            Accuracy {sortOrder === 'asc' ? '↑' : '↓'}
                                        </th>
                                        <th align="left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chatbot.combinations?.length > 0 ? chatbot.combinations?.map((combination, index) => {

                                        return <tr key={index}>
                                            <td align="right" >{index}</td>
                                            <td align="right" >{combination.chunksize}</td>
                                            <td align="right" >{combination.metric}</td>
                                            <td align="right" >{combination.retrieval_method === 'complex-to-simple-query' ? 'deconstruction' : combination.retrieval_method}</td>
                                            <td align="right" >{`${combination.metadata_filtering}`}</td>
                                            <td align="right" >{combination.rerank_method}</td>
                                            <td align="right" >{combination.top_k}</td>
                                            <td align="right" >{combination.llm}</td>
                                            <td align="right" >{combination.embedding_model}</td>
                                            <td align="right" >{combination?.average_rating}</td>
                                            <td align="right" >
                                                {(loading && !combination.completed) ? <span style={{ fontSize: "10px", color: "var(--bgLight)" }}>Queued</span> : (checkCombinationValidity(combination?._id?.$oid) === "This combination failed. Please retry or try another combination." && !loading) ?
                                                    <div className='errored-combination'>
                                                        <Tooltip sx={{ cursor: "pointer" }} title="An error occurred while running the combination. Please retry!" placement="top" leaveDelay={1000}>
                                                            <ReportProblemOutlinedIcon />
                                                        </Tooltip>
                                                        <button className='error-retry' onClick={() => handleRetry(combination)}>Retry</button>
                                                    </div>
                                                    : combination?.completed && (
                                                        <div className='table-actions'>
                                                            <button className='btn-view-grid-details btn-view' onClick={() => handleOpenDetails(index)}>
                                                                View
                                                            </button>
                                                        </div>
                                                    )}
                                            </td>
                                        </tr>
                                    }) : combinations?.length > 0 && combinations?.map((combination, index) => {

                                        return <tr key={index}>
                                            <td align="right" >{index}</td>
                                            <td align="right" >{combination.chunksize}</td>
                                            <td align="right" >{combination.metric}</td>
                                            <td align="right" >{combination.retrieval_method}</td>
                                            <td align="right" >{`${combination.metadata_filtering}`}</td>
                                            <td align="right" >{combination.rerank_method}</td>
                                            <td align="right" >{combination.top_k}</td>
                                            <td align="right" >{combination.llm}</td>
                                            <td align="right" >{combination.embedding_model}</td>
                                            <td align="right" >{combination?.average_rating}</td>
                                            <td align="right" >
                                                {(loading && !combination.completed) ? <span style={{ fontSize: "10px", color: "var(--bgLight)" }}>Queued</span> : (checkCombinationValidity(combination?._id?.$oid) === "This combination failed. Please retry or try another combination." && !loading) ?
                                                    <div className='errored-combination'>
                                                        <Tooltip sx={{ cursor: "pointer" }} title="An error occurred while running the combination. Please retry!" placement="top" leaveDelay={1000}>
                                                            <ReportProblemOutlinedIcon />
                                                        </Tooltip>
                                                        <button className='error-retry' onClick={() => handleRetry(combination)}>Retry</button>
                                                    </div>
                                                    : combination?.completed && (
                                                        <div className='table-actions'>
                                                            <button className='btn-view-grid-details btn-view' onClick={() => handleOpenDetails(index)}>
                                                                View
                                                            </button>
                                                        </div>
                                                    )}
                                            </td>
                                        </tr>
                                    })
                                    }


                                </tbody>
                            </table>
                        </div>
                        <div className="navs">
                            <div>
                                <button className={`next`} onClick={() => {
                                    if (chatbot?.combinations[0]?.completed === true) {
                                        if (chatbot?.selected_combinations?.length > 0) {
                                            set_selected_combinations(chatbot?.selected_combinations || []);

                                            setValue("4");

                                        } else {
                                            const combinationsWithChecked = chatbot?.combinations?.map(combination => ({
                                                ...combination,
                                                checked: true,
                                            }));

                                            setCombinations(combinationsWithChecked);
                                            set_selected_combinations(combinationsWithChecked)
                                            setValue("3")
                                        }
                                    } else {
                                        setSnackbarOpen(true)
                                    }
                                }}  >Next</button>
                            </div>
                        </div>
                    </div>
                </div>}

            {
                value === "0" && <BotsList setValue={setValue} handleReload={handleReload} setLoading_message={setLoading_message} setLoading={setLoading} set_selected_combinations={set_selected_combinations} setCombinations={setCombinations} />
            }
            {
                value === "2" && <PromptReader combinations={combinations} setCombinations={setCombinations} selected_combinations={selected_combinations} set_selected_combinations={set_selected_combinations} prompts={prompts} setPrompts={setPrompts} setValue={setValue} bot_id={bot_id} value={value} chatbot={chatbot?.chatbots} />
            }
            {
                (value === "3") && <PromptGrid combinations={combinations} setCombinations={setCombinations} bot_id={bot_id} reload={reload} reloadUser={reloadUser} user_id={user_id} selected_combinations={selected_combinations} set_selected_combinations={set_selected_combinations} setTabName={setTabName} setValue={setValue} />
            }

            {
                (value === "4") && <PostPromptGrid combinations={chatbot?.selected_combinations > 0 ? chatbot?.selected_combinations : selected_combinations} setCombinations={setCombinations} bot_id={bot_id} reload={reload} reloadUser={reloadUser} user_id={user_id} selected_combinations={selected_combinations} set_selected_combinations={set_selected_combinations} setTabName={setTabName} setValue={setValue} />
            }

            {
                (value === "5") && <TestDeploy setCombinations={setCombinations} bot_id={bot_id} reload={reload} reloadUser={reloadUser} user_id={user_id} setTabName={setTabName} />
            }

            {
                (value === "6") && <UploadSources />
            }
        </div>
    )
}


export default PostGrid

{
    /*  
    
     <div className='postgrid-info'>
         <p>Select the top few combinations on which you would like to optimize the prompt.</p>
     </div>
    
    <div>
                         <Tooltip disableHoverListener={(combinations[chatbot?.combinationUsed]?.completed ===true)} title="You need to initialize and run all the combinations to go to the next step">
                             <button className={`next ${(combinations[chatbot?.combinationUsed]?.completed ===false) ? 'button-disabled' : ''}`} onClick =  {() => {
                                     if(selected_combinations.length > 0){
         
                                         setValue("2")
                                     }else{
                                         setSnackbarOpen(true)
                                     }
                             }} disabled = {(combinations[chatbot?.combinationUsed]?.completed ===false) }>Next</button>
                         </Tooltip>
                     </div> */
}