import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import './registerOrganization.css'
import CustomInput from '../../../Components/Input/CustomInput'
import Loader from '../../../Components/Loader'


const Organization = () => {

    const navigate = useNavigate()
    const [orgName, setOrgName] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        localStorage.clear();
    }, [])

    const saveOrganization = async () => {
        setLoading(true)
        navigate('/auth/signup', { state: { orgName } });
        setLoading(false);

    }

    const handleGotoLogin = () => {
        navigate('/auth/login')
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div className="organization-container">
            <div className="organization">
                <div className="organization-heading"><p>Add Organization</p></div>
                <div className='customInput-container'>
                    <CustomInput multiple={false} className={'customColor'} placeholder={"organization name"} type={"text"} value={orgName} name={"organization"} onHandleChange={(event) => setOrgName(event.target.value)} />
                </div>
                <div className="btn btn-organization">
                    <button style={{ backgroundColor: "var(--buttonBackground)", color: "white" }} onClick={saveOrganization}>Add</button>
                    <p className="not-a-user">Already registered? <span onClick={handleGotoLogin} className="create-account">Login</span></p>
                </div>
            </div>
        </div>
    )
}

export default Organization