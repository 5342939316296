import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import colors from '../../Constants/colors'
import { useNavigate } from 'react-router-dom'
import Loader from '../../Components/Loader'

const Home = () => {

  const navigate = useNavigate();
  useEffect(() => {
    navigate("/add/organization")
  }, [navigate])
  return (
    <Box bgcolor={colors.bg} display={'flex'} flexDirection={'column'}>
      <Loader />
      {/*  <Navigation />
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'} padding={10}>

          <Typography fontSize={48} color={'#ffffff'}>Chatbot for your usecase</Typography>
          <Typography fontSize={18} mt={3} color={'#ffffff'}>AI with knowledge base, AI assistant, AI Assessment tool
            All within your reach</Typography>
          <Button LinkComponent={Link} to="auth/add/organization" sx={{ marginTop: 4, borderRadius: 4 }} variant='contained'>Get Started Now</Button>
        </Box>

        <Box mr={10}>

        </Box>
      </Box>

      <Customise />
      <Features /> */}
    </Box>

  )
}

export default Home