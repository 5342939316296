import React, { useCallback, useEffect, useState } from 'react'
import "./AppDrawerNav.css"
import { AppBar, Box, CssBaseline, Dialog, DialogTitle, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ScienceIcon from '@mui/icons-material/Science';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import colors from '../../Constants/colors';
import BotType from './ChatBotRetrievalDocs/RetrievalBot/BotType';
import MyBots from './MyBots/MyBots';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserComplete } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router';
import { getBotbyCreator, getBotbyOrg, getCombinations, get_selected_combinations, resetChatbot } from '../../features/chatbots/chatbotSlice';
import Logo from '../../Components/Logo/Logo'
import Loader from '../../Components/Loader';
import SettingsPage from './ChatBotRetrievalDocs/SettingsPage/SettingsPage';
import axios from 'axios';
import UserManagement from './UserManagement/UserManagement';
import { getOrganization, get_organization_by_user } from '../../features/organization/orgSlice';


const drawerWidth = 260;


const AppDrawerNav = ({ botData, setBotData, advancedMode, setAdvancedMode, combinations, setCombinations, selected_combinations, set_selected_combinations, subscriptionPackage, setSubscriptionPackage }) => {
  const { user, isLoading, usage } = useSelector(state => state.auth)
  const chatbot = useSelector(state => state.chatbot);
  const organization = useSelector(state => state.organization)
  const [tabName, setTabName] = useState('createChatbot');
  const [openKeys, setOpenKeys] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = useCallback(() => {
    window.location.href = 'https://queryloop-ai.com/';
    dispatch(resetUserComplete());
    dispatch(resetChatbot());
  }, [dispatch]);

  const id = user?.user?._id['$oid'];
  const orgId = organization?.organization?._id?.["$oid"]

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const packag = localStorage.getItem("package");
    if (sessionId && packag) {

      const token = JSON.parse(localStorage.getItem("token"))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          // Other headers if needed
        },
      };

      axios.get(`/api/payments/session-status/${orgId}/${sessionId}/${packag}`, config)
        .then((response) => {
          setShowPaymentSuccess(true);
          dispatch(getOrganization(orgId));
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          // Handle the error appropriately
        });
    }


  }, []);

  useEffect(() => {
    if (user === null) {
      alert('Session expired. Please, Login Again!');
      handleLogout();
    }

    if ((!organization?.organization || !chatbot?.chatbots) || (chatbot?.chatbots?.combinations?.length < organization?.organization?.bot?.length)) {
      if (id) {
        dispatch(get_organization_by_user(id)).then(() => {
          dispatch(getBotbyOrg(orgId));
        }
        ).then(() => {
          if (chatbot.chatbots?.combinations?.length > 0) {
            const bot_id = chatbot?.chatbots?.combinations?.[chatbot.combinationUsed]?._id?.['$oid'];
            if (bot_id) {
              dispatch(getCombinations(bot_id));
              dispatch(get_selected_combinations(bot_id));
            }
          }
        });
      }
    }

    if (chatbot?.combinations?.length === 0 && organization?.organization?.bot?.length > 0) {
      const bot_id = organization?.organization?.bot[chatbot.combinationUsed]["$oid"];
      if (bot_id) {
        dispatch(getCombinations(bot_id));
        dispatch(get_selected_combinations(bot_id));
      }
    }

    if (chatbot?.combinations?.length > 0 && organization?.organization?.bot?.length > 0) {
      reload();
    }
    setBotData({ ...botData, organization: orgId, creator: id });
  }, [user, chatbot.chatbots?.combinations?.length, chatbot?.combinations?.length, id, dispatch, handleLogout, chatbot?.chatbots]);


  const reload = async () => {

    if (organization?.organization?.bot?.length > 0) {
      const bot_id = organization?.organization?.bot?.[chatbot.combinationUsed]?.["$oid"];
      if (bot_id) {
        await dispatch(getCombinations(bot_id))
        await dispatch(get_selected_combinations(bot_id))
      }
    }


  }

  const reloadUser = async () => {
    if (organization?.organization?.bot?.length > 0) {

    } else {
      const orgId = organization?.organization?._id['$oid'];
      if (orgId) {
        await dispatch(getOrganization(orgId))
      }
    }

  }

  if (isLoading === true) {
    return (
      <Loader />
    )
  }

  return (
    <Box sx={{ display: 'flex' }} bgcolor={'white'} padding={"0px"}>
      <Dialog open={showPaymentSuccess} onClose={() => setShowPaymentSuccess(false)}>
        <div className='dialog-payment-success'>
          <DialogTitle>
            You have successfully completed the payment.
          </DialogTitle>
          <div style={{ padding: "20px" }}>
            <p>
              Please contact on <b>contact@queryloop-ai.com</b> for any assistance.
            </p>
            <button onClick={() => setShowPaymentSuccess(false)} style={{
              backgroundColor: "white", borderRadius: "5px", color: "white"
            }}>Done</button>
          </div>
        </div>
      </Dialog>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, border: 'none', padding: '0px' }}
      >
      </AppBar>
      <Drawer
        sx={{
          width: windowWidth >= 560 ? drawerWidth : 90,
          flexShrink: 0,
          '&.MuiDrawer-paper': {
            width: windowWidth >= 560 ? drawerWidth : 90,
          },
          border: 'none', padding: '0px'
        }}
        variant="permanent"
        anchor="left"
      >

        <List style={{ height: "100vh", backgroundColor: colors.bgDark, color: colors.bgLight, width: windowWidth > 560 ? drawerWidth : "90px", boxShadow: "10px 0px 13px -5px rgba(0,0,0,0.49)", zIndex: "1", padding: "0px", border: 'none' }}>
          <Box display={'flex'} flexDirection={'row'} padding={4} alignItems={'center'} justifyContent={'flex-start'} style={{ backgroundColor: colors.bgDark, width: windowWidth > 560 ? drawerWidth : "90px" }}>
            <Typography fontSize={windowWidth > 560 ? 28 : 20} color={colors.text} fontFamily={'Oxygen'} mr={windowWidth > 560 ? 1 : 0}>{windowWidth > 560 ? "Queryloop" : "Q"}</Typography>
            <Logo />
          </Box>
          {
            <ListItem sx={{ ...tabName === "createChatbot" && { bgcolor: colors.bgBlue, color: 'white', padding: "0" }, width: windowWidth > 560 ? '95%' : "70px", paddingLeft: windowWidth < 560 && "0px", paddingRight: windowWidth < 560 && "0px", }}>
              <ListItemButton sx={{ paddingRight: windowWidth < 560 && "24px" }} onClick={() => {
                setTabName('createChatbot')
                navigate(`/dashboard/createChatbot`)
              }}  >
                <ListItemIcon>
                  <AddCircleIcon fontSize='small' sx={{ ...tabName === "createChatbot" && { bgcolor: colors.bgBlue, color: 'white' } }} />
                </ListItemIcon>
                <ListItemText primary={windowWidth >= 560 ? "Create LLM App" : null} />
              </ListItemButton>
            </ListItem>
          }


          <ListItem sx={{ ...tabName === "myBots" && { bgcolor: colors.bgBlue, color: 'white', padding: "0" }, width: '100%', width: windowWidth > 560 ? '95%' : "70px", paddingLeft: windowWidth < 560 && "0px", paddingRight: windowWidth < 560 && "0px", }} >
            <ListItemButton onClick={() => {
              navigate(`/dashboard/myBots/`)
              setTabName('myBots')
            }}>
              <ListItemIcon>
                <ScienceIcon sx={{ ...tabName === "myBots" && { bgcolor: colors.bgBlue, color: 'white' } }} />
              </ListItemIcon>
              <ListItemText primary={windowWidth >= 560 ? "Experiments" : null} />
            </ListItemButton>
          </ListItem>


          <ListItem sx={{ ...tabName === "settingsPage" && { bgcolor: colors.bgBlue, color: 'white', padding: "0" }, width: '95%', width: windowWidth > 560 ? '95%' : "70px", paddingLeft: windowWidth < 560 && "0px", paddingRight: windowWidth < 560 && "0px", }} >
            <ListItemButton onClick={() => {
              navigate(`/dashboard/settingsPage/`)
              setTabName('settingsPage')
            }}>
              <ListItemIcon>
                <SettingsIcon sx={{ ...tabName === "settingsPage" && { bgcolor: colors.bgBlue, color: 'white' } }} />
              </ListItemIcon>
              <ListItemText primary={windowWidth >= 560 ? "Settings" : null} />
            </ListItemButton>
          </ListItem>


          <ListItem sx={{ ...tabName === "userManagement" && { bgcolor: colors.bgBlue, color: 'white', padding: "0" }, width: '95%', width: windowWidth > 560 ? '95%' : "70px", paddingLeft: windowWidth < 560 && "0px", paddingRight: windowWidth < 560 && "0px", }} >
            <ListItemButton onClick={() => {
              navigate(`/dashboard/userManagement/`)
              setTabName('userManagement')
            }}>
              <ListItemIcon>
                <ManageAccountsIcon sx={{ ...tabName === "userManagement" && { bgcolor: colors.bgBlue, color: 'white' } }} />
              </ListItemIcon>
              <ListItemText primary={windowWidth >= 560 ? "User Management" : null} />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton onClick={handleLogout} sx={{ borderRadius: 2, width: windowWidth > 560 ? '95%' : "70px", paddingLeft: windowWidth < 560 && "8px", paddingRight: windowWidth < 560 && "0px" }} >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={windowWidth >= 560 ? "Sign out" : null} />
            </ListItemButton>
          </ListItem>


          {/* <iframe src="http://localhost:3000/chat-interface" width="260px" height="500px"></iframe> */}

        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: colors.bgDark, p: 0, width: '400px' }}
      >
        {tabName === "createChatbot" ? (<BotType setTabName={setTabName} botData={botData} setBotData={setBotData} combinations={combinations} setCombinations={setCombinations} advancedMode={advancedMode} setAdvancedMode={setAdvancedMode} />) : null}

        {tabName === "myBots" ? (<MyBots setTabName={setTabName} reloadUser={reloadUser} reload={reload} selected_combinations={selected_combinations} set_selected_combinations={set_selected_combinations} combinations={combinations} setCombinations={setCombinations} bot_id={chatbot?.chatbots?.combinations?.[chatbot.combinationUsed]?._id?.["$oid"] ? chatbot?.chatbots?.combinations?.[chatbot.combinationUsed]?._id?.["$oid"] : ""} user_id={id} />) : null}

        {tabName === "settingsPage" ? (<SettingsPage subscriptionPackage={subscriptionPackage} setSubscriptionPackage={setSubscriptionPackage} setTabName={setTabName} reloadUser={reloadUser} reload={reload} userId={id} usage={usage} />) : null}

        {tabName === "userManagement" ? (<UserManagement />) : null}

      </Box>
    </Box>
  )
}

export default AppDrawerNav