import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import queryloopIcon from "../../Assets/queryloop.png"
import userIcon from "../../Assets/user.png"
import "./ChatInterface.css"

const ChatInterface = () => {

    const [defaultHistory, setDefaultHistory] = useState([]);
    const [defaultHuman, setDefaultHuman] = useState([]);
    const [api_key, setApi_key] = useState("");
    const [userData, setUserData] = useState({ email: "", password: "" });
    const [enable, set_enable] = useState(true);
    const textareaRef = useRef(null);
    const [openBubble, setOpenBubble] = useState(false);

    useEffect(() => {
        const api = (localStorage.getItem("api_key"));
        if (api) {
            setApi_key(api);
            set_enable(false);
        } else {
            set_enable(true)
        }

    }, [])

    const handleLogin = async () => {
        localStorage.setItem("api_key", api_key)
        set_enable(false);
    }

    const handleNoLogin = () => {
        localStorage.setItem("api_key", "no key")
        setDefaultHistory([]);
        set_enable(false);
    }

    const handleChat = async () => {
        if (defaultHuman.length > 0) {
            setDefaultHistory([...defaultHistory, { human: defaultHuman, ai: "Generating ..." }])
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "apiKey": api_key,
                },
            };
            axios
                .post(
                    `http://localhost:5000/api/developer/use`,
                    { query: defaultHuman },
                    config
                )
                .then((response) => {
                    console.log(response)
                    setDefaultHistory([...defaultHistory, { ai: response.data.answer, human: defaultHuman }]);
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            alert("Please type any query!")
        }
    }

    const handlekeydown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevents the default behavior of the Enter key
            setDefaultHistory([...defaultHistory, { ai: "thinking... ", human: defaultHuman }]);
            setDefaultHuman("")
            handleChat();
            adjustTextareaRows();
        }

    };


    const adjustTextareaRows = () => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = "auto"; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content
        }
    };

    const handleChangeInput = (e) => {
        setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    return (
        !openBubble ?
            <div className='chat-bubble' onClick={() => setOpenBubble(true)}>
                <img src={queryloopIcon} alt="Chat" />
            </div>
            :
            <div className='chat-wrapper'>
                <div className='btn-back'>
                    <button onClick={() => setOpenBubble(false)}>X</button>
                </div>

                {(enable) ?
                    <div className='api-input'>
                        <div className='queryloop-title'>
                            <h1>Login to Queryloop</h1>
                        </div>
                        <div className='input-form_input'>
                            <p>Enter your email</p>
                            <input placeholder='Enter your email' name="email" value={userData.email} onChange={handleChangeInput} />
                        </div>
                        <div className='input-form_input'>
                            <p>Enter your password</p>
                            <input placeholder='Enter your password' name="password" value={userData.password} onChange={handleChangeInput} />
                        </div>
                        <div className='api-input-actions'>
                            <button className='btn-login' onClick={handleLogin}>Login</button>
                            <button className='btn-no-login' onClick={handleNoLogin}>Continue without login</button>
                        </div>
                    </div> :

                    <div className='chat-container_interface'>

                        <div className='chat-point_window'>
                            {defaultHistory.map((msg, index) => (
                                <div className='message-container' key={index}>
                                    <div className="human-message">
                                        <img src={userIcon} alt="user" />
                                        <p>{msg.human}</p>
                                    </div>
                                    <div className="ai-message">
                                        <img src={queryloopIcon} alt="queryloop" />
                                        <p>{msg.ai}</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div>

                            <textarea
                                ref={textareaRef}
                                value={defaultHuman}
                                onChange={(e) => {
                                    setDefaultHuman(e.target.value);
                                    adjustTextareaRows();
                                }}
                                autoFocus={true}
                                onKeyDown={handlekeydown}
                                placeholder="Start typing"
                                className="user-input-area"

                            ></textarea>
                        </div>
                        <div></div>
                    </div>}
            </div>
    )
}

export default ChatInterface