import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './MyKeys.css';


const MyKeys = () => {
    const [openAIKey, setOpenAIKey] = useState('');
    const [pineconeKey, setPineconeKey] = useState('');
    const [openAiUsage, setOpenAiUsage] = useState('');

    const handleSave = () => {

        console.log("Save keys");
    };

    const accordioStyles = {
        boxShadow: '0px 0px 10px rgba(52, 102, 255, 0.3)',
        backgroundColor: '#050505',
        color: 'var(--bgLight)',
        border: '1px solid var(--buttonBackground)'
    }

    return (
        <div className='mykeys'>
            <div className='open_keys_mykeys'>
                <h3>Add API Keys</h3>
                <div className='accordion-wrapper'>
                    <Accordion sx={accordioStyles}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon color='info' />}>
                            <Typography>Don't want to add keys? (Limitations will apply)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul>
                                <li>Create a single bot</li>
                                <li>Upload upto 5 files and 20Mbs total size </li>
                                <li>Enter upto 5 Ideal Responses</li>
                                <li>Run and evaluate upto 5 combinations</li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordioStyles}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon color='info' />}>
                            <Typography>With OpenAI Key</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul>
                                <li>File upload limit (Upload upto 5 files and 20Mbs total size)</li>
                                <li>Enter OpenAI usage limit</li>
                                <li>Fine tune GPT3.5 only</li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={accordioStyles}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon color='info' />}>
                            <Typography>With OpenAI and Pinecone Keys</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul>
                                <li>No limitation on usage/upload</li>
                                <li>Cannot Deploy the app on our cloud</li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                    <div class="trial-notice-mykeys">
                        Your trial files are stored on S3 for a maximum of 5 days. To prevent data deletion, please subscribe before the 5th day. You'll receive an email reminder.
                    </div>
                </div>
                <div className='inputs-wrapper'>
                    <TextField
                        label="Enter OpenAI Key"
                        value={openAIKey}
                        onChange={(e) => setOpenAIKey(e.target.value)}
                        margin="dense"
                        fullWidth
                        variant="outlined"

                        sx={{
                            backgroundColor: '#050505',
                            color: 'var(--buttonBackground)',                 // Text color

                            '& .MuiOutlinedInput-root': {   // Input border styles
                                '& fieldset': {
                                    borderColor: 'var(--buttonBackground)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'var(--buttonBackground)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'var(--buttonBackground)'
                                }
                            },

                            '& .MuiInputBase-input': {     // Text color and placeholder
                                color: 'var(--buttonBackground)'
                            },

                            '& .MuiInputLabel-root': {      // Label color
                                color: 'var(--buttonBackground)'
                            },

                            '& .MuiInputBase-input::placeholder': {  // Adjust placeholder opacity if desired
                                color: 'var(--buttonBackground)',
                            }
                        }}

                    />
                    <TextField
                        label="Enter OpenAi usage limit in USD"
                        value={openAiUsage}
                        onChange={(e) => setOpenAiUsage(e.target.value)}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        sx={{
                            backgroundColor: '#050505',
                            color: 'var(--buttonBackground)',                 // Text color

                            '& .MuiOutlinedInput-root': {   // Input border styles
                                '& fieldset': {
                                    borderColor: 'var(--buttonBackground)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'var(--buttonBackground)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'var(--buttonBackground)'
                                }
                            },

                            '& .MuiInputBase-input': {     // Text color and placeholder
                                color: 'var(--buttonBackground)'
                            },

                            '& .MuiInputLabel-root': {      // Label color
                                color: 'var(--buttonBackground)'
                            },

                            '& .MuiInputBase-input::placeholder': {  // Adjust placeholder opacity if desired
                                color: 'var(--buttonBackground)',
                            }
                        }}
                    />
                    <TextField
                        label="Enter Pinecone Key"
                        value={pineconeKey}
                        onChange={(e) => setPineconeKey(e.target.value)}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        sx={{
                            backgroundColor: '#050505',
                            color: 'var(--buttonBackground)',                 // Text color

                            '& .MuiOutlinedInput-root': {   // Input border styles
                                '& fieldset': {
                                    borderColor: 'var(--buttonBackground)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'var(--buttonBackground)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'var(--buttonBackground)'
                                }
                            },

                            '& .MuiInputBase-input': {     // Text color and placeholder
                                color: 'var(--buttonBackground)'
                            },

                            '& .MuiInputLabel-root': {      // Label color
                                color: 'var(--buttonBackground)'
                            },

                            '& .MuiInputBase-input::placeholder': {  // Adjust placeholder opacity if desired
                                color: 'var(--buttonBackground)',
                            }
                        }}
                    />
                </div>
                <div className='actions-wrapper'>
                    <button onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default MyKeys;