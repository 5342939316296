import React from 'react'
import "./CustomTextArea.css"
import InfoOutlined from '@mui/icons-material/InfoOutlined';
const CustomTextArea = ({title, placeholder,value, handleChangeInput, rows, disabled, customLabel, LabelStyles, handleOpenSideBar, type, showInfo, customStyles}) => {
  const disable = disabled ? true : false;
  const titleClass = customLabel ? customLabel : "title";
  const styles = customStyles ? customStyles : {};
  const noOfRows = rows ? rows : 5;
  return (
    <div className='custom-text-area'>
        <div className={titleClass}>
            <p style={LabelStyles}>{title}{showInfo && <span className='info' onClick={() => handleOpenSideBar(type)}><InfoOutlined fontSize='small'/></span>}</p>
        </div>
    
        <div className='text-area'>
            <textarea
                rows={noOfRows}
                placeholder={placeholder}
                value={value}
                onChange={handleChangeInput}
                disabled={disable}
                style={styles}
              />
        </div>
    </div>
  )
}

export default CustomTextArea