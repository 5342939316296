import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  MenuItem,
  Snackbar,
  Select, FormControl, InputLabel
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';

import colors from "../../../../Constants/colors";

import { useDispatch, useSelector } from "react-redux";
import {
  createBot,
  getDataTypes,
  resetBotState,
} from "../../../../features/chatbots/chatbotSlice";
import "./Questionnaire/questionnaire.css"
import "./BotType.css"
import Loader from "../../../../Components/Loader";
import axios from "axios"
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge, Handle, Position,
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomInput from "../../../../Components/Input/CustomInput";
import CustomTextArea from "../../../../Components/CustomTextArea/CustomTextArea";
import Checkbox from "../../../../Components/CheckBox/Checkbox";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Papa from 'papaparse';
import { handleLatencyChange, handleMMRChange, handleMemoryChange, handleReferenceChange, handleResponseLengthChange, handleSentenceWindowChange, handleSourcesChange, handleTWRChange, handleToneChange } from "./Functions/PromptCheckers";
import { getOrganization } from "../../../../features/organization/orgSlice";
import { getUser } from "../../../../features/auth/authSlice";
import SideBar from "../../../../Components/SideBar/SideBar";
import AddKeys from "../../../../Components/PopUp/AddKeys"
import CustomSelect from "../../../../Components/SelectBox/CustomSelect";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});




const BotType = ({ botData, setBotData, setTabName, combinations, setCombinations }) => {
  const { user } = useSelector((state) => state.auth);
  const chatbot = useSelector((state) => state.chatbot);
  const organization = useSelector((state) => state.organization);

  const dispatch = useDispatch();

  const [openFileAlert, setopenFileAlert] = useState(false);
  const [type, set_type] = useState("");
  const [open_sidebar, set_open_sidebar] = useState(false)
  const [openKeys, setOpenKeys] = useState(false);
  const [info, setInfo] = useState([]);
  const [infoHeading, setInfoHeading] = useState({});

  const infoHeadingDataset = { "for": "pinecone", "info": "You are trying to upload more than 5 files or more than 20MB of data", "title": "Choosing to proceed without a Pinecone API Key will result in the following limitations:", "fileAlert": true }
  const infoDataset = ["Maximum file uploads: 5", "Total upload size limit: 20 MB"]

  const infoHeadingGolden = { "for": "open_ai", "info": "You are trying to add more than 5 Ideal Responses", "title": "Choosing to proceed without an OpenAI API Key will result in the following limitations:" }
  const infoGolden = ["Maximum limit: 5 Ideal Responses"]

  const infoHeadingLLM = { "for": "open_ai", "info": "You are trying to add gpt-4 LLM model", "title": "Choosing to proceed without an OpenAI API Key will result in the following limitations:" }
  const infoLLM = ["You cannot choose gpt-4-turbo as LLM"]

  const handleOpenSideBar = (infotype) => {
    set_type(infotype)
    set_open_sidebar(true)
    scroll();
  }

  const scroll = () => {

    setTimeout(() => {
      const closeButton = document.querySelector("#btn-close-sidebar");
      if (closeButton) {
        closeButton.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);

  }


  const [elements, setElements, onNodesChange] = useNodesState([
    {
      id: "InputNodes",
      type: "InputNodes",
      position: { x: 0, y: 50 }
    },
    {
      id: "problemStatement",
      type: "problemStatement",
      position: { x: 30, y: 100 },
      data: {
        value: botData.problem_statement,
        onChange: (event) => setBotData({ ...botData, problem_statement: event.target.value })
      },

      parentNode: "InputNodes"
    },

    {
      id: "prompt",
      type: "prompt",
      position: { x: 550, y: 330 },
      _data: {
        botData: botData,
      }
    },

    {
      id: "datasets",
      type: "datasets",
      position: { x: 30, y: 380 },
      _data: {
        botData: botData,
      }, parentNode: "InputNodes"
    },
    {
      id: "goldenResponse",
      type: "goldenResponse",
      position: { x: 30, y: 760 },
      _data: {
        botData: botData,
      }, parentNode: "InputNodes"
    },

    {
      id: "QueryloopNode",
      type: "QueryloopNode",
      position: { x: 1200, y: 0 }
    },
    {
      id: "chunk",
      type: "chunk",
      position: { x: 50, y: 100 },
      setBotData: setBotData,
      parentNode: "QueryloopNode"
    },
    {
      id: "metricType",
      type: "metricType",
      position: { x: 300, y: 100 },
      parentNode: "QueryloopNode"
    },
    {
      id: "retrievalMethod",
      type: "retrievalMethod",
      position: { x: 550, y: 100 },
      parentNode: "QueryloopNode"
    },
    {
      id: "metadataFiltering",
      type: "metadataFiltering",
      position: { x: 800, y: 100 }, parentNode: "QueryloopNode"
    },
    {
      id: "rerank",
      type: "rerank",
      position: { x: 50, y: 550 }, parentNode: "QueryloopNode"
    },
    {
      id: "topk",
      type: "topk",
      position: { x: 300, y: 550 }, parentNode: "QueryloopNode"
    },
    {
      id: "llm",
      type: "llm",
      position: { x: 550, y: 550 }, parentNode: "QueryloopNode"
    },
    {
      id: "embeddingModel",
      type: "embeddingModel",
      position: { x: 800, y: 550 }, parentNode: "QueryloopNode"
    },
  ]
  );

  {
    // { id: 'e-chunk-Queryloop', source: 'chunk', animated: true, target: 'Queryloop', arrowHeadType: 'arrowclosed' },
    // { id: 'e-metricType-Queryloop', source: 'metricType', animated: true, target: 'Queryloop', arrowHeadType: 'arrowclosed' },
    // { id: 'e-metadataFiltering-Queryloop', source: 'metadataFiltering', animated: true, target: 'Queryloop', arrowHeadType: 'arrowclosed' },
    // { id: 'e-retrievalMethod-Queryloop', source: 'retrievalMethod', animated: true, target: 'Queryloop', arrowHeadType: 'arrowclosed' },
    // { id: 'e-rerank-Queryloop', source: 'rerank', animated: true, target: 'Queryloop', arrowHeadType: 'arrowclosed' },
    // { id: 'e-topk-Queryloop', source: 'topk', animated: true, target: 'Queryloop', arrowHeadType: 'arrowclosed' },
    // { id: 'e-llm-Queryloop', source: 'llm', animated: true, target: 'Queryloop', arrowHeadType: 'arrowclosed' },
    // { id: 'e-embeddingModel-Queryloop', source: 'embeddingModel', animated: true, target: 'Queryloop', arrowHeadType: 'arrowclosed' },
    // { id: 'e-Queryloop-create', source: 'Queryloop', animated: true, target: 'create', arrowHeadType: 'arrowclosed' },
  }

  const [edges, setEdges, onEdgesChange] = useEdgesState([
    { id: 'e-problemstatement-BasePrompt', source: 'InputNodes', animated: true, target: 'prompt', arrowHeadType: 'arrowclosed' },
    { id: 'e-goldenresponse-Queryloop', source: 'prompt', animated: true, target: 'QueryloopNode', arrowHeadType: 'arrowclosed' },
  ])


  const nodeTypesList = useMemo(() => ({ InputNodes, TableNode, QueryloopNode, problemStatement: ProblemStatementNode, datasets: UploadDatasetsNode, prompt: PromptNode, goldenResponse: GoldenResponseNode, chunk: ChunkSizeNode, metricType: MetricType, retrievalMethod: RetrievalMethod, metadataFiltering: MetadataFiltering, rerank: RerankMethod, topk: TopK, llm: LLM, embeddingModel: EmbeddingModel, Queryloop: Queryloop }), []);


  const reactFlowWrapper = useRef(null);



  const nodeTypes = {
    problemStatement: { label: "Problem Statement" },
    datasets: { label: "Datasets" },
    prompt: { label: "Prompt" },
    goldenResponse: { label: "Golden Responses" },
    promptConfigs: { label: "Prompt Configuration", desc: "The information provided in response to the following questions will be included in the prompt" },
    retrievalConfigs: { label: "Retrieval Configuration", desc: "Your responses will be used to select the relevant retriever parameters." },
    hyperparameters: {
      label: "Hyperparameters",
    },
    chunk: { label: "Chunk Size" },
    QueryloopNode, TableNode, InputNodes,
  };

  useEffect(() => {
    dispatch(resetBotState());

    if (user !== null) {
      // console.log(user)
      const userID = user?.user?._id?.$oid;
      setBotData((prevData) => ({ ...prevData, creator: userID, organization: organization?.organization?._id["$oid"] }));

    }
  }, []);




  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setopenFileAlert(false);
  };



  const [openName, setOpenName] = useState(false);


  const handleChangeInput = (e) => {
    setBotData((prevData) => ({ ...prevData, name: e.target.value }));
  }

  const handleOpenName = () => {

    let message = "Please add all the following data required before creating the LLM application!"
    let check_ = false;
    if (botData.problem_statement === "") {
      message += "\nYou need to add a problem statement!"
      check_ = true;
    }
    if (botData.rqa_prompt === "") {
      message += "\nYou need to generate a prompt!"
      check_ = true;
    }
    if (botData.structured_datasets.length === 0 && botData.datasets.length === 0) {
      message += "\nYou need to add atlease one dataset!"
      check_ = true;
    }

    if (botData.golden_responses.length === 0) {
      message += "\nYou need to add atlease one ideal response!"
      check_ = true;
    }

    if (check_) {
      alert(message);
      return;
    }

    setOpenName(true);
    console.log(botData)

  }

  const handleCreateBot = async () => {

    // if (organization?.organization?.subscription === "starter" && organization?.organization?.bot?.length > 0) {
    //   alert("You cannot create more than one LLM application on the trial version. Please go to settings to upgrade your package.");
    //   return;
    // }
    // localStorage.setItem("value", JSON.stringify("1"));
    const name_ = chatbot?.chatbots?.combinations?.find(_ => _.name === botData.name);
    if (name_) {
      alert("Application name already exists. Please choose a different name.");
      return;
    }

    const formData = new FormData()

    for (const [key, value] of Object.entries(botData)) {

      let d_ = {}
      if (key === 'datasets') {
        botData.datasets.map((d, index) => (d_[key + index] = d))

        formData.append(key, JSON.stringify(d_))
        continue
      }
      else if (key === "parameter_key_names") {
        formData.append(key, JSON.stringify(value))
        continue
      }

      else if (key === "golden_responses") {
        formData.append(key, JSON.stringify(value))
        console.log("GOLDEN: ", value)
        continue
      }
      else if (key === "tone") {
        formData.append(key, JSON.stringify(value))
        continue
      }
      else if (key === "response_length") {
        formData.append(key, JSON.stringify(value))
        continue
      }
      else if (key === "references_text") {
        formData.append(key, JSON.stringify(value))
        continue
      }
      else if (key === "columnTypes") {
        formData.append(key, JSON.stringify(value))
        continue
      }
      else if (key === "structured_datasets") {
        botData.structured_datasets.map((d, index) => (d_[key + index] = d))
        formData.append(key, JSON.stringify(d_))
        continue
      }
      else if (key === "organization") {
        formData.append(key, organization?.organization?._id["$oid"])
        continue
      }
      else if (key === "creator") {
        formData.append(key, user?.user?._id['$oid'])
        continue
      }


      formData.append(key, value)

    }
    // console.log(botData.response_length_text, botData.recency_text, botData.tone_text, botData.references_text, botData.sources_text)
    let prompts = [botData.response_length_text, botData.recency_text, botData.tone_text, botData.references_text, botData.sources_text]
    let prompt_guides = prompts.filter(prompt => prompt !== "");

    formData.append("prompt_guides", JSON.stringify(prompt_guides));

    for (const entry of formData.entries()) {
      console.log(entry);
    }

    try {

      // Make the createBot API call and wait for it to complete
      await dispatch(createBot(formData));
      // Once createBot is successful, fetch the user data
      const user_id = user?.user?._id['$oid'];
      const orgId = organization?.organization?._id["$oid"]
      // After getUser is complete, set the tab name
      await dispatch(getUser(user_id));
      await dispatch(getOrganization(orgId));
      setTabName("myBots")


    } catch (error) {
      console.error('Error in handleCreateBot:', error);
      alert("There was an error while creating an LLM Application. Please try again!")
      // Handle any errors appropriately
      return;
    }


  }




  function QueryloopNode({ data }) {

    return (
      <div className="queryloop-node">
        <div className="top-nav-node">
          <p>Queryloop</p>
          <div>
            <InfoOutlined />
          </div>
        </div>
        <div className="queryloop-node_input">
        </div>
        <Handle type="source" position={Position.Right} id="a" />
        <Handle
          type="target"
          position={Position.Left}
          id="b"
        />
      </div>
    );
  }

  function InputNodes() {

    return (
      <div className="queryloop-node input-nodes">
        <div className="top-nav-node">
          <p>Inputs</p>
          <div>
            <InfoOutlined />
          </div>
        </div>
        <div className="queryloop-node_input">
        </div>
        <Handle type="source" position={Position.Right} id="a" />
      </div>
    );
  }

  function ProblemStatementNode() {
    const [displayOptions, setDisplayOption] = useState(false);
    const [data, setData] = useState(botData, setBotData);
    const onProblemStatementChange = (event) => {

      localStorage.setItem("problem_statement", JSON.stringify(event.target.value));
      setData((prevData) => ({ ...prevData, problem_statement: event.target.value }));
      setBotData((prevData) => ({ ...prevData, problem_statement: event.target.value }));
    }
    return (
      <div className="problem-statement-node">
        <div className="top-nav-node">
          <p>Problem Statement</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("problem_statement")} />
          </div>
        </div>
        <div className="problem-statement-node_input">
          <CustomTextArea rows={6} placeholder={"What do you want to do?"} value={data.problem_statement} handleChangeInput={onProblemStatementChange} />
        </div>

        <div className="accordion">
          <button className="accordion-header" onClick={() => setDisplayOption(prev => !prev)}>Advanced Options</button>
          <Dialog open={displayOptions} fullWidth onClose={() => setDisplayOption(false)}>
            <div className="advanced-options">
              <p className="info">The information provided in response to the following questions will be included in the prompt</p>
              <div className='prompt-radio advanced-mode_category'>
                <p className='advanced-mode_question'>Do you need the app to identify and address any cultural nuances, biases, or discriminatory language in the text? If yes, please specify (example: Do not add documents with hate speech.)</p>
                <div>
                  <input
                    type='radio'
                    id='referencesYes'
                    name='references'
                    value='Yes'
                    checked={data.references}
                    onChange={() => handleReferenceChange(true, setData, setBotData)}
                  />
                  <label htmlFor='referencesYes'>Yes</label>
                </div>
                <div>
                  <input
                    type='radio'
                    id='referencesNo'
                    name='references'
                    value='No'
                    checked={!data.references}
                    onChange={() => handleReferenceChange(false, setData, setBotData)}
                  />
                  <label htmlFor='referencesNo'>No</label>
                </div>
                {data.references === true && <CustomTextArea
                  value={data.references_text}
                  handleChangeInput={(e) => {
                    setBotData((prevData) => ({ ...prevData, references_text: e.target.value }));
                    setData((prevData) => ({ ...prevData, references_text: e.target.value }));
                  }}
                  rows={1} title={"Enter references"}
                  LabelStyles={{ fontSize: '0.8rem', fontWeight: '600', fontFamily: "Oxygen, sans-serif", color: "rgb(67, 67, 67)", margin: "2px 8px" }}
                />}

                <p className='advanced-mode_question'>Do you prefer to prioritize more recent documents in your responses? </p>
                <div className='advanced-mode_radio'>
                  <div>
                    <input
                      type='radio'
                      id='addTWRYes'
                      name='twr'
                      value='Yes'
                      checked={data.recency}
                      onChange={() => handleTWRChange(true, setData, setBotData)}
                    />
                    <label htmlFor='addTWRYes'>Yes</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='addTWRNo'
                      name='twr'
                      value='No'
                      checked={!data.recency}
                      onChange={() => handleTWRChange(false, setData, setBotData)}
                    />
                    <label htmlFor='addTWRNo'>No</label>
                  </div>
                </div>


                <p className='advanced-mode_question'>Would you prefer the LLM to provide detailed or concise answers? </p>
                <div className='advanced-mode_radio'>
                  <div>
                    <input
                      type='radio'
                      id='responseLengthNo'
                      name='responseLength'
                      value={"no-preference"}
                      checked={data.response_length["no-preference"]}
                      onChange={(event) => handleResponseLengthChange(true, "no-preference", setData, setBotData)}
                    />
                    <label htmlFor='responseLengthYes'>No Preference</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='responseLengthYes'
                      name='responseLength'
                      value={"Yes"}
                      checked={data.response_length["short-sentences"]}
                      onChange={(event) => handleResponseLengthChange(true, "short-sentences", setData, setBotData)}
                    />
                    <label htmlFor='responseLengthYes'>Concise Answers</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='responseLengthNo'
                      name='responseLength'
                      value={"No"}
                      checked={data.response_length["detailed-answers"]}
                      onChange={(event) => handleResponseLengthChange(true, "detailed-answers", setData, setBotData)}
                    />
                    <label htmlFor='responseLengthYes'>Detailed Answers</label>
                  </div>

                </div>

                <p className='advanced-mode_question'>Do you want responses to reference or cite their sources for added reliability? </p>
                <div className='advanced-mode_radio'>
                  <div>
                    <input
                      type='radio'
                      id='sourcesYes'
                      name='sources'
                      value='Yes'
                      checked={data.sources}
                      onChange={() => handleSourcesChange(true, setData, setBotData)}
                    />
                    <label htmlFor='sourcesYes'>Yes</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='sourcesNo'
                      name='sources'
                      value='No'
                      checked={!data.sources}
                      onChange={() => handleSourcesChange(false, setData, setBotData)}
                    />
                    <label htmlFor='sourcesNo'>No</label>
                  </div>
                </div>

                <p className='advanced-mode_question'>Do you have specific preferences for the tone or style of the responses?</p>
                <div className='advanced-mode_radio'>
                  <div>
                    <input
                      type='radio'
                      id='noPreference'
                      name='tone'
                      value='no-preference'
                      checked={data.tone["no-preference"]}
                      onChange={event => handleToneChange(event.target.checked, "no-preference", setData, setBotData)}
                    />
                    <label htmlFor='noPreference'>No preference</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='toneFormal'
                      name='tone'
                      value='formal'
                      checked={data.tone["formal"]}
                      onChange={event => handleToneChange(event.target.checked, "formal", setData, setBotData)}
                    />
                    <label htmlFor='toneFormal'>The responses should be formal</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='toneInformal'
                      name='tone'
                      value='informal'
                      checked={data.tone["informal"]}
                      onChange={event => handleToneChange(event.target.checked, "informal", setData, setBotData)}
                    />
                    <label htmlFor='toneInformal'>The responses should be informal</label>
                  </div>
                </div>
                <div className='information-prompt'>
                  <p className="info">Your responses will be used to select the relevant retriever parameters.</p>
                </div>
                <p className='advanced-mode_question'>Your documents will be broken into parts and only certain part will be retrieved when a question is asked.  Can this lead to missing out on the required context?</p>
                <div className='advanced-mode_radio'>
                  <div>
                    <input
                      type='radio'
                      id='sentenceWindowYes'
                      name='sentenceWindow'
                      value='Yes'
                      checked={data.parameter_key_names.retrieval_method["sentence-window"]}
                      onChange={() => handleSentenceWindowChange(true, setData, setBotData)}
                    />
                    <label htmlFor='sentenceWindowYes'>Yes</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='sentenceWindowNo'
                      name='sentenceWindow'
                      value='No'
                      checked={!data.parameter_key_names.retrieval_method["sentence-window"]}
                      onChange={() => handleSentenceWindowChange(false, setData, setBotData)}
                    />
                    <label htmlFor='sentenceWindowNo'>No</label>
                  </div>
                </div>

                <p className='advanced-mode_question'>Does the text in your data repeat across different sections or locations?</p>
                <div className='advanced-mode_radio'>
                  <div>
                    <input
                      type='radio'
                      id='mmrYes'
                      name='mmr'
                      value='Yes'
                      checked={data.parameter_key_names.rerank_method["mmr"]}
                      onChange={() => handleMMRChange(true, setData, setBotData)}
                    />
                    <label htmlFor='mmrYes'>Yes</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='mmrNo'
                      name='mmr'
                      value='No'
                      checked={!data.parameter_key_names.rerank_method["mmr"]}
                      onChange={() => handleMMRChange(false, setData, setBotData)}
                    />
                    <label htmlFor='mmrNo'>No</label>
                  </div>
                </div>

                <p className='advanced-mode_question'>How important is minimizing response delay for the effectiveness of your application?</p>
                <div className='advanced-mode_radio'>
                  <div>
                    <input
                      type='radio'
                      id='latencyCritical'
                      name='latency'
                      value='critical'
                      checked={data.latency["critical"]}
                      onChange={event => handleLatencyChange(event.target.checked, "critical", setData, setBotData)}
                    />
                    <label htmlFor='latencyCritical'>Minimizing delay is critical</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='latencyNoCritical'
                      name='latency'
                      value='noCritical'
                      checked={data.latency["noCritical"]}
                      onChange={event => handleLatencyChange(event.target.checked, "noCritical", setData, setBotData)}
                    />
                    <label htmlFor='latencyNoCritical'>My application can tolerate some delay</label>
                  </div>
                </div>

                <p className='advanced-mode_question'>Do you want to ask follow-up questions?</p>
                <div className='advanced-mode_radio'>
                  <div>
                    <input
                      type='radio'
                      id='memoryYes'
                      name='memory'
                      value='Yes'
                      checked={data.have_memory}
                      onChange={() => handleMemoryChange(true, setData, setBotData)}
                    />
                    <label htmlFor='memoryYes'>Yes</label>
                  </div>

                  <div>
                    <input
                      type='radio'
                      id='memoryNo'
                      name='memory'
                      value='No'
                      checked={!data.have_memory}
                      onChange={() => handleMemoryChange(false, setData, setBotData)}
                    />
                    <label htmlFor='memoryNo'>No</label>
                  </div>
                </div>


              </div>
            </div>
          </Dialog>
        </div>

      </div>
    );
  }

  function UploadDatasetsNode() {
    const [data, setData] = useState(botData, setBotData);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [csvData, setCsvData] = useState({});
    const [columnTypes, setColumnTypes] = useState(botData.columnTypes || {});
    const [dataset, setDataset] = useState(botData?.structured_datasets[0]?.name || '');


    const [openDatasetModal, setOpenDatasetModal] = useState(false);


    function getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-based
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }



    const handleColumnTypeChange = (fileName, header, type) => {

      setColumnTypes(prevTypes => ({
        ...prevTypes,
        [fileName]: { ...prevTypes[fileName], [header]: type }
      }));

      setData((prevData) => ({ ...prevData, columnTypes: { ...prevData.columnTypes, ...prevData.columnTypes[fileName], [header]: type } }))
      setBotData((prevData) => ({ ...prevData, columnTypes: { ...prevData.columnTypes, ...prevData.columnTypes[fileName], [header]: type } }))

    }



    const handleUpload = (e) => {
      const files = e.target.files; // Get an array of selected files

      // const checkOpen_ai = !botData?.keys?.open_ai;
      // const checkPinecone = !botData?.keys?.pinecone;
      // const datasetLength = data.datasets.length + data.structured_datasets.length;


      // if (datasetLength > 5 && organization?.organization?.subscription === "starter" && checkPinecone && checkOpen_ai) {
      //   setInfoHeading((prev) => (infoHeadingDataset));
      //   setInfo((prev) => (infoDataset));
      //   setOpenKeys((prev) => (true));
      // }
      // if (files.length > 5 && organization?.organization?.subscription === "starter" && checkPinecone && checkOpen_ai) {
      //   setInfoHeading((prev) => (infoHeadingDataset));
      //   setInfo((prev) => (infoDataset));
      //   setOpenKeys((prev) => (true));
      // }

      // const totalSize = data.datasets.reduce((accumulator, dataset) => {
      //   if (dataset.hasOwnProperty('fileSize')) {
      //     return accumulator + dataset.fileSize;
      //   } else {
      //     return accumulator;
      //   }
      // }, 0) + data.structured_datasets.reduce((accumulator, dataset) => {
      //   if (dataset.hasOwnProperty('fileSize')) {
      //     return accumulator + dataset.fileSize;
      //   } else {
      //     return accumulator;
      //   }
      // }, 0);



      // if ((totalSize > 20) && organization?.organization?.subscription === "starter" && checkPinecone && checkOpen_ai) {
      //   setInfoHeading((prev) => (infoHeadingDataset));
      //   setInfo((prev) => (infoDataset));
      //   setOpenKeys((prev) => (true));
      // }

      let check = false;
      let fileSizes = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const uniqueId = Date.now() + "_" + i;
        const fileName = file.name;
        const fileSize = file.size;
        const reader = new FileReader();

        fileSizes.push(fileSize)
        const size = parseFloat(fileSizes.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (1024 * 1024))


        // setInfoHeading((prev) => (infoHeadingDataset));
        // setInfo((prev) => (infoDataset));
        // setOpenKeys((prev) => (true));


        reader.onload = (event) => {
          if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/pdf" || file.type === "text/plain") {
            check = true;
            setBotData((prevData) => ({
              ...prevData,
              datasets: [
                ...prevData.datasets,
                {
                  filedata: event.target.result,
                  id: uniqueId,
                  fileName: fileName,
                  fileType: file.type,
                  creationDate: getCurrentDate(),
                  category: "",
                  confidentiality: confid
                },
              ],
            }));

            setData((prevData) => ({
              ...prevData,
              datasets: [
                ...prevData.datasets,
                {
                  filedata: event.target.result,
                  id: uniqueId,
                  fileName: fileName,
                  fileType: file.type,
                  creationDate: getCurrentDate(),
                  category: "",
                  confidentiality: confid
                },
              ],
            }));


            console.log({
              filedata: event.target.result,
              id: uniqueId,
              fileName: fileName,
              fileType: file.type,
              creationDate: getCurrentDate(),
              category: "",
              confidentiality: confid
            })

          } else {
            alert(`${file.type} is not a supported file type.\nSupported file types are: PDF, DOCX`)
            return
          }
        };

        reader.readAsDataURL(file);
      }


    }


    const handleUploadStructured = (e) => {

      const datasetLength = data.datasets.length + data.structured_datasets.length;
      const checkOpen_ai = !botData?.keys?.open_ai;
      const checkPinecone = !botData?.keys?.pinecone;
      if (datasetLength > 5 && organization?.organization?.subscription === "starter" && checkOpen_ai && checkPinecone) {
        setInfoHeading((prev) => (infoHeadingDataset));
        setInfo((prev) => (infoDataset));
        setOpenKeys((prev) => (true));

      }

      const files = e.target.files;
      if (!files) {
        return;
      }

      // if (files.length > 5 && organization?.organization?.subscription === "starter" && checkOpen_ai && checkPinecone) {
      //   setInfoHeading((prev) => (infoHeadingDataset));
      //   setInfo((prev) => (infoDataset));
      //   setOpenKeys((prev) => (true));
      // }

      // const totalSize = data.datasets.reduce((accumulator, dataset) => {
      //   if (dataset.hasOwnProperty('fileSize')) {
      //     return accumulator + dataset.fileSize;
      //   } else {
      //     return accumulator;
      //   }
      // }, 0) + data.structured_datasets.reduce((accumulator, dataset) => {
      //   if (dataset.hasOwnProperty('fileSize')) {
      //     return accumulator + dataset.fileSize;
      //   } else {
      //     return accumulator;
      //   }
      // }, 0);



      // if ((totalSize > 20) && organization?.organization?.subscription === "starter" && checkOpen_ai && checkPinecone) {
      //   setInfoHeading((prev) => (infoHeadingDataset));
      //   setInfo((prev) => (infoDataset));
      //   setOpenKeys((prev) => (true));

      // }

      for (const file of files) {


        const reader = new FileReader();
        const size = file.size / (1024 * 1024); // Convert size to MB

        // if (size > 20 && organization?.organization?.subscription === "starter" && checkOpen_ai && checkPinecone) {
        //   setInfoHeading((prev) => (infoHeadingDataset));
        //   setInfo((prev) => (infoDataset));
        //   setOpenKeys((prev) => (true));


        // }

        Papa.parse(file, {
          complete: (result) => {
            const file_data = result.data;
            const headers = Object.keys(file_data[0]);
            const initialColumnTypes = headers.reduce((acc, header) => {
              acc[header] = getColumnDefaultType(file_data, header);
              return acc;
            }, {});
            setColumnTypes((prevData) => ({ ...prevData, [file.name]: initialColumnTypes }));
            setCsvData((prevData) => ({ ...prevData, [file.name]: file_data.slice(0, 3) }));
            setData((prevData) => ({ ...prevData, columnTypes: { ...prevData.columnTypes, [file.name]: initialColumnTypes }, structured_file: file_data.slice(0, 3) }))
            setBotData((prevData) => ({ ...prevData, columnTypes: { ...prevData.columnTypes, [file.name]: initialColumnTypes }, structured_file: file_data.slice(0, 3) }))

          },
          header: true
        });



        reader.onload = async (event) => {
          if (file.type === "text/csv" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", file.name);
            const dataTypesCSV = await dispatch(getDataTypes(formData));
            if (dataTypesCSV.payload.datatypes) {
              setData((prevData) => ({
                ...prevData,
                structured_datasets: [
                  ...(Array.isArray(prevData.structured_datasets) ? prevData.structured_datasets : []),
                  {
                    filedata: event.target.result,
                    id: getCurrentDate() + "_" + 1,
                    fileName: file.name,
                    fileType: file.type,
                    creationDate: getCurrentDate(),
                    category: "",
                    confidentiality: confid,
                    dataTypes: dataTypesCSV.payload.datatypes,
                    fileSize: size,
                  },
                ],
              }));
              setBotData((prevData) => ({
                ...prevData,
                structured_datasets: [
                  ...(Array.isArray(prevData.structured_datasets) ? prevData.structured_datasets : []),
                  {
                    filedata: event.target.result,
                    id: getCurrentDate() + "_" + 1,
                    fileName: file.name,
                    fileType: file.type,
                    creationDate: getCurrentDate(),
                    category: "",
                    confidentiality: confid,
                    dataTypes: dataTypesCSV.payload.datatypes,
                    fileSize: size,

                  },
                ],
              }));

              // console.log(file.type, {
              //   filedata: event.target.result,
              //   id: getCurrentDate() + "_" + 1,
              //   fileName: file.name,
              //   fileType: file.type,
              //   creationDate: getCurrentDate(),
              //   category: "",
              //   confidentiality: confid,
              //   dataTypes: dataTypesCSV.payload.datatypes,
              //   fileSize: size,
              // });

              setDataset(file.name);
            }

          } else {
            alert(`${file.type} is not a supported file type.\nSupported file types are: CSV, XLSX`);
          }
        };

        if (file.type === "text/csv") {
          reader.readAsText(file);
        } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          reader.readAsArrayBuffer(file);
        }
      }
    };

    const getColumnDefaultType = (file_data, columnName) => {
      // Check the values in the 3rd row (index 2) of the specified column
      if (file_data.length < 3) {
        // If the data has less than 3 rows, default to "Metadata column"
        return "Metadata column";
      }

      const value = file_data[2][columnName];
      if (!value.includes(',')) {
        return "Metadata column"; // Not comma-separated
      }
      return value.split(',').every(part => part.trim().split(/\s+/).length <= 4) ? "Metadata column" : "Text column";
    };


    const [confid, setConfid] = useState('Public');
    const [visibility, setVisibility] = useState('Public');

    const handleChange = (event) => {
      setVisibility(event.target.value);

      const indexOfDataset = data.structured_datasets.findIndex((_dataset) => _dataset.fileName === dataset);

      if (indexOfDataset !== -1) {
        setData((prevData) => ({
          ...prevData,
          structured_datasets: [
            ...prevData.structured_datasets.slice(0, indexOfDataset),
            {
              ...prevData.structured_datasets[indexOfDataset],
              confidentiality: event.target.value,
            },
            ...prevData.structured_datasets.slice(indexOfDataset + 1),
          ],
        }));
        setBotData((prevData) => ({
          ...prevData,
          structured_datasets: [
            ...prevData.structured_datasets.slice(0, indexOfDataset),
            {
              ...prevData.structured_datasets[indexOfDataset],
              confidentiality: event.target.value,
            },
            ...prevData.structured_datasets.slice(indexOfDataset + 1),
          ],
        }));
      } else {
        console.error("Dataset not found with filename:", dataset);
      }
    };

    const handleChangeGlobal = (selectedOption) => {
      setConfid(selectedOption.value);
    };

    const confidentialityOptions = [
      { value: 'Public', label: 'Public' },
      { value: 'Private', label: 'Private' },
      { value: 'Confidential', label: 'Confidential' },
    ];

    const handleChangeDataset = (event) => {
      setDataset(event.target.value);
    };



    const handleFileRemove = (uniqueIdToRemove) => {

      const updatedBotData = {
        ...botData,
        datasets: botData.datasets.filter(file => file.id !== uniqueIdToRemove)
      };
      setBotData((prevData) => ({ ...prevData, ...updatedBotData }));
      setData((prevData) => ({ ...prevData, ...updatedBotData }));
    }

    const handleDataTypeChange = (event, header, dataset) => {
      const index = data.structured_datasets.findIndex(_d => _d.fileName === dataset);

      // Ensure the dataset exists
      if (index !== -1) {
        // Create a copy of the dataset to avoid mutating state directly
        const updatedDataset = { ...data.structured_datasets[index] };

        // Update the dataTypes object with the new value for the given header
        updatedDataset.dataTypes = {
          ...updatedDataset.dataTypes,
          [header]: event.target.value
        };

        // Create a new array with the updated dataset
        const updatedDatasets = [...data.structured_datasets];
        updatedDatasets[index] = updatedDataset;

        // Update the state with the new array of datasets
        setData(prevData => ({
          ...prevData,
          structured_datasets: updatedDatasets
        }));

        setBotData(prevData => ({
          ...prevData,
          structured_datasets: updatedDatasets
        }));

      }

    };


    const handleStructuredFileRemove = (fileNameToRemove) => {

      setData(prevData => ({
        ...prevData,
        structured_datasets: prevData.structured_datasets.filter(
          dataset => dataset.fileName !== fileNameToRemove
        ),
        columnTypes: {
          ...Object.keys(prevData.columnTypes).reduce((acc, key) => {
            if (key !== fileNameToRemove) {
              acc[key] = prevData.columnTypes[key];
            }
            return acc;
          }, {})
        },
      }));


      setBotData(prevData => ({
        ...prevData,
        structured_datasets: prevData.structured_datasets.filter(
          dataset => dataset.fileName !== fileNameToRemove
        ),
        columnTypes: {
          ...Object.keys(prevData.columnTypes).reduce((acc, key) => {
            if (key !== fileNameToRemove) {
              acc[key] = prevData.columnTypes[key];
            }
            return acc;
          }, {})
        },
      }));


      setCsvData(prevCsvData => {
        const updatedCsvData = { ...prevCsvData };
        delete updatedCsvData[fileNameToRemove];
        return updatedCsvData;
      });
      setColumnTypes(prevColumnTypes => {
        const updatedColumnTypes = { ...prevColumnTypes };
        delete updatedColumnTypes[fileNameToRemove];
        return updatedColumnTypes;
      });


      if (dataset === fileNameToRemove) {
        setDataset(
          botData?.structured_datasets?.length > 0
            ? botData.structured_datasets[0].fileName
            : ''
        );
      }
    };

    return (
      <div className="node">
        <div className="top-nav-node">
          <p>Dataset</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("datasets")} />
          </div>

        </div>
        <div className="select-custom">
          <CustomSelect
            label="Confidentiality for files to select"
            options={confidentialityOptions}
            value={confid}
            onChange={handleChangeGlobal}
          />
        </div>
        <div className="node_input">

          <CustomInput placeholder={"select file"} value={selectedFiles} type={"file"} upload_type={"unstructured"} multiple={true} onHandleChange={handleUpload} />
          <CustomInput placeholder={"select file"} value={selectedFiles} type={"file"} upload_type={"structured"} multiple={true} onHandleChange={handleUploadStructured} />
          <button className="btn-view-datasets" onClick={() => setOpenDatasetModal(true)}>View Datasets</button>
        </div>

        <Dialog open={openDatasetModal} fullScreen onClose={() => setOpenDatasetModal(false)} sx={{ backgroundColor: '#050505' }}>
          <div className="table-responsive table-node">
            <div className="table-node-close">
              <button onClick={() => setOpenDatasetModal(false)}>X</button>
            </div>
            {data.datasets.length > 0 ? <div className='question-container-files'>
              <p className="info">Unstructured Data</p>
              {
                data
                  ?.datasets
                  ?.map((file, index) => (
                    <div key={file.fileName} className='files-container'>
                      <div className='files-info'>
                        <p>{file.fileName}</p>
                        <button onClick={() => handleFileRemove(file.id)}>X</button>
                      </div>
                    </div>
                  ))
              }
            </div> : <div style={{ paddingLeft: '20px', display: 'flex', flexDirection: 'row', width: '300px', justifyContent: "center", alignItems: "center" }}>
              <p style={{ color: 'var(--bgLight)' }}>No unstructured data uploaded</p> </div>}

            {Object.keys(csvData).length > 0 && <p className="info">Structured Data</p>}

            {
              Object.keys(csvData).length === 0 && <div style={{ display: 'flex', flexDirection: 'row', width: '300px', justifyContent: "center", alignItems: "center" }}>
                <p style={{ color: 'var(--bgLight)' }}>No structured data uploaded</p>
              </div>
            }

            {(Object.keys(csvData)?.[0]) && Object.keys(csvData)?.length > 0 && <div className="dataset-select">
              <FormControl fullWidth size="small">
                <InputLabel id="Confidentiality-label">Confidentiality</InputLabel>
                <Select
                  labelId="Confidentiality-label"
                  id="Confidentiality"
                  value={visibility}
                  label="Confidentiality"
                  onChange={handleChange}
                >
                  <MenuItem value="Public">Public</MenuItem>
                  <MenuItem value="Confidential">Confidential</MenuItem>
                  <MenuItem value="Secret">Secret</MenuItem>
                </Select>
              </FormControl>
            </div>}
            {Object.keys(csvData)?.length > 0 && <div className="dataset-select">
              <FormControl fullWidth size="small">
                <InputLabel id="datasetName-label">Select Dataset</InputLabel>
                <Select
                  labelId="datasetName-label"
                  id="datasetName"
                  value={dataset}
                  label="Select Dataset"
                  onChange={handleChangeDataset}
                >
                  {
                    data?.structured_datasets?.map((d, index) => <MenuItem key={index} id={index} value={d.fileName}>{d.fileName}</MenuItem>)

                  }

                </Select>
              </FormControl>

            </div>}
            {/* <button onClick={() => console.log(data.structured_datasets)}>Check Data</button> */}
            {Object.keys(csvData).length > 0 && <div className="delete-dataset">
              <button onClick={() => handleStructuredFileRemove(dataset)}>Delete selected dataset</button>
            </div>
            }
            <table>
              <thead>
                {csvData[dataset] && csvData[dataset].length > 0 && (

                  <>
                    <tr>

                      {(Object.keys(csvData[dataset][0]) || {})?.map((header, index) => (
                        <th key={index}>
                          <select
                            value={columnTypes[dataset][header]}
                            onChange={(e) => handleColumnTypeChange(dataset, header, e.target.value)}
                            style={{
                              borderRadius: '5px',
                              padding: '4px',
                              backgroundColor: '#726DFF',
                              border: '1px solid #ccc',
                              color: "white",
                              cursor: 'pointer'
                            }}
                          >
                            <option value="Metadata column">Metadata column</option>
                            <option value="Text column">Text column</option>
                          </select>
                          <br />
                          <select
                            defaultValue={data?.structured_datasets?.find(_d => _d?.fileName === dataset)?.dataTypes[header]}
                            onChange={(event) => handleDataTypeChange(event, header, dataset)}
                            style={{
                              borderRadius: '5px',
                              padding: '4px',
                              backgroundColor: '#726DFF',
                              border: '1px solid #ccc',
                              color: "white",
                              cursor: 'pointer',
                              marginTop: "10px"
                            }}
                          >
                            <option value={data?.structured_datasets?.find(_d => _d?.fileName === dataset)?.dataTypes[header] === "object" ? "String" : data?.structured_datasets?.find(_d => _d?.fileName === dataset)?.dataTypes[header]}>{data?.structured_datasets?.find(_d => _d?.fileName === dataset)?.dataTypes[header] === "object" ? "String" : data?.structured_datasets?.find(_d => _d?.fileName === dataset)?.dataTypes[header]}</option>
                            <option value="Integer">Integer</option>
                            <option value="Float">Float</option>
                            <option value="String">String</option>
                            <option value="Boolean">Boolean</option>
                            <option value="List">List</option>
                            <option value="Null">Null</option>
                            <option value="Undefined">Undefined</option>
                            <option value="Symbol">Symbol</option>

                          </select>

                        </th>
                      ))}
                    </tr>


                    <tr>
                      {csvData[dataset] && (Object.keys(csvData[dataset][0]) || [])?.map((header, index) => (

                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </>
                )}
              </thead>
              <tbody>
                {csvData[dataset]?.map((row, rowIndex) => (
                  rowIndex !== 0 &&
                  <tr key={rowIndex}>
                    {Object?.values(row)?.map((value, cellIndex) => (
                      <td key={cellIndex}>{
                        value.length < 100 ? value : value.substring(0, 100) + "..."
                      }</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>


          </div>
        </Dialog>


      </div>
    );
  }



  function TableNode() {
    return (
      ""
    );
  }


  function PromptNode() {
    const [data, setData] = useState(botData, setBotData);
    const [loader, set_loader] = useState(false);


    const handlePromptGeneration = async () => {
      const _localdata_ = JSON.parse(localStorage.getItem("problem_statement"))
      set_loader(true)
      // Assuming user is an object that has a nested user object with _id
      const user_id = user?.user?._id['$oid'];

      // Your data object
      const _data = { problem_statement: _localdata_, prompt_text: botData.response_length_text + botData.recency_text + botData.tone_text + botData.sources_text };

      // The API endpoint, assuming `user_id` needs to be part of the URL
      const apiEndpoint = `/api/eval_bot/generate/prompt/${user_id}`;
      const token = JSON.parse(localStorage.getItem("token"))
      // console.log(_data);

      // Making the POST request
      await axios.post(apiEndpoint, _data, {
        headers: {
          // Include the bearer token in the authorization header
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          // Handle success
          setData((prevData) => ({ ...prevData, rqa_prompt: response.data.prompt }))
          setBotData((prevData) => ({ ...prevData, rqa_prompt: response.data.prompt }));
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
        });

      set_loader(false)

    }

    const handleChangeBasePrompt = (e) => {
      setData((prevData) => ({ ...prevData, rqa_prompt: e.target.value }))
      setBotData((prevData) => ({ ...prevData, rqa_prompt: e.target.value }))
    }

    return (
      <div className="node prompt">
        <div className="top-nav-node">
          <p>Base Prompt</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("base_prompt")} />
          </div>
        </div>
        <div className="node_input">
          <CustomTextArea rows={15} title={"Base Prompt"} value={data.rqa_prompt} handleChangeInput={handleChangeBasePrompt} LabelStyles={{ color: "black" }} />
          <button onClick={handlePromptGeneration}>{loader ? "Generating prompt" : "Generate prompt"}</button>
        </div >
        <Handle
          type="target"
          position={Position.Left}
          id="b"
        />
        <Handle
          type="source"
          position={Position.Right}
          id="b"
        />
      </div >
    );
  }

  function GoldenResponseNode({ _data }) {
    const [data, setData] = useState(botData, setBotData);
    const [golden_response, set_golden_response] = useState({ question: "", answer: "" });
    const [golden_response_validation_msg, set_golden_response_validation_msg] = useState("");
    const [open_golden_response, set_open_golden_response] = useState(false);

    const handleDeleteResponse = (index) => {
      let responses = data.golden_responses;
      responses.splice(index, 1);
      setData({ ...data, golden_responses: [...responses] });
      setBotData({ ...botData, golden_responses: [...responses] })
    }



    const handleChange_question = (e) => {
      set_golden_response_validation_msg(false)
      set_golden_response({ ...golden_response, question: e.target.value })
    }

    const handleChange_answer = (e) => {
      set_golden_response_validation_msg(false)
      set_golden_response({ ...golden_response, answer: e.target.value })
    }

    function handleUploadCsv(event) {
      const file = event.target.files[0]; // Get the uploaded file

      if (!file) return; // Handle no file selected
      if (file.type !== 'text/csv') {
        alert('Please select a valid CSV file.');
        return;
      }

      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const qnaPairs = [];
          const questionHeader = 'question'; // Customize if needed
          const answerHeader = 'answer';    // Customize if needed

          for (const row of results.data) {
            if (row[questionHeader] && row[answerHeader]) { // Skip rows without both
              qnaPairs.push({
                question: row[questionHeader],
                answer: row[answerHeader]
              });
            }
          }

          setData(prevData => ({
            ...prevData,
            golden_responses: [...prevData.golden_responses, ...qnaPairs]
          }));

          setBotData(prevData => ({
            ...prevData,
            golden_responses: [...prevData.golden_responses, ...qnaPairs]
          }));
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          alert('An error occurred while parsing the CSV file.'); // Inform the user
        }
      });
    }


    return (
      <div className="node golden_reponse-node">
        <div className="top-nav-node">
          <p>Golden Responses</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("golden_responses")} />
          </div>
        </div>

        <div className='golden_responses-button'>
          <button onClick={() => set_open_golden_response(true)}>Add Golden Responses</button>
          <p>*{data.golden_responses.length} Golden Responses Added. <span onClick={() => set_open_golden_response(true)}>View all</span></p>
        </div>
        <Dialog fullWidth open={open_golden_response} onClose={() => set_open_golden_response(false)}>
          <div className='golden_responses-container'>
            <div className='golden_responses-info'>
              <h3>Input your desired QnA against which the chatbot responses will be compared.</h3>
              <p>Golden responses are essential for evaluation as they serve as benchmark answers against which chatbot-generated responses are measured to assess their performance and accuracy.</p>
            </div>
            <CustomTextArea value={golden_response.question} rows={2} title={"Question"} handleChangeInput={(event) => handleChange_question(event)} />
            <CustomTextArea value={golden_response.answer} rows={10} title={"Answer"} handleChangeInput={(event) => handleChange_answer(event)} />
            {golden_response_validation_msg && <p className='validation-message'>Question and answer should not be empty text!</p>}

            <div className='golden_responses-actions'>
              <div className='response-add'>
                <button onClick={() => {
                  if (golden_response.question !== "" && golden_response.answer !== "") {
                    //botData.golden_responses.length <= 10 && !user?.user?.subscription === "starter"
                    if (data?.golden_responses?.length < 5 && organization?.organization?.subscription === "starter") {
                      setData({ ...data, golden_responses: [...data.golden_responses, golden_response] });
                      set_golden_response({ question: "", answer: "" })
                      setBotData((prevData) => ({ ...prevData, golden_responses: [...prevData.golden_responses, golden_response] }))
                    } else if (organization?.organization?.subscription !== "starter") {
                      setData({ ...data, golden_responses: [...data.golden_responses, golden_response] });
                      setBotData((prevData) => ({ ...prevData, golden_responses: [...prevData.golden_responses, golden_response] }));
                      set_golden_response({ question: "", answer: "" })
                    } else if (botData?.keys?.open_ai || organization?.organization?.keys?.open_ai) {
                      setBotData((prevData) => ({ ...prevData, golden_responses: [...prevData.golden_responses, golden_response] }))
                      setData({ ...data, golden_responses: [...data.golden_responses, golden_response] });
                      set_golden_response({ question: "", answer: "" })
                    }
                    else {
                      setInfoHeading((prev) => (infoHeadingGolden));
                      setInfo((prev) => (infoGolden));
                      setOpenKeys((prev) => (true));
                    }
                  } else {
                    set_golden_response_validation_msg(true)
                  }
                }}>Add Response</button>
              </div>

              <div className="btn-upload-csv">
                <input type="file" accept=".csv" onChange={handleUploadCsv} />
              </div>
            </div>
            <div className='questions-container'>

              {
                data?.golden_responses?.length > 0 && data?.golden_responses?.map((response, index) => (
                  <div className='qna-display' key={index}>
                    <div className='qna-display_qna'>
                      <p><b>Question: </b> {response?.question}</p>
                      <p><b>Answer: </b> {response?.answer}</p>
                    </div>
                    <button className='btn-delete-response' onClick={() => handleDeleteResponse(index)}>
                      <DeleteIcon sx={{ color: "#666666" }} fontSize='small' />
                    </button>
                  </div>
                ))
              }

            </div>


          </div>
        </Dialog>
      </div>
    );
  }


  function ChunkSizeNode() {
    const [data, setData] = useState(botData, setBotData);



    const handleChunkChange = (event, size) => {
      const chunk_size = { ...data.parameter_key_names.chunksize };
      chunk_size[size] = event.target.checked;
      // Check if at least one checkbox is selected
      if (!Object.values(chunk_size).some(value => value)) {
        // Default to the first checkbox if none is selected
        chunk_size["300"] = true;
      }

      setData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, chunksize: chunk_size } }));
      setBotData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, chunksize: chunk_size } }));

    };

    return (
      <div className="node check-node">
        <div className="top-nav-node">
          <p>Chunk Size</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("chunk_size")} />
          </div>
        </div>
        <div className="node_input">
          <div className='check'>
            <Checkbox label="Tiny" checked={data.parameter_key_names.chunksize["300"]} onChange={(event) => handleChunkChange(event, "300")} />
            <Checkbox label="Small" checked={data.parameter_key_names.chunksize["700"]} onChange={(event) => handleChunkChange(event, "700", botData, setBotData)} />
            <Checkbox label="Medium" checked={data.parameter_key_names.chunksize["1800"]} onChange={(event) => handleChunkChange(event, "1800", botData, setBotData)} />
            <Checkbox label="Large" checked={data.parameter_key_names.chunksize["4200"]} onChange={(event) => handleChunkChange(event, "4200", botData, setBotData)} />
          </div>
        </div>
      </div>
    );
  }


  function MetricType() {
    const [data, setData] = useState(botData, setBotData);

    const handleMetricChange = (event, type) => {
      const metric_type = data.parameter_key_names.metric;
      metric_type[type] = event.target.checked;

      // Check if at least one checkbox is selected in the metric category
      const isSelected = Object.values(metric_type).some(value => value);
      if (!isSelected) {
        // If no checkbox is selected, default to the first checkbox (e.g., 'cosine')
        metric_type['cosine'] = true;
      }

      setData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, metric: { ...metric_type } } }))
      setBotData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, metric: { ...metric_type } } }));

    };
    return (
      <div className="node check-node">
        <div className="top-nav-node">
          <p>Metric Type</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("metrics_type")} />
          </div>
        </div>
        <div className="node_input">
          <div className='check'>
            <Checkbox label="Cosine" onChange={(event) => handleMetricChange(event, "cosine")} checked={data.parameter_key_names.metric.cosine} />
            <Checkbox label="Dot Product" onChange={(event) => handleMetricChange(event, "dotproduct")} checked={data.parameter_key_names.metric.dotproduct} />
            <Checkbox label="Euclidean" onChange={(event) => handleMetricChange(event, "euclidean")} checked={data.parameter_key_names.metric.euclidean} />
            <Checkbox label="Hybrid" onChange={(event) => handleMetricChange(event, "hybrid")} checked={data.parameter_key_names.metric.hybrid} />

          </div>
        </div>

      </div>
    );
  }


  function RetrievalMethod() {
    const [data, setData] = useState(botData, setBotData);


    const handleRetrievalMethodChange = (event, type) => {
      const retrieval_method = { ...data.parameter_key_names.retrieval_method };
      retrieval_method[type] = event.target.checked;

      if (!Object.values(retrieval_method).some(value => value)) {
        retrieval_method["basic"] = true; // Assuming "basic" is the first checkbox
      }

      setData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, retrieval_method: retrieval_method } }));
      setBotData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, retrieval_method: retrieval_method } }));

    };


    return (
      <div className="node check-node">
        <div className="top-nav-node">
          <p>Retrieval Method</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("retrieval_method")} />
          </div>
        </div>
        <div className="node_input">
          <div className='check'>
            <Checkbox label="Basic" onChange={(event) => handleRetrievalMethodChange(event, "basic")} checked={data.parameter_key_names.retrieval_method.basic} />
            <Checkbox label="Sentence Window" onChange={(event) => handleRetrievalMethodChange(event, "sentence-window")} checked={data.parameter_key_names.retrieval_method["sentence-window"]} />
            <Checkbox label="Paraphrasing" onChange={(event) => handleRetrievalMethodChange(event, "multi-query")} checked={data.parameter_key_names.retrieval_method["multi-query"]} />
            <Checkbox label="HyDE" onChange={(event) => handleRetrievalMethodChange(event, "hyde")} checked={data.parameter_key_names.retrieval_method["hyde"]} />
            <Checkbox label="Deconstruction" onChange={(event) => handleRetrievalMethodChange(event, "complex-to-simple-query")} checked={data.parameter_key_names.retrieval_method["complex-to-simple-query"]} />
          </div>
        </div>

      </div>
    );
  }


  function MetadataFiltering() {

    const [data, setData] = useState(botData, setBotData);

    const handleMetadataFilteringChange = (event, type) => {
      const metadata_filtering = { ...data.parameter_key_names.metadata_filtering };
      metadata_filtering[type] = event.target.checked;

      if (!Object.values(metadata_filtering).some(value => value)) {
        metadata_filtering["add-metadata"] = true; // Assuming "add-metadata" is the first checkbox
      }

      setData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, metadata_filtering: metadata_filtering } }));
      setBotData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, metadata_filtering: metadata_filtering } }));
    };


    return (
      <div className="node check-node">
        <div className="top-nav-node">
          <p>Metadata Filtering</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("metadata_filtering")} />
          </div>
        </div>
        <div className="node_input">
          <div className='check'>
            <Checkbox label="Add Metadata" onChange={(event) => handleMetadataFilteringChange(event, "add-metadata")} checked={data.parameter_key_names.metadata_filtering['add-metadata']} />
            <Checkbox label="No Metadata" onChange={(event) => handleMetadataFilteringChange(event, "no-metadata")} checked={data.parameter_key_names.metadata_filtering['no-metadata']} />
          </div>
        </div>

      </div>
    );
  }


  function RerankMethod() {
    const [data, setData] = useState(botData, setBotData);

    const handlePostRetrievalChange = (event, type) => {
      const rerank_method = { ...data.parameter_key_names.rerank_method };
      rerank_method[type] = event.target.checked;

      if (!Object.values(rerank_method).some(value => value)) {
        rerank_method["none"] = true; // Assuming "none" is the first checkbox
      }

      setData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, rerank_method: rerank_method } }));
      setBotData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, rerank_method: rerank_method } }));
    };


    return (
      <div className="node check-node">
        <div className="top-nav-node">
          <p>Rerank Method</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("post_retrieval")} />
          </div>
        </div>
        <div className="node_input">
          <div className='check'>
            <Checkbox label="None" onChange={(event) => handlePostRetrievalChange(event, "none")} checked={data.parameter_key_names.rerank_method["none"]} />
            <Checkbox label="Maximal Marginal Relevence" onChange={(event) => handlePostRetrievalChange(event, "mmr")} checked={data.parameter_key_names.rerank_method.mmr} />
            <Checkbox label="Cohere" onChange={(event) => handlePostRetrievalChange(event, "cohere")} checked={data.parameter_key_names.rerank_method["cohere"]} />
            <Checkbox label="LLM Rerank" onChange={(event) => handlePostRetrievalChange(event, "llm_rerank")} checked={data.parameter_key_names.rerank_method["llm_rerank"]} />
          </div>
        </div>

      </div>
    );
  }


  function TopK() {
    const [data, setData] = useState(botData, setBotData);

    const handleTopKChange = (event, type) => {
      const top_k = { ...data.parameter_key_names.top_k };
      top_k[type] = event.target.checked;

      if (!Object.values(top_k).some(value => value)) {
        top_k["1"] = true; // Assuming "1" is the first checkbox
      }

      setData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, top_k: top_k } }));
      setBotData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, top_k: top_k } }));

    };
    return (
      <div className="node check-node">
        <div className="top-nav-node">
          <p>Top K</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("top_k")} />
          </div>
        </div>
        <div className="node_input">
          <div className='check'>
            <Checkbox label="1" onChange={(event) => handleTopKChange(event, "1")} checked={data.parameter_key_names.top_k["1"]} />
            <Checkbox label="5" onChange={(event) => handleTopKChange(event, "5")} checked={data.parameter_key_names.top_k["5"]} />
            <Checkbox label="10" onChange={(event) => handleTopKChange(event, "10")} checked={data.parameter_key_names.top_k["10"]} />
            <Checkbox label="15" onChange={(event) => handleTopKChange(event, "15")} checked={data.parameter_key_names.top_k["15"]} />
            <Checkbox label="20" onChange={(event) => handleTopKChange(event, "20")} checked={data.parameter_key_names.top_k["20"]} />
          </div>
        </div>

      </div>
    );
  }


  function LLM() {

    const [data, setData] = useState(botData, setBotData);

    const handleLLMChange = (event, type) => {
      const checkOpen_ai = !botData?.keys?.open_ai;
      if (organization?.organization?.subscription === "starter" && checkOpen_ai) {
        setInfoHeading((prev) => (infoHeadingLLM));
        setInfo((prev) => (infoLLM));
        setOpenKeys((prev) => (true));
        return;
      }
      const llm = { ...data.parameter_key_names.llm };
      llm[type] = event.target.checked;

      if (!Object.values(llm).some(value => value)) {
        llm["gpt-3.5-turbo-1106"] = true; // Assuming "gpt-3.5-turbo-1106" is the first checkbox
      }

      setData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, llm: llm } }));
      setBotData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, llm: llm } }));
    };


    return (
      <div className="node check-node">
        <div className="top-nav-node">
          <p>LLM</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("llm")} />
          </div>
        </div>
        <div className="node_input">
          <div className='check'>
            <Checkbox label="gpt-3.5-turbo-0125" onChange={(event) => handleLLMChange(event, "gpt-3.5-turbo-0125")} checked={data.parameter_key_names.llm["gpt-3.5-turbo-0125"]} />
            <Checkbox label="gpt-4-turbo" onChange={(event) => handleLLMChange(event, "gpt-4-turbo-2024-04-09")} checked={data.parameter_key_names.llm["gpt-4-turbo-2024-04-09"]} />
            {/* <Checkbox label="LLama 2" onChange={(event) => handleLLMChange(event, "llama_2")} checked={data.parameter_key_names.llm["llama_2"]} disabled={true} /> */}
            <Checkbox label="LLama 3" onChange={(event) => handleLLMChange(event, "llama3")} checked={data.parameter_key_names.llm["llama3"]} />

          </div>
        </div>

      </div>
    );
  }


  function EmbeddingModel() {

    const [data, setData] = useState(botData, setBotData);

    const handleEmbeddingChange = (event, type) => {
      const embedding_model = { ...data.parameter_key_names.embedding_model };
      embedding_model[type] = event.target.checked;

      if (!Object.values(embedding_model).some(value => value)) {
        embedding_model["text-embedding-ada-002"] = true; // Assuming "text-embedding-ada-002" is the first checkbox
      }

      setData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, embedding_model: embedding_model } }));
      setBotData((prevData) => ({ ...prevData, parameter_key_names: { ...prevData.parameter_key_names, embedding_model: embedding_model } }));
    };


    return (
      <div className="node check-node">
        <div className="top-nav-node">
          <p>Embedding Model</p>
          <div>
            <InfoOutlined onClick={() => handleOpenSideBar("embedding_model")} />
          </div>
        </div>
        <div className="node_input">
          <div className='check'>

            <Checkbox label="text-embedding-ada-002" onChange={(event) => handleEmbeddingChange(event, "text-embedding-ada-002")} checked={data.parameter_key_names.embedding_model["text-embedding-ada-002"]} />
            <Checkbox label="Fine tuned model" onChange={(event) => handleEmbeddingChange(event, "fine-tuned")} checked={data.parameter_key_names.embedding_model["fine-tuned"]} disabled={organization?.organization?.subscription === "starter"} />

          </div>
        </div>

      </div>
    );
  }

  function Queryloop() {


    return (
      <div className="node check-node queryloop">
        <div className="node_input">
          <h3>Hyper-Parameter Tunning</h3>
        </div>
        <Handle type="target" position={Position.Right} id="a" />

      </div>
    );
  }

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const nodeType = event.dataTransfer.getData("application/reactflow");
    const position = {
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    };
    const newNode = {
      id: Date.now().toString(),
      type: nodeType,
      position,
      dragging: true,
      data: { label: `${nodeType}` },
    };

    setElements((es) => es.concat(newNode));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };


  if (chatbot.isLoading === true) {
    return <Loader />
  }


  return (
    <div style={{ width: "100%" }}>
      {open_sidebar && <SideBar type={type} open_sidebar={open_sidebar} set_open_sidebar={set_open_sidebar} />}
      <Snackbar open={openFileAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} sx={{ width: '100%', bgcolor: colors.hover }}>
          File added successfull!
        </Alert>
      </Snackbar>

      {/* *************************** Bot Setup Name and Type ********************* */}

      <div style={{ width: '100%', height: '680px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="container">
        {/* <Paper variant="outlined" sx={{ width: "25%", height: '100%' }}>
          <MenuList>
            {
              Object.keys(nodeTypes).map((nodeType) => {
                return <MenuItem key={nodeType} onDragStart={(event) => onDragStart(event, nodeType)}>
                  <ListItemText sx={{ width: "200px", justifyContent: "space-between", display: "flex", flexDirection: "row" }}>{nodeTypes[nodeType].label}</ListItemText>
                </MenuItem>
              })
            }
          </MenuList>
          <button onClick={() => console.log(botData)}>Check</button>

        </Paper> */}
        {/* <button onClick={() => console.log("Bot Data: ", botData)}>Check</button> */}

        <AddKeys openKeys={openKeys} setOpenKeys={setOpenKeys} botData={botData} setBotData={setBotData} info={info} infoHeading={infoHeading} fileAlert={infoHeading.fileAlert} />
        <div ref={reactFlowWrapper}
          className="reactflow-wrapper"
          onDragOver={onDragOver}
          style={{ width: '98%', height: '100%' }}>

          <ReactFlow
            nodes={elements}
            edges={edges}
            nodeTypes={nodeTypesList}
            onDrop={onDrop}
            onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView
            defaultEdgeOptions={{ zIndex: 100, }}
            proOptions={{ hideAttribution: true }}
          >
            <Controls />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>

          <div className="create-node">
            <div className="create-node_input">
              <button onClick={handleOpenName}>Create</button>
              <Dialog fullWidth open={openName} onClose={() => setOpenName(false)}>
                <DialogTitle>Enter Application Name</DialogTitle>
                <div className="enter-name">
                  <input placeholder="Enter application name" value={botData.name} onChange={handleChangeInput} />
                  <button onClick={handleCreateBot}>Create Application</button>
                </div>
              </Dialog>
            </div>
          </div>
        </div>


      </div>

    </div>
  );
};

export default BotType;

