import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import "./UserManagement.css"
import colors from '../../../Constants/colors';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { addNewUser, adduser, getAllUsers, get_organization_by_user } from '../../../features/organization/orgSlice';

const UserManagement = () => {

    const organization = useSelector(state => state.organization);
    const { user } = useSelector(state => state.auth);
    const [openDialog, setOpenDialog] = useState(false);
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const get_users = async () => {
            const data = organization.organization.users.map(usr => usr['$oid']);
            if (data.length > 0) {
                const response = await dispatch(getAllUsers(data));
                console.log(response)
            }
        }


        get_users();
    }, [])

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleAddUser = async () => {
        const response = await dispatch(addNewUser({ ...newUser, organization: organization.organization._id["$oid"], privilege: "member" }));
        console.log(response.payload.status);
        if (response.payload.status === "User Successfuly Created") {
            await dispatch(get_organization_by_user(organization.organization.owner["$oid"]));
            setNewUser({ first_name: '', last_name: '', email: '', password: '' }); // Reset the form

            const data = await organization.organization.users.map(usr => usr['$oid']);
            if (data.length > 0) {
                const response = await dispatch(getAllUsers(data));
                console.log(response);
            }
            handleClose();
        } else {
            alert("Error occured during adding of user, please try again.")
        }
    };

    const handleDeleteUser = (index) => {
        const updatedUsers = [...users];
        updatedUsers.splice(index, 1);
        setUsers(updatedUsers);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const handleUploadCSV = (e) => {
        const file = e.target.files[0];
        Papa.parse(file, {
            complete: (result) => {
                const parsedUsers = result.data.map(row => ({
                    first_name: row['First Name'],
                    last_name: row['Last Name'],
                    email: row['Email'],
                    password: row['Password']
                })).filter(user => user.email && user.password); // Filter out rows without email or password
                setUsers([...users, ...parsedUsers]);
            },
            header: true
        });
    };

    return (
        <div className='usermanagement-container'>
            <div className='usermanagement'>
                <Button
                    variant="contained"
                    onClick={handleOpen}
                    style={{ backgroundColor: 'var(--buttonBackground)', color: 'white' }}
                >
                    Add User
                </Button>
                <Button variant="contained" component="label" style={{ backgroundColor: 'var(--buttonBackground)', color: 'white', marginLeft: "10px", marginBottom: '10px', width: "150px" }}>
                    Upload CSV
                    <input type="file" hidden onChange={handleUploadCSV} accept=".csv" />
                </Button>

                <Dialog open={openDialog} onClose={handleClose} >
                    <div style={{ backgroundColor: colors.bgDark, border: "1px solid var(--buttonBackground)" }}>

                        <DialogTitle sx={{ color: "white" }}>Add a new user</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="first_name"
                                label="First Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={newUser.first_name}
                                onChange={handleChange}

                                sx={{ backgroundColor: "white" }}
                            />
                            <TextField
                                margin="dense"
                                name="last_name"
                                label="Last Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={newUser.last_name}
                                onChange={handleChange}
                                sx={{ backgroundColor: "white" }}
                            />
                            <TextField
                                margin="dense"
                                name="email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant="outlined"
                                value={newUser.email}
                                onChange={handleChange}
                                sx={{ backgroundColor: "white" }}
                            />
                            <TextField
                                margin="dense"
                                name="password"
                                label="Password"
                                type="password"
                                fullWidth
                                variant="outlined"
                                value={newUser.password}
                                onChange={handleChange}
                                sx={{ backgroundColor: "white" }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                style={{
                                    backgroundColor: 'white',
                                    border: '1px solid #e0e0e0', // light grey border
                                    color: 'black'
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleAddUser}
                                style={{
                                    backgroundColor: 'var(--buttonBackground)',
                                    color: 'white'
                                }}
                            >
                                Add User
                            </Button>

                        </DialogActions>
                    </div>
                </Dialog>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: 'white' }} align='left'>Index</TableCell>
                            <TableCell style={{ color: 'white' }} align='left'>Name</TableCell>
                            <TableCell style={{ color: 'white' }} align='left'>Email</TableCell>
                            <TableCell style={{ color: 'white' }} align='left'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organization?.users?.map((_user, index) => {
                            return user?.user?.email !== _user?.email && <TableRow key={index} >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{`${_user["first_name"]} ${_user["last_name"]}`}</TableCell>
                                <TableCell align="left">{_user["email"]}</TableCell>
                                <TableCell align="left">
                                    <IconButton
                                        onClick={() => handleDeleteUser(index)}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'transparent', // Keeps the background transparent on hover
                                            }
                                        }}
                                    >
                                        <DeleteIcon color="black" size="small" />
                                    </IconButton>

                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </div>
        </div >
    );
}

export default UserManagement