import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login, register } from '../../../features/auth/authSlice'
import { useLocation, useNavigate } from 'react-router'
import CustomInput from '../../../Components/Input/CustomInput'
import "./signup.css"
import Loader from '../../../Components/Loader'
import { registerOrg } from '../../../features/organization/orgSlice'


const Signup = () => {

  const user = useSelector(state => state.auth)
  const { organization } = useSelector(state => state.organization)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()

  const [validationText, setValidationText] = useState("")
  const [loading, setLoading] = useState(false)

  const [userData, setUserData] = useState({ first_name: "", last_name: "", email: "", password: "", organization: organization?._id["$oid"] })
  const { first_name, last_name, email, password } = userData

  const handleRegister = async () => {
    if (userData.first_name === "") {
      setValidationText("Please enter first name. Example: 'John'");
      return;
    }

    if (userData.last_name === "") {
      setValidationText("Please enter last name. Example: 'Doe'");
      return;
    }

    // Email validation
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      setValidationText("Please enter a valid email address. 'example@domain.com'")
      return;
    }

    // Password validation
    const passwordRegex = /^(?=.*\d)[A-Za-z\d\/\-\*\@\!\#\_]{8,}$/;
    if (!passwordRegex.test(password)) {
      setValidationText("Password must be at least 8 characters long and include at least one number.");
      return;
    }

    setLoading(true);
    const response_org = await dispatch(registerOrg(state.orgName))
    if (response_org.error && response_org.error.message === 'Rejected') {
      alert("Error occurred while creating Organization!\nPlease try again.")
      setLoading(false)

    } else {
      const response = await dispatch(register({ ...userData, organization: response_org.payload._id["$oid"] }));
      console.log("response:", response)
      if (response.error && response.error.message === 'Rejected') {
        setValidationText("User already exists with this email. Please use a different email.");
      }

      else {
        await dispatch(login({ email, password }));
        setLoading(false);
        navigate('/dashboard/create-bot', { state: { redirected: true } })

      }
    }


  };



  const handleChange = (event) => {

    if (event.target.name === "first_name" || event.target.name === "last_name") {
      setValidationText('');
    }

    if (event.target.name === "email" || event.target.name === "password") {
      setValidationText('');
    }

    setUserData((inputTexts) => ({
      ...inputTexts,
      [event.target.name]: event.target.value,
    }));
  }

  const handleGotoLogin = () => {
    navigate('/auth/login')
  }

  if (user.isLoading === true) {
    return <Loader />
  }

  return (
    <div className="signup-container">
      <div className="signup">
        <div className="signup-heading"><p>Create an Account</p></div>
        <CustomInput multiple={false} className={'customColor'} placeholder={"Enter Your First Name"} type={"text"} value={first_name} name={"first_name"} onHandleChange={handleChange} />
        <CustomInput multiple={false} className={'customColor'} placeholder={"Enter Your Last Name"} type={"text"} value={last_name} name={"last_name"} onHandleChange={handleChange} />
        <CustomInput multiple={false} className={'customColor'} placeholder={"Enter Your Email"} type={"email"} value={email} name={"email"} onHandleChange={handleChange} />
        <CustomInput multiple={false} className={'customColor'} placeholder={"Enter Password"} type={"password"} value={password} name={"password"} onHandleChange={handleChange} />
        <p className='validation-text'>{validationText}</p>
        <div className="btn btn-signup">
          <button style={{ backgroundColor: "var(--buttonBackground)", color: "white", width: "150px" }} onClick={handleRegister}>Sign up</button>
          <p className="not-a-user">Already a user? <span onClick={handleGotoLogin} className="create-account">Go to Login</span></p>
        </div>
      </div>
    </div>

  )
}

export default Signup