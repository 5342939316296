import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import chatbotReducer from "../features/chatbots/chatbotSlice"
import organizationReducer from '../features/organization/orgSlice'
import { composeWithDevTools } from "@redux-devtools/extension";
export const store = configureStore(
    {
        reducer: {
            auth: authReducer,
            chatbot: chatbotReducer,
            organization: organizationReducer,
        },
    },
    composeWithDevTools()
);
