import React from 'react'
import "./custominput.css"
const CustomInput = ({ width, placeholder, label, className, onHandleChange, value, type, additionalInfo, name, multiple, disable, upload_type }) => {
  const inputId = `file-input-${upload_type}`;
  return (
    <div className={`input ${className}`}>
      <div>
        <p>
          {label}
          <span className='additionalinfo'>{additionalInfo}</span>
        </p>
      </div>
      {
        multiple === false ?
          <div className='input-container'>
            <input style={{ width: width }} placeholder={placeholder} name={name} onChange={onHandleChange} value={value} type={type} disabled={disable ? disable : false} />
          </div>
          :
          <div className='file-input-container'>
            <label htmlFor={inputId} className="custom-file-input-label">
              {upload_type === "structured" ? "Upload CSV/Excel files" : "Upload PDF/Text/Docs files"}
            </label>
            {upload_type === "structured" && <input multiple={true} className='file-selector'
              id="file-input-structured" style={{ width: width }} name={name} onChange={onHandleChange} type={type} accept={`${upload_type === "structured" && 'text/csv'}`} />}
            {upload_type === "unstructured" && <input multiple={true} className='file-selector'
              id="file-input-unstructured" style={{ width: width }} name={name} onChange={onHandleChange} type={type} accept={`${upload_type === "unstructured" && 'application/msword, text/plain, application/pdf'}`} />}
            {upload_type === "structured" ?
              <p> *Supported file types: CSV/XLXS (Excel format)</p>
              :
              <p> *Supported file types: PDF and DOCX</p>
            }
          </div>
      }
    </div>
  )
}

export default CustomInput