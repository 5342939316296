import React, { useEffect, useRef, useState } from 'react'
import DetailsView from '../../../../Components/DetailsView/DetailsView'
import NavigationButtons from '../../../../Components/NavigationButtons/NavigationButtons'
import { useDispatch, useSelector } from 'react-redux'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { deleteChatbot, generatePrompts, getBotbyCreator, initializeCombinations, initializeMutations, resetChatbot, runEvals } from '../../../../features/chatbots/chatbotSlice'
import { getUser } from '../../../../features/auth/authSlice'
import axios from 'axios'
import { Snackbar } from '@mui/material';



const PromptGrid = ({ combinations, setCombinations, reload, bot_id, reloadUser, user_id, selected_combinations, set_selected_combinations, setTabName, setValue }) => {

    const chatbot = useSelector(state => state.chatbot);
    const organization = useSelector(state => state.organization);
    const { user } = useSelector(state => state.auth);
    const [open_details, set_open_details] = useState(false);
    const dispatch = useDispatch();
    const [metrics, set_metrics] = useState();
    const [cost, setCost] = useState(0.00);
    const [enableRun, set_enableRun] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading_message, setLoading_message] = useState("");
    const [sortOrder, setSortOrder] = useState('asc');
    const [openChat, setOpenChat] = useState(false);
    const [openChatDefault, setOpenChatDefault] = useState(false);
    const [defaultHistory, setDefaultHistory] = useState([]);
    const [defaultHuman, setDefaultHuman] = useState([]);
    const textareaRef = useRef(null);

    const [selectAll, setSelectAll] = useState(true);

    useEffect(() => {
        // Check if all combinations are selected to update the selectAll state
        const areAllSelected = combinations.length && combinations.every(c => c.checked);
        setSelectAll(areAllSelected);
    }, [combinations]);


    useEffect(() => {
        setLoading_message("Getting combinations please wait.")
        setLoading(true)
        if (combinations.length === 0 && (chatbot?.combinations[0]?.combination_set["$oid"] === chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?._id["$oid"])) {
            // Clearing previous combinations
            setCombinations(chatbot?.combinations?.length > 0 ? [...chatbot?.combinations] : []);
        }

        if (selected_combinations.length === 0 && (chatbot?.combinations[0]?.combination_set["$oid"] === chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?._id["$oid"])) {
            // Setting selected combinations if they exist
            set_selected_combinations(chatbot?.selected_combinations);
        }
        setLoading(false)
        setLoading_message("")

    }, [chatbot?.selected_combinations]);

    // Effect for handling combinations initialization
    useEffect(() => {
        setLoading_message("Getting combinations please wait.")
        setLoading(true)
        if (combinations?.length === 0 && (!chatbot?.gettingCombinations || !chatbot?.isLoading) && organization?.organization?.bot?.length > 0) {
            handleReload();
        } else if (chatbot?.combinations[0]?.combination_set["$oid"] === chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?._id["$oid"]) {
            const combinationsWithChecked = combinations?.map(combination => ({
                ...combination,
                checked: true,
            }));
            setCombinations(combinationsWithChecked);
            set_selected_combinations(combinationsWithChecked)
        }
        setLoading(false)
        setLoading_message("")
    }, []);


    const adjustTextareaRows = () => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = "auto"; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content
        }
    };

    const handleOpenDetails = (index) => {
        set_metrics(combinations[index])
        set_open_details(true)
    }

    const handleReload = async () => {
        await setLoading_message("Getting combinations please wait.");
        await setLoading(true);
        await reload();
        await setLoading(false);
        await setLoading_message("");
    }

    const handleCloseDetails = () => {
        set_open_details(false)
    }

    const handleSortByAccuracy = () => {
        const sortedCombinations = [...combinations].sort((a, b) => {
            // Assuming that accuracy is a number and directly comparable
            const accuracyA = a.generated_responses[0]?.rating || 0;
            const accuracyB = b.generated_responses[0]?.rating || 0;

            if (sortOrder === 'asc') {
                return accuracyA - accuracyB;
            } else {
                return accuracyB - accuracyA;
            }
        });

        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle the sort order for the next click
        setCombinations(sortedCombinations);
    };


    const handleDeleteBot = async () => {
        setLoading(true)
        setLoading_message("Deleting Combination Set please wait...")
        const response = await dispatch(deleteChatbot(bot_id))
        dispatch(resetChatbot());
        await dispatch(getUser(user_id))
        await handleReload();

        setLoading_message('')
        setLoading(false)
        set_selected_combinations([])
        setCombinations([])
    }

    const handleChat = async () => {
        if (defaultHuman.length > 0) {
            setDefaultHistory([...defaultHistory, { human: defaultHuman, ai: "Generating ..." }])
            const token = JSON.parse(localStorage.getItem("token"))
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    // Other headers if needed
                },
            };
            const orgId = organization.organization._id["$oid"];
            axios
                .post(
                    `/api/eval_bot/chatdefault/${orgId}/${user_id}/${bot_id}`,
                    { query: defaultHuman },
                    config
                )
                .then((response) => {
                    setDefaultHistory([...defaultHistory, { ai: response.data.answer, human: defaultHuman }]);
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            alert("Please type any query!")
        }
    }


    const handleCheckboxChange = (e, combination, index) => {
        const checked = e.target.checked;

        // Update combinations
        const updatedCombinations = combinations.map((item, idx) => {
            if (idx === index) {
                return { ...item, checked };
            }
            return item;
        });
        setCombinations(updatedCombinations);

        // Update selected_combinations
        if (checked) {
            set_selected_combinations(prev => [...prev, combination]);
        } else {
            set_selected_combinations(prev => prev.filter(item => item._id['$oid'] !== combination._id['$oid']));
        }
    };


    const handleSelectAllCombinations = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);

        // Update all combinations
        const updatedCombinations = combinations.map(combination => ({
            ...combination,
            checked
        }));
        setCombinations(updatedCombinations);

        // Update selected_combinations
        if (checked) {
            set_selected_combinations(updatedCombinations);
        } else {
            set_selected_combinations([]);
        }

    };

    const handleReset = () => {
        setValue("4");
    }

    const isAnyCombinationCompleted = () => {
        return chatbot?.combinations?.some(combination => combination?.completed === true);
    };


    return (
        <div className='prompt-reader-container'>
            <DetailsView open={open_details} handleClose={handleCloseDetails} metrics={metrics} openChat={openChat} setOpenChat={setOpenChat} bot_id={bot_id} user_id={user_id} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message="Please select atleast one combination to proceed."
            />
            <div className='postgrid-info'>
                {
                    <p>Select the top few combinations on which you would like to optimize the prompt.</p>
                }
            </div>
            <div className='postgrid'>

                <div className='grid-btns'>
                    <div className='run-btns'>
                    </div>
                    {
                        /*  chatbot?.chatbots?.combinations[0]?.default_combination && 
                         <button className='chat-default' onClick={() => setOpenChatDefault(true)}>Run deployed version</button> */
                    }
                    {chatbot?.selected_combinations?.length > 0 && <button onClick={handleReset} className='reset-btn'>Reset to previous selections</button>}
                    <button className='refresh-btn' onClick={handleReload}>Refresh</button>
                </div>

                <div className="scrollable-sticky-head">
                    <div className="scrollable-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th align="left">
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={(e) => handleSelectAllCombinations(e)}
                                        />
                                    </th>
                                    <th align="left">#</th>
                                    <th align="left">Chunk Size</th>
                                    <th align="left">Metric Type</th>
                                    <th align="left">Retrieval Method</th>
                                    <th align="left">Metadata Filtering</th>
                                    <th align="left">Post Retrieval</th>
                                    <th align="left">Top K</th>
                                    <th align="left">LLM</th>
                                    <th align="left">Embedding Model</th>
                                    <th align="left" style={{ cursor: "pointer" }} onClick={handleSortByAccuracy}>
                                        Accuracy {sortOrder === 'asc' ? '↑' : '↓'}
                                    </th>
                                    <th align="left"></th>
                                </tr>
                            </thead>
                        </table>


                        <table>
                            <tbody>
                                {combinations.length > 0 && combinations?.map((combination, index) => {
                                    const isChecked = selected_combinations.some(selected => selected._id['$oid'] === combination._id['$oid']) || combination.checked;

                                    return <tr key={index}>

                                        <td align="right" ><input type='checkbox' value={isChecked} checked={isChecked}
                                            onChange={(e) => handleCheckboxChange(e, combination, index)} />
                                        </td>

                                        <td align="right" >{index}</td>
                                        <td align="right" >{combination.chunksize}</td>
                                        <td align="right" >{combination.metric}</td>
                                        <td align="right" >{combination.retrieval_method === 'complex-to-simple-query' ? 'deconstruction' : combination.retrieval_method}</td>
                                        <td align="right" >{`${combination.metadata_filtering}`}</td>
                                        <td align="right" >{combination.rerank_method}</td>
                                        <td align="right" >{combination.top_k}</td>
                                        <td align="right" >{combination.llm}</td>
                                        <td align="right" >{combination.embedding_model}</td>
                                        <td align="right" >{combination?.average_rating}</td>
                                        <td align="right" >
                                            {combination?.completed ? <div className='table-actions'>
                                                <button className='btn-view-grid-details btn-view' onClick={() => handleOpenDetails(index)}>View</button>
                                                {
                                                    // <button className='btn-view-grid-details' onClick={() => {}}>Run</button>
                                                }
                                            </div>
                                                :
                                                user?.user?.subscription === "starter" && isAnyCombinationCompleted &&
                                                <div className='table-actions-subscribe'>
                                                    <button className='btn-view-grid-details btn-subscribe-to-view' onClick={() => {
                                                        localStorage.setItem("valueIndex", "2");
                                                        setTabName("settingsPage")
                                                    }}>Subscribe to run more</button>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                })
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="navs">
                    <div>
                        <button className={`next`} onClick={() => {
                            if (selected_combinations.length > 0) {
                                setValue("2")
                            } else {
                                setSnackbarOpen(true)
                            }
                        }} >Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PromptGrid