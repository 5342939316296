import React, { useEffect, useRef, useState } from 'react'
import userIcon from "../../Assets/user.png"
import { Chip, Dialog } from '@mui/material'
import "./DetailsView.css"
import queryloopIcon from "../../Assets/queryloop.png"
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { makeDefault } from '../../features/chatbots/chatbotSlice'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

const DetailsView = ({ metrics, open, handleClose, openChat, setOpenChat, bot_id, user_id, orgId, open_sidebar, set_open_sidebar, clearHistory }) => {

    const { user } = useSelector(state => state.auth);
    const organization = useSelector(state => state.organization);
    const testOpen = (openChat === true || openChat === false) ? true : false
    const [history, setHistory] = useState([])
    const [human, setHuman] = useState('')
    const textareaRef = useRef(null);
    const dispatch = useDispatch();
    const [clear_history, set_clear_history] = useState(clearHistory);

    useEffect(() => {
        if (clear_history === true) {
            setHistory([]);
            set_clear_history(clearHistory)
        }
    }, [clear_history, clearHistory])

    const adjustTextareaRows = () => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = "auto"; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content
        }
    };

    const handleChat = async () => {

        if (organization?.organization?.subscription === "starter") {
            setHistory([...history, { human: human, ai: "Oops! I cannot answer your query if you are on the Trial Version. Go to Settings to update your package." }])
            return;
        }

        if (human.length > 0) {
            setHistory([...history, { human: human, ai: "Generating ..." }])
            const token = JSON.parse(localStorage.getItem("token"))
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    // Other headers if needed
                },
            };
            axios
                .post(
                    `/api/eval_bot/chatcombination/${orgId}/${user_id}/${bot_id}/${metrics._id['$oid']}`,
                    { query: human },
                    config
                )
                .then((response) => {
                    setHistory([...history, { ai: response.data.answer, human: human }]);
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            alert("Please type any query!")
        }
    }

    const handlekeydown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevents the default behavior of the Enter key
            setHistory([...history, { ai: "thinking... ", human: human }]);
            setHuman("")
            handleChat()

        }
    };

    const handleMakeDefault = async () => {
        const data = { bot_id: bot_id, combination_id: metrics._id["$oid"] };
        const response = await dispatch(makeDefault(data));
        if (response.payload?.combinations !== null) {
            alert("Combination is deployed Successfully!")
        } else {
            alert("An error has occured, while deploying this combination!")
        }
    }


    return (
        <Dialog fullWidth maxWidth={"lg"} open={open} onClose={handleClose}>
            <Dialog fullScreen open={openChat} onClose={() => setOpenChat(false)}>
                <div className='chat-container'>
                    <div className='action-back'>
                        <button onClick={() => setOpenChat(false)}>X</button>
                    </div>

                    <div className='chat-point'>
                        {history.map((msg, index) => (
                            <div key={index}>
                                <div className="human-message">
                                    <img src={userIcon} alt="user" />
                                    <p>{msg.human}</p>
                                </div>
                                <div className="ai-message">
                                    <img src={queryloopIcon} alt="queryloop" />
                                    <p>{msg.ai}</p>
                                </div>
                            </div>
                        ))}
                        <div>

                            <textarea
                                ref={textareaRef}
                                value={human}
                                onChange={(e) => {
                                    setHuman(e.target.value);
                                    adjustTextareaRows();
                                }}
                                autoFocus={true}
                                onKeyDown={handlekeydown}
                                placeholder="Start typing"
                                className="user-input-area"

                            ></textarea>
                        </div>
                    </div>
                    <div></div>
                </div>
            </Dialog>
            <div className='details'>
                <div className='chips-container'>
                    <Chip label={`Chunk Size: ${metrics?.chunksize}`} />
                    <Chip label={`Metric Type: ${metrics?.metric}`} />
                    <Chip label={`Retrieval Method: ${metrics?.retrieval_method}`} />
                    <Chip label={`Metadata Filetering: ${metrics?.metadata_filtering}`} />
                    <Chip label={`Post Retrieval: ${metrics?.rerank_method}`} />
                    <Chip label={`Top K: ${metrics?.top_k}`} />
                    <Chip label={`LLM: ${metrics?.llm}`} />
                    <Chip label={`Embedding Model: ${metrics?.embedding_model}`} />
                </div>

                {testOpen &&
                    <div className="start-chat">
                        <button className="test-chat" onClick={() => setOpenChat(true)}>Run other questions</button>
                        {/*   <button className="make-default" onClick={handleMakeDefault}>Deploy this LLM</button> */}
                    </div>
                }

                <div className='details_qna'>
                    {metrics?.prompt && <p className='prompt-view'><b>Prompt - </b> {metrics.prompt}</p>}
                    {metrics?.generated_responses?.map((qa, index) => (
                        <div key={index} className='query-response'>

                            <div className="queryloop-response">
                                <img className='user-icon' src={userIcon} alt='usericon' width={20} height={20} style={{ marginRight: "10px" }} />
                                <p>{qa.question}</p>
                            </div>

                            <div className="queryloop-response">
                                <div className="queryloop-logo-container">

                                    <div className="queryloop-logo" />
                                </div>
                                <p>{qa.generated_answer}</p>
                            </div>
                            <p> <b>Golden Response - </b> {qa.answer}</p>
                            <div className='select-info'>

                                <button style={{ backgroundColor: "var(--colorPrimary)", border: "none", cursor: "pointer", color: "var(--bgLight)", fontWeight: "700", fontSize: "1.2rem", padding: "0px" }} onClick={() => set_open_sidebar(true)}>Evaluation <InfoOutlined fontSize='small' /></button>
                                <div className="ratings-container">
                                    <p className="ratings">Overall Accuracy: {qa?.rating}</p>
                                    <h5 className="info-min_max ratings-value">(max value 10)</h5>
                                </div>

                                <p><b>Feedback:</b> {qa?.feedback}</p>

                                <p><b>Retrieval Score:</b> {qa?.retrieval_result}</p>
                                <h5 className="info-min_max">(max value 1)</h5>

                                <p><b>Correctness Score:</b> {qa?.correctness_result}</p>
                                <h5 className="info-min_max">(max value 2)</h5>

                                <p><b>Prompt Adherenece:</b> {qa?.prompt_adherence}</p>
                                <h5 className="info-min_max">(max value 1)</h5>

                                <p><b>Response Length:</b> {qa?.response_length}</p>
                                <h5 className="info-min_max">(max value 1)</h5>

                                <p><b>Faithfulness:</b> {qa?.faithfulness}</p>
                                <h5 className="info-min_max">(max value 1)</h5>


                                <p></p>
                            </div>

                            <div className='select-info'>
                                <div className="ratings-container">
                                    <p className="ratings">Context provided to LLM</p>
                                </div>
                                <div className='selec-info_scroll'>
                                    <p>
                                        {qa?.documents}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        </Dialog>
    )
}


export default DetailsView