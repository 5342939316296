import { Dialog } from '@mui/material';
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import userIcon from "../../../../Assets/user.png"
import queryloopIcon from "../../../../Assets/queryloop.png"
import { makeDefault, runEvals } from '../../../../features/chatbots/chatbotSlice';
import axios from 'axios';

const TestDeploy = ({ setCombinations, reload, bot_id, reloadUser, user_id, selected_combinations, set_selected_combinations, setTabName }) => {
    const chatbot = useSelector(state => state.chatbot);
    const organization = useSelector(state => state.organization);
    const { user } = useSelector(state => state.auth);

    const dispatch = useDispatch()

    const [sortOrder, setSortOrder] = useState('asc');


    const [metrics, set_metrics] = useState();
    const [openChatDefault, setOpenChatDefault] = useState(false);
    const [defaultHistory, setDefaultHistory] = useState([]);
    const [defaultHuman, setDefaultHuman] = useState([]);
    const textareaRef = useRef(null);

    const [open_run_chat, set_open_run_chat] = useState(false);
    const [run_History, set_run_History] = useState([]);
    const [run_Human, set_run_Human] = useState([]);
    const textareaRef_run = useRef(null);

    const [loading_message, setLoading_message] = useState("");
    const [loading, setLoading] = useState(false);



    const adjustTextareaRows = () => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = "auto"; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content
        }
    };


    const handlekeydown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevents the default behavior of the Enter key
            setDefaultHistory([...defaultHistory, { ai: "thinking... ", human: defaultHuman }]);
            setDefaultHuman("")
            handleChat()

        }
    };


    const handlekeydown_run = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevents the default behavior of the Enter key
            set_run_History([...run_History, { ai: "thinking... ", human: run_Human }]);
            set_run_Human("")
            handleChatRun()
        }
    };



    const handleSortByAccuracy = () => {
        const sortedCombinations = [...chatbot?.combinations].sort((a, b) => {
            // Assuming that accuracy is a number and directly comparable
            const accuracyA = a.generated_responses[0]?.rating || 0;
            const accuracyB = b.generated_responses[0]?.rating || 0;

            if (sortOrder === 'asc') {
                return accuracyA - accuracyB;
            } else {
                return accuracyB - accuracyA;
            }
        });

        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle the sort order for the next click
        setCombinations(sortedCombinations);
    };


    const handleMakeDefault = async (combination) => {
        const data = { bot_id: bot_id, combination_id: combination._id["$oid"] };
        const response = await dispatch(makeDefault(data));

    }


    const handleChat = async () => {
        if (defaultHuman.length > 0) {
            setDefaultHistory([...defaultHistory, { human: defaultHuman, ai: "Generating ..." }])
            const token = JSON.parse(localStorage.getItem("token"))
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    // Other headers if needed
                },
            };
            const orgId = organization?.organization?._id["$oid"]
            axios
                .post(
                    `/api/eval_bot/chatdefault/${orgId}/${user_id}/${bot_id}`,
                    { query: defaultHuman },
                    config
                )
                .then((response) => {
                    setDefaultHistory([...defaultHistory, { ai: response.data.answer, human: defaultHuman }]);
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            alert("Please type any query!")
        }
    }



    const handleChatRun = async () => {
        if (run_Human.length > 0) {
            setDefaultHistory([...run_History, { human: run_Human, ai: "Generating ..." }])
            const token = JSON.parse(localStorage.getItem("token"))
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    // Other headers if needed
                },
            };
            const orgId = organization.organization._id["$oid"];
            axios
                .post(
                    `/api/eval_bot/chatcombination/${orgId}/${user_id}/${bot_id}/${metrics._id['$oid']}`,
                    { query: run_Human },
                    config
                )
                .then((response) => {
                    set_run_History([...run_History, { ai: response.data.answer, human: run_Human }]);
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            alert("Please type any query!")
        }
    }


    const handleReload = async () => {
        await setLoading_message("Getting combinations, please wait.");
        await setLoading(true);
        await reloadUser();
        await reload();

        await setLoading(false);
        await setLoading_message("");

        setCombinations(chatbot?.combinations);
    }


    const handleRun = (combination) => {
        set_metrics(combination);
        set_open_run_chat(true)
    }

    const handleRunAll = async () => {
        setLoading_message("We're testing all combinations with the golden responses. Hang tight, we'll have your optimized results ready shortly.")
        setLoading(true);

        // Check the user's subscription type and set a limit accordingly
        const limit = user?.user?.subscription === "starter" ? 5 : chatbot?.selected_combinations.length;
        const combinationsToRun = chatbot?.selected_combinations.slice(0, limit); // Slice the array to run only a specific number of combinations based on the limit

        for (const combination of combinationsToRun) {
            try {
                let combination_id = combination._id['$oid'];
                await dispatch(runEvals({ user_id, bot_id, combination_id }));
            } catch (error) {
                console.error('Error running evaluation:', error);
                // Handle error appropriately
            }
        }

        setLoading(false);

        if (user?.user?.subscription === "starter" && chatbot?.selected_combinations?.length > 5) {
            alert("Your current Starter Package allows a maximum of 5 combination runs. \nTo access more, please subscribe to the Enterprise Package.")
        }

        await handleReload();
    };



    return (
        <div className='postgrid'>
            {loading &&
                <div className='loading-prompt'>
                    <div className="loading-spinner"></div>
                    <p>{loading_message}</p>
                </div>

            }
            <Dialog fullScreen open={openChatDefault} onClose={() => setOpenChatDefault(false)}>
                <div className='chat-container'>
                    <div className='action-back'>
                        <button onClick={() => setOpenChatDefault(false)}>X</button>
                    </div>

                    <div className='chat-point'>
                        {defaultHistory.map((msg, index) => (
                            <div key={index}>
                                <div className="human-message">
                                    <img src={userIcon} alt="user" />
                                    <p>{msg.human}</p>
                                </div>
                                <div className="ai-message">
                                    <img src={queryloopIcon} alt="queryloop" />
                                    <p>{msg.ai}</p>
                                </div>
                            </div>
                        ))}
                        <div>

                            <textarea
                                ref={textareaRef}
                                value={defaultHuman}
                                onChange={(e) => {
                                    setDefaultHuman(e.target.value);
                                    adjustTextareaRows();
                                }}
                                autoFocus={true}
                                onKeyDown={handlekeydown}
                                placeholder="Start typing"
                                className="user-input-area"

                            ></textarea>
                        </div>
                    </div>
                    <div></div>
                </div>
            </Dialog>
            {/* *********************** Run Window ******************** */}
            <Dialog fullScreen open={open_run_chat} onClose={() => set_open_run_chat(false)}>
                <div className='chat-container'>
                    <div className='action-back'>
                        <button onClick={() => set_open_run_chat(false)}>X</button>
                    </div>

                    <div className='chat-point'>
                        {run_History.map((msg, index) => (
                            <div key={index}>
                                <div className="human-message">
                                    <img src={userIcon} alt="user" />
                                    <p>{msg.human}</p>
                                </div>
                                <div className="ai-message">
                                    <img src={queryloopIcon} alt="queryloop" />
                                    <p>{msg.ai}</p>
                                </div>
                            </div>
                        ))}
                        <div>

                            <textarea
                                ref={textareaRef_run}
                                value={run_Human}
                                onChange={(e) => {
                                    set_run_Human(e.target.value);
                                    adjustTextareaRows();
                                }}
                                autoFocus={true}
                                onKeyDown={handlekeydown_run}
                                placeholder="Start typing"
                                className="user-input-area"

                            ></textarea>
                        </div>
                    </div>
                    <div></div>
                </div>
            </Dialog>

            <div className='grid-btns'>
                <div className='run-btns'>
                    {chatbot?.selected_combinations[chatbot?.combinationUsed]?.completed === false && <button onClick={handleRunAll}>Run All</button>}

                </div>
                {
                    chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.default_combination &&
                    <button className='chat-default' onClick={() => setOpenChatDefault(true)}>Test deployed version</button>
                }
                {
                    chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.default_combination &&
                    <button className='chat-default' onClick={() => {
                        localStorage.setItem("valueIndex", 3);
                        setTabName("settingsPage")
                    }}>Generate API Key</button>
                }
                <button className='refresh-btn' onClick={handleReload}>Refresh</button>
            </div>

            <table style={{ overflowX: "auto", display: "flex", flexDirection: "column" }} >
                <thead>
                    <tr>
                        <th align="left">#</th>
                        <th align="left">Prompt</th>
                        <th align="left">Chunk Size</th>
                        <th align="left">Metric Type</th>
                        <th align="left">Retrieval Method</th>
                        <th align="left">Metadata Filtering</th>
                        <th align="left">Post Retrieval</th>
                        <th align="left">Top K</th>
                        <th align="left">LLM</th>
                        <th align="left">Embedding Model</th>
                        <th align="left" style={{ cursor: "pointer" }} onClick={handleSortByAccuracy}>
                            Accuracy {sortOrder === 'asc' ? '↑' : '↓'}
                        </th>
                        <th align="left"></th>
                    </tr>
                </thead>
                <tbody>
                    {(chatbot?.selected_combinations?.length > 0 ? chatbot?.selected_combinations : []).map((combination, index) => {

                        return <tr key={index}>
                            <td align="right" >{index}</td>
                            <td align="right" >{combination?.prompt && combination?.prompt.substring(0, 100) + "..."}</td>
                            <td align="right" >{combination.chunksize}</td>
                            <td align="right" >{combination.metric}</td>
                            <td align="right" >{combination.retrieval_method === 'complex-to-simple-query' ? 'deconstruction' : combination.retrieval_method}</td>
                            <td align="right" >{`${combination.metadata_filtering}`}</td>
                            <td align="right" >{combination.rerank_method}</td>
                            <td align="right" >{combination.top_k}</td>
                            <td align="right" >{combination.llm}</td>
                            <td align="right" >{combination.embedding_model}</td>
                            <td align="right" >{combination?.average_rating}</td>
                            <td align="right" >
                                {combination?.completed === true ? <div className='table-actions'>
                                    {
                                        organization.organization.subscription !== "starter" && <button className='run-btn' onClick={() => handleRun(combination)}>Run</button>
                                    }
                                    {chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.default_combination && chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.default_combination["$oid"] === combination?._id["$oid"] ?
                                        organization.organization.subscription !== "starter" ? <button className='deploy-btn-disabled' disabled>Deployed</button> : <button className='deploy-btn-disabled' disabled>Update Package</button>
                                        :
                                        organization.organization.subscription !== "starter" ? <button className='deploy-btn' onClick={() => handleMakeDefault(combination)}>Deploy</button> : <button className='deploy-btn-disabled' disabled>Update Package</button>
                                    }

                                </div>
                                    :
                                    organization?.organization?.subscription === "starter" ?
                                        <div className='table-actions-subscribe'>
                                            <button className='btn-view-grid-details btn-subscribe-to-view' onClick={() => {
                                                localStorage.setItem("valueIndex", "2");
                                                setTabName("settingsPage")
                                            }}>Subscribe to test & deploy</button>
                                        </div>
                                        :
                                        <p>Invalid Combination</p>
                                }
                            </td>
                        </tr>
                    })
                    }
                    {/* ************************************* RAG ***************************************** */}

                    {(chatbot?.combinations?.length > 0 && chatbot?.combinations).map((combination, index) => {

                        return <tr key={index}>
                            <td align="right" >{(index + chatbot?.selected_combinations.length)}</td>
                            <td align="right" >{chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.rqa_prompt && chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.rqa_prompt.substring(0, 100) + "..."}</td>
                            <td align="right" >{combination.chunksize}</td>
                            <td align="right" >{combination.metric}</td>
                            <td align="right" >{combination.retrieval_method === 'complex-to-simple-query' ? 'deconstruction' : combination.retrieval_method}</td>
                            <td align="right" >{`${combination.metadata_filtering}`}</td>
                            <td align="right" >{combination.rerank_method}</td>
                            <td align="right" >{combination.top_k}</td>
                            <td align="right" >{combination.llm}</td>
                            <td align="right" >{combination.embedding_model}</td>
                            <td align="right" >{combination?.average_rating}</td>
                            <td align="right" >
                                {combination?.completed === true ? <div className='table-actions'>
                                    {
                                        organization.organization.subscription !== "starter" && <button className='run-btn' onClick={() => handleRun(combination)}>Run</button>
                                    }
                                    {chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.default_combination && chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?.default_combination["$oid"] === combination?._id["$oid"] ?
                                        organization.organization.subscription !== "starter" ? <button className='deploy-btn-disabled' disabled>Deployed</button> : <button className='deploy-btn-disabled' disabled>Update Package</button>
                                        :
                                        organization.organization.subscription !== "starter" ? <button className='deploy-btn' onClick={() => handleMakeDefault(combination)}>Deploy</button> : <button className='deploy-btn-disabled' disabled>Update Package</button>
                                    }

                                </div>
                                    :
                                    organization?.organization?.subscription === "starter" ?
                                        <div className='table-actions-subscribe'>
                                            <button className='btn-view-grid-details btn-subscribe-to-view' onClick={() => {
                                                localStorage.setItem("valueIndex", "2");
                                                setTabName("settingsPage")
                                            }}>Subscribe to test & deploy</button>
                                        </div>
                                        :
                                        <p>Invalid Combination</p>
                                }
                            </td>
                        </tr>
                    })
                    }


                </tbody>
            </table>
        </div>
    )
}

export default TestDeploy