import React, { useEffect, useRef, useState } from 'react'
import DetailsView from '../../../../Components/DetailsView/DetailsView'
import "./PromptGrid.css";
import { useDispatch, useSelector } from 'react-redux'
import { runEvals } from '../../../../features/chatbots/chatbotSlice'
import { Dialog } from '@mui/material';
import userIcon from "../../../../Assets/user.png"
import queryloopIcon from "../../../../Assets/queryloop.png"
import axios from 'axios';


const PostPromptGrid = ({ selected_combinations, set_selected_combinations, setValue, bot_id, reload, setTabName }) => {
    const organization = useSelector(state => state.organization)
    const { user } = useSelector(state => state.auth);
    const [open_details, set_open_details] = useState(false);
    const [metrics, set_metrics] = useState({});
    const [all, setAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading_message, setLoading_message] = useState("");
    const [openChat, setOpenChat] = useState(false);
    const dispatch = useDispatch();
    const chatbot = useSelector(state => state.chatbot);
    const [openChatDefault, setOpenChatDefault] = useState(false);

    const [defaultHistory, setDefaultHistory] = useState([]);
    const [defaultHuman, setDefaultHuman] = useState([]);
    const textareaRef = useRef(null);


    const [open_sidebar, set_open_sidebar] = useState(false);

    useEffect(() => {
        if (chatbot.selected_combinations.length > 0 && (chatbot?.combinations[0]?.combination_set["$oid"] === chatbot?.chatbots?.combinations[chatbot?.combinationUsed]?._id["$oid"])) {
            set_selected_combinations(chatbot?.selected_combinations || []);
        }
    }, [chatbot?.selected_combinations])

    const handleClose = () => {
        set_open_details(false)
    }

    const handleOpenDetails = (index) => {
        set_metrics(selected_combinations[index])
        set_open_details(true)
    }

    const handleRunAll = async () => {
        setLoading_message("We're testing all combinations with the golden responses. Hang tight, we'll have your optimized results ready shortly.");
        setLoading(true);
        const user_id = user?.user?._id["$oid"];
        const orgId = organization.organization._id["$oid"];
        for (const combination of chatbot?.selected_combinations) {
            try {

                let combination_id = combination._id['$oid'];
                const response = await dispatch(runEvals({ orgId, user_id, bot_id, combination_id }));
                await reload();
            } catch (error) {
                console.error('Error running evaluation:', error);
                // Handle error appropriately
            }
        }
        setLoading_message("");
        setLoading(false);
    };


    const adjustTextareaRows = () => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = "auto"; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content
        }
    };



    const handlekeydown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevents the default behavior of the Enter key
            setDefaultHistory([...defaultHistory, { ai: "thinking... ", human: defaultHuman }]);
            setDefaultHuman("")
            handleChat()

        }
    };



    const handleChat = async () => {
        if (defaultHuman.length > 0) {
            setDefaultHistory([...defaultHistory, { human: defaultHuman, ai: "Generating ..." }])
            const token = JSON.parse(localStorage.getItem("token"))
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    // Other headers if needed
                },
            };
            const orgId = organization.organization._id["$oid"];
            const user_id = user?.user?._id["$oid"];

            axios
                .post(
                    `/api/eval_bot/chatdefault/${orgId}/${user_id}/${bot_id}`,
                    { query: defaultHuman },
                    config
                )
                .then((response) => {
                    setDefaultHistory([...defaultHistory, { ai: response.data.answer, human: defaultHuman }]);
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            alert("Please type any query!")
        }
    }

    const isAnyCombinationCompleted = () => {
        return chatbot?.combinations?.some(combination => combination?.completed === true);
    };

    return (
        <div className='prompt-reader-container'>
            <DetailsView open_sidebar={open_sidebar} set_open_sidebar={set_open_sidebar} open={open_details} handleClose={handleClose} metrics={metrics} openChat={openChat} setOpenChat={setOpenChat} bot_id={bot_id} user_id={user?.user?._id["$oid"]} />
            <Dialog fullScreen open={openChatDefault} onClose={() => setOpenChatDefault(false)}>
                <div className='chat-container'>
                    <div className='action-back'>
                        <button onClick={() => setOpenChatDefault(false)}>X</button>
                    </div>

                    <div className='chat-point'>
                        {defaultHistory.map((msg, index) => (
                            <div key={index}>
                                <div className="human-message">
                                    <img src={userIcon} alt="user" />
                                    <p>{msg.human}</p>
                                </div>
                                <div className="ai-message">
                                    <img src={queryloopIcon} alt="queryloop" />
                                    <p>{msg.ai}</p>
                                </div>
                            </div>
                        ))}
                        <div>

                            <textarea
                                ref={textareaRef}
                                value={defaultHuman}
                                onChange={(e) => {
                                    setDefaultHuman(e.target.value);
                                    adjustTextareaRows();
                                }}
                                autoFocus={true}
                                onKeyDown={handlekeydown}
                                placeholder="Start typing"
                                className="user-input-area"

                            ></textarea>
                        </div>
                    </div>
                    <div></div>
                </div>
            </Dialog>

            {loading &&
                <div className='loading-prompt'>
                    <div className="loading-spinner"></div>
                    <p>{loading_message}</p>
                </div>
            }
            <div className='prompt-reader'>

                <div className='prompt-reader-actions'>
                </div>
                <div className='grid-btns'>
                    <div className='run-btns'>
                        {chatbot?.selected_combinations[0]?.completed === false && <button onClick={handleRunAll}>Run All</button>}

                    </div>
                    <div className='edit-bot'>
                        <button onClick={() => setValue("3")}>Change Selections</button>
                    </div>
                </div>
                <div className="scrollable-sticky-head">
                    <div className="scrollable-table-container">
                        <table className='prompt-table'>
                            <thead>
                                <tr>
                                    <th>Prompt</th>
                                    <th>Chunk Size</th>
                                    <th>Metric</th>
                                    <th>Retrieval Method</th>
                                    <th>Metadata Filtering</th>
                                    <th>Post Retrieval</th>
                                    <th>Top K</th>
                                    <th>LLM</th>
                                    <th>Embedding Model</th>
                                    <th>Accuracy</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(selected_combinations?.length === chatbot?.selected_combinations?.length ? chatbot?.selected_combinations : selected_combinations).map((combination, index) => (
                                    <tr key={index}>
                                        <td className="prompt-cell" style={{ width: "20%" }}>

                                            {combination?.prompt?.length > 0 ?
                                                all === true ? <span style={{ cursor: "pointer" }} onClick={() => setAll((prev) => !prev)}>{combination?.prompt}</span> : <>{combination?.prompt?.substring(0, 50)} <span style={{ cursor: "pointer" }} onClick={() => setAll((prev) => !prev)}>...</span></>
                                                :
                                                <p style={{ fontWeight: "bolder", color: '#b5202f' }}>Prompts not selected. Please go back and generate prompts to select prompts.</p>
                                            }
                                        </td>
                                        <td>{combination?.chunksize}</td>
                                        <td>{combination?.metric}</td>
                                        <td>{combination?.retrieval_method === 'complex-to-simple-query' ? 'deconstruction' : combination?.retrieval_method}</td>
                                        <td>{combination?.metadata_filtering}</td>
                                        <td>{combination?.rerank_method}</td>
                                        <td>{combination?.top_k}</td>
                                        <td>{combination?.llm}</td>
                                        <td>{combination?.embedding_model}</td>
                                        <td>{combination?.average_rating}</td>
                                        <td align="right" >
                                            {combination?.completed ?
                                                <div className='table-actions'>
                                                    <button className='btn-view-grid-details btn-view' onClick={() => handleOpenDetails(index)}>View</button>
                                                </div> :
                                                user?.user?.subscription === "starter" && isAnyCombinationCompleted && <div className='table-actions-subscribe'>
                                                    <button className='btn-view-grid-details btn-subscribe-to-view' onClick={() => {
                                                        localStorage.setItem("valueIndex", "2");
                                                        setTabName("settingsPage")
                                                    }}>Subscribe to run more</button>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PostPromptGrid