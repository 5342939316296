import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import './Usage.css';

const Usage = ({ usage }) => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [items, setItems] = useState([]);
  const [costs, setCosts] = useState({});

  useEffect(() => {
    if (usage && usage.usage) {
      const months = usage.usage.map((item) => item.month);
      setItems(months);

      const newCosts = {};
      usage.usage.forEach((item) => {
        newCosts[item.month] = {
          pinecone_cost: parseFloat(item.pinecone_cost).toFixed(2),
          llm_cost: parseFloat(item.llm_cost).toFixed(2),
        };
      });
      setCosts(newCosts);
    }
  }, [usage]);

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <div className='usage'>
      <Typography variant="h6" component="h2" sx={{ marginBottom: 2, color: "white" }}>
        Usage shown for external tools used by the LLM App
      </Typography>
      <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2, width: "60%", }}>
        <InputLabel id="month-select-label">Select month</InputLabel>
        <Select
          labelId="month-select-label"
          id="month-select"
          value={selectedMonth}
          onChange={handleChange}
          label="Select month"
          sx={{ backgroundColor: '#fff' }}
        >
          {items.map((month, index) => (
            <MenuItem sx={{ color: "white" }} key={index} value={month}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className='cost-details'>
        <Typography variant="body1" sx={{ marginBottom: 1., color: "white" }}>
          Pinecone Cost: <span className='cost-value'>USD {costs[selectedMonth]?.pinecone_cost || '0.00'}</span>
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1, color: "white" }}>
          LLM Cost: <span className='cost-value'>USD {costs[selectedMonth]?.llm_cost || '0.00'}</span>
        </Typography>
      </div>
    </div>
  );
};

export default Usage;
