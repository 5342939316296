import React, { useEffect, useState } from 'react'
import "./PromptReader.css"
import CustomTextArea from '../../../../Components/CustomTextArea/CustomTextArea';
import EditIcon from '@mui/icons-material/Edit';
import NavigationButtons from '../../../../Components/NavigationButtons/NavigationButtons';
import { useDispatch, useSelector } from 'react-redux';
import { generatePrompts, get_selected_combinations, initializeMutations } from '../../../../features/chatbots/chatbotSlice';


const MUTATIONS = [
    "Modify the following instruction creatively, giving some advice on how to solve it:",
    "Elaborate on the instruction giving some detailed advice on how to do what it wants",
    "As a really good teacher, explain the instruction, as if you were explaining it to a child.",
]

const THINKING_STYLES = [
    "This style involves analyzing the question from different perspectives, questioning assumptions, and evaluating the evidence or information available. It focuses on logical reasoning, evidence-based decision-making, and identifying potential biases or flaws in thinking",
    "Step back from the question, take the time for introspection and self-reflection. Examine personal biases, assumptions, and mental models that may influence problem-solving, and being open to learning from past experiences to improve future approaches",
    "Are there any relevant data or information that can provide insights into the question? If yes, what data sources are available, and how can they be analyzed?",
]


const PromptReader = ({ selected_combinations, set_selected_combinations, prompts, setPrompts, setValue, bot_id }) => {
    const chatbot = useSelector(state => state.chatbot);
    const [generated, setGenerated] = useState(false)
    const [enableAlias, setEnableAlias] = useState(true)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [loading_message, set_loading_message] = useState("Crafting customized prompts tailored to your specific needs. Hang tight, we're fetching the best selections based on your unique problem statement!")


    useEffect(() => {
        const call = async () => {
            const response = await dispatch(get_selected_combinations(bot_id));
        }

        if (chatbot?.selected_combinations?.length === 0 && !chatbot.isLoading) {
            call();
        }
    }, [])

    const _generatePrompts = async () => {
        set_loading_message("Crafting customized prompts tailored to your specific needs. Hang tight, we're fetching the best selections based on your unique problem statement!")
        setLoading(true)
        const CachedPrompts = JSON.parse(localStorage.getItem("prompts"))
        if (!CachedPrompts && chatbot.prompts.length === 0 && chatbot?.chatbots?.combinations[0]?.mutated_prompts?.length === 0) {
            const prompts_ = await dispatch(generatePrompts(bot_id))
            setGenerated(true)
            setLoading(false)
            const p = prompts_.payload.prompts.map((prompt) => { return { "prompt": prompt, promptChecked: false } })
            setPrompts(p)
        } else if (chatbot.prompts.length > 0) {
            // console.log(CachedPrompts.prompts)
            const prompts_ = await chatbot.prompts.map((prompt) => { return { "prompt": prompt, promptChecked: false } })
            setPrompts(prompts_)
            setGenerated(true)
            setLoading(false)
        } else if (chatbot?.chatbots?.combinations[0]?.mutated_prompts?.length > 0) {
            const prompts_ = await chatbot?.chatbots?.combinations[0]?.mutated_prompts?.map((prompt) => { return { "prompt": prompt, promptChecked: false } })
            setPrompts(prompts_)
            setGenerated(true)
            setLoading(false)
        } else {
            const prompts_ = await CachedPrompts?.prompts?.map((prompt) => { return { "prompt": prompt, promptChecked: false } })
            setPrompts(prompts_)
            setGenerated(true)
            setLoading(false)
        }
    }

    useEffect(() => {

        _generatePrompts();

    }, [])

    const handleChangePrompts = (e, index) => {
        const newPrompt = prompts;
        newPrompt[index]["prompt"] = e.target.value;
        setPrompts([...newPrompt]);
    }

    const handleChangeAlias = (e, index) => {
        const newPrompt = prompts;
        newPrompt[index]["alias"] = e.target.value;
        setPrompts([...newPrompt]);
    }

    const handleChangePromptSelect = (event, index) => {
        const newPrompt = prompts;
        newPrompt[index].promptChecked = event.target.checked;
        setPrompts([...newPrompt]);
    }

    const handleSelectAll = () => {
        const updatedPrompts = prompts.map(prompt => {
            return { ...prompt, promptChecked: true };
        });
        setPrompts(updatedPrompts);
    }

    const handleSetCombinations = async () => {

        set_loading_message("Configuring the optimal combinations with your preferences in mind. Please wait this can take a few minutes!")
        setLoading(true)
        // Filter the prompts to only include those with promptChecked set to true
        const selectedCombinations = prompts.filter(prompt => prompt.promptChecked === true);

        let newCombinations = [];

        selectedCombinations.forEach(prompt => {
            selected_combinations.forEach(combination => {
                const newCombination = { ...combination, checked: false, prompt: prompt.prompt };
                const combinationString = JSON.stringify(newCombination);

                if (!newCombinations.some(comb => JSON.stringify(comb) === combinationString)) {
                    newCombinations.push(newCombination);
                }
            });
        });
        if (newCombinations.length === 0) {
            if (chatbot?.selected_combinations?.length > 0) {
                setValue("4");
                return;
            }
            alert("Please select atleast one prompt to proceed!");
            setLoading(false);
            return;
        }
        const combinations_ = Array.from(new Set(newCombinations.map(c => c._id['$oid'])));
        const prompts_ = Array.from(new Set(newCombinations.map(c => c.prompt)));

        const data = {
            prompts: prompts_,
            combinations: combinations_,
            bot_id
        }

        try {
            const init_mutations = await dispatch(initializeMutations(data))
            // Update the state with the new unique combinations
            console.log(init_mutations.payload.selected_combinations)
            await dispatch(get_selected_combinations(bot_id))
            set_selected_combinations(init_mutations.payload.selected_combinations);

            setTimeout(() => {
                set_loading_message("Just a moment, we're almost there!")
                setLoading(false)
            }, 2000)
            setValue("4")
        } catch (error) {
            alert("Error generating mutations with selected prompt.\nPlease try again.")
        }

    }

    return (
        <div className='prompt-reader-container'>
            {loading === true ?
                <div className='loading-prompt'>
                    <div className="loading-spinner"></div>
                    <p>{loading_message}</p>
                </div>
                :
                <div className='postgrid-info'>
                    <p>Prompts are automatically generated by integrating mutations and thinking styles with your base prompt.</p>
                </div>
            }

            <div className='prompt-reader'>
                {generated && <div className='navs_' style={{ marginBottom: '-50px' }}>
                    <button className='btn-selectall' onClick={handleSelectAll}>Select All</button>
                    <button className='next' onClick={handleSetCombinations}>Next</button>
                </div>}
                <div className='prompt-reader-actions'>

                </div>

                {generated && <div className='prompt-cards'>
                    {prompts?.map((prompt, index) => {
                        // Calculate the indexes for mutations and thinking styles
                        const mutationIndex = Math.floor(index / THINKING_STYLES.length);
                        const thinkingStyleIndex = index % THINKING_STYLES.length;

                        return (
                            <div key={index} className={`prompt-card ${prompt.promptChecked === true ? 'prompt-card-bordered' : ''}`} onClick={(e) => handleChangePromptSelect(e, index)}>
                                <div className="input-icon-wrapper">
                                    <div className='prompt-selector'>
                                        <input type='checkbox' value={prompt.promptChecked} checked={prompt.promptChecked} onChange={(e) => handleChangePromptSelect(e, index)} />
                                    </div>
                                </div>
                                <p className='title-prompt'>
                                    <b>Mutation:</b> {MUTATIONS[mutationIndex]}<br /><br />
                                    <b>Thinking Style:</b> {THINKING_STYLES[thinkingStyleIndex]}
                                </p>
                                <p style={{ marginBottom: "-20px", marginLeft: "5px", padding: "0px", fontSize: '0.8rem', fontWeight: '600', fontFamily: "Oxygen, sans-serif", color: "rgb(67, 67, 67)" }}>Prompt</p>
                                <CustomTextArea rows={10} value={prompt["prompt"]} handleChangeInput={(e) => handleChangePrompts(e, index)} customStyles={{ fontSize: "0.9rem", maxHeight: "300px", width: "100%" }} />
                            </div>
                        );
                    })}
                </div>}

                {generated && <div className='navs'>
                    <NavigationButtons previousName={"Previous"} handlePrevious={() => setValue("3")} handleNext={handleSetCombinations} />
                </div>}
            </div>
        </div>
    )
}


export default PromptReader