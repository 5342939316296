

const handleReferenceChange = (checked, setData, setBotData) => {

    setBotData(prevBotData => ({
        ...prevBotData,
        references: checked
    }));

    setData(prevBotData => ({
        ...prevBotData,
        references: checked
    }));
};



const handleTWRChange = (checked, setData, setBotData) => {

    if (checked === true) {
        setBotData((prevBotData) => ({
            ...prevBotData,
            recency_text: "\nPrioritize recent data over old.",
            recency: true
        }));
        setData((prevBotData) => ({
            ...prevBotData,
            recency_text: "\nPrioritize recent data over old.",
            recency: true
        }));
    } else {
        setBotData((prevBotData) => ({
            ...prevBotData,
            recency_text: "",
            recency: false
        }));
        setData((prevBotData) => ({
            ...prevBotData,
            recency_text: "",
            recency: false
        }));
    }

};





const handleResponseLengthChange = (checked, name, setData, setBotData) => {

    setBotData(prevBotData => {
        // Update the response_length
        const updatedResponseLength = {
            "short-sentences": name === "short-sentences",
            "detailed-answers": name === "detailed-answers",
            "no-preference": name === "no-preference",
        };

        // Construct the updated problem statement
        let updatedProblemStatement = "";
        // if (name === "short-sentences") {
        //   updatedProblemStatement = updatedProblemStatement.replace("\nProvide long detailed answers.", "");
        //   if (!updatedProblemStatement.includes("\nProvide short answers only.")) {
        //     updatedProblemStatement += "\nProvide short answers only.";
        //   }
        // } else if (name === "detailed-answers") {
        //   updatedProblemStatement = updatedProblemStatement.replace("\nProvide short answers only.", "");
        //   updatedProblemStatement = updatedProblemStatement.replace("\nProvide exactly to-the-point answers..", "");
        //   if (!updatedProblemStatement.includes("\nProvide long detailed answers.")) {
        //     updatedProblemStatement += "\nProvide long detailed answers.";
        //   }
        // }
        // Return the updated state
        if (name === "short-sentences") {
            updatedProblemStatement = "\nProvide concise answers only."
        } else if (name === "detailed-answers") {
            updatedProblemStatement = "\nProvide long detailed answers."
        }
        else if (name === "no-preference") {
            updatedProblemStatement = ""
        }
        return {
            ...prevBotData,
            response_length: updatedResponseLength,
            response_length_text: updatedProblemStatement
        };

    });

    setData(prevBotData => {

        const updatedResponseLength = {
            "short-sentences": name === "short-sentences",
            "detailed-answers": name === "detailed-answers",
            "no-preference": name === "no-preference",
        };

        let updatedProblemStatement = "";
        if (name === "short-sentences") {
            updatedProblemStatement = "\nProvide concise answers only."
        } else if (name === "detailed-answers") {
            updatedProblemStatement = "\nProvide long detailed answers."
        }
        else if (name === "no-preference") {
            updatedProblemStatement = ""
        }
        return {
            ...prevBotData,
            response_length: updatedResponseLength,
            response_length_text: updatedProblemStatement
        };

    });
};



const handleSourcesChange = (checked, setData, setBotData) => {
    setBotData(prevBotData => {
        // Initialize the updated problem statement from the previous state
        let updatedProblemStatement = "";

        // If checked is true, add the specific string if it's not already present
        if (checked) {
            updatedProblemStatement += "\nReturn sources of the documents used in context.";
            // if (!updatedProblemStatement.includes("\nReturn sources of the documents used in context.")) {
            //   updatedProblemStatement += "\nReturn sources of the documents used in context.";
            // }
        }
        // If checked is false, remove the specific string if it's present
        else {
            updatedProblemStatement = ""
            // updatedProblemStatement = updatedProblemStatement.replace("\nReturn sources of the documents used in context.", "");
        }

        // Return the updated state
        return {
            ...prevBotData,
            sources: checked,
            sources_text: updatedProblemStatement
        };
    });

    setData(prevBotData => {

        let updatedProblemStatement = "";


        if (checked) {
            updatedProblemStatement += "\nReturn sources of the documents used in context.";

        }
        else {
            updatedProblemStatement = ""
        }

        return {
            ...prevBotData,
            sources: checked,
            sources_text: updatedProblemStatement
        };
    });
};




const handleToneChange = (checked, name, setData, setBotData) => {
    if (checked) {
        setBotData(prevBotData => {
            let updatedProblemStatement = "";

            // Remove existing tone-related strings
            // updatedProblemStatement = updatedProblemStatement.replace("\nGenerate responses in formal tone.", "");
            // updatedProblemStatement = updatedProblemStatement.replace("\nGenerate responses in informal tone.", "");

            // Add the new tone string based on the selected name
            if (name === "formal") {
                updatedProblemStatement += "\nGenerate responses in formal tone.";
            } else if (name === "informal") {
                updatedProblemStatement += "\nGenerate responses in informal tone.";
            } else if (name === "no-preference") {
                // updatedProblemStatement = updatedProblemStatement.replace("\nGenerate responses in formal tone.", "");
                // updatedProblemStatement = updatedProblemStatement.replace("\nGenerate responses in informal tone.", "");
                updatedProblemStatement = "";
            }

            // Return the updated state
            return {
                ...prevBotData,
                tone: {
                    "formal": name === "formal",
                    "informal": name === "informal",
                    "no-preference": name === "no-preference",
                },
                tone_text: updatedProblemStatement
            };
        });
        setData(prevBotData => {
            let updatedProblemStatement = "";


            if (name === "formal") {
                updatedProblemStatement += "\nGenerate responses in formal tone.";
            } else if (name === "informal") {
                updatedProblemStatement += "\nGenerate responses in informal tone.";
            } else if (name === "no-preference") {

                updatedProblemStatement = "";
            }

            return {
                ...prevBotData,
                tone: {
                    "formal": name === "formal",
                    "informal": name === "informal",
                    "no-preference": name === "no-preference",
                },
                tone_text: updatedProblemStatement
            };
        });
    }
};





const handleSentenceWindowChange = (checked, setData, setBotData) => {
    setBotData(prevBotData => ({
        ...prevBotData,
        parameter_key_names: {
            ...prevBotData.parameter_key_names,
            retrieval_method: {
                ...prevBotData.parameter_key_names.retrieval_method,
                "sentence-window": checked
            }
        }
    }));
    setData(prevBotData => ({
        ...prevBotData,
        parameter_key_names: {
            ...prevBotData.parameter_key_names,
            retrieval_method: {
                ...prevBotData.parameter_key_names.retrieval_method,
                "sentence-window": checked
            }
        }
    }));
};



const handleMMRChange = (checked, setData, setBotData) => {
    setBotData(prevBotData => ({
        ...prevBotData,
        parameter_key_names: {
            ...prevBotData.parameter_key_names,
            rerank_method: {
                ...prevBotData.parameter_key_names.rerank_method,
                "mmr": checked
            }
        }
    }));
    setData(prevBotData => ({
        ...prevBotData,
        parameter_key_names: {
            ...prevBotData.parameter_key_names,
            rerank_method: {
                ...prevBotData.parameter_key_names.rerank_method,
                "mmr": checked
            }
        }
    }));
};



const handleLatencyChange = (checked, name, setData, setBotData) => {
    setBotData(prevBotData => {
        // Determine the new state for the "multi-query" based on the radio button selected
        let newMultiQueryValue;
        if (name === "critical") {
            // Assuming "multi-query" should be true when "critical" is selected
            newMultiQueryValue = false;
        } else if (name === "noCritical") {
            // Assuming "multi-query" should be false when "noCritical" is selected
            newMultiQueryValue = true;
        } else {
            // Default or error handling
            newMultiQueryValue = prevBotData.parameter_key_names.retrieval_method["multi-query"];
        }

        return {
            ...prevBotData,
            latency: {
                "critical": name === "critical",
                "noCritical": name === "noCritical",
            },
            parameter_key_names: {
                ...prevBotData.parameter_key_names,
                retrieval_method: {
                    ...prevBotData.parameter_key_names.retrieval_method,
                    "multi-query": newMultiQueryValue,
                },
            },
        };
    });
    setData(prevBotData => {
        // Determine the new state for the "multi-query" based on the radio button selected
        let newMultiQueryValue;
        if (name === "critical") {
            // Assuming "multi-query" should be true when "critical" is selected
            newMultiQueryValue = false;
        } else if (name === "noCritical") {
            // Assuming "multi-query" should be false when "noCritical" is selected
            newMultiQueryValue = true;
        } else {
            // Default or error handling
            newMultiQueryValue = prevBotData.parameter_key_names.retrieval_method["multi-query"];
        }

        return {
            ...prevBotData,
            latency: {
                "critical": name === "critical",
                "noCritical": name === "noCritical",
            },
            parameter_key_names: {
                ...prevBotData.parameter_key_names,
                retrieval_method: {
                    ...prevBotData.parameter_key_names.retrieval_method,
                    "multi-query": newMultiQueryValue,
                },
            },
        };
    });
};



const handleMemoryChange = (checked, setData, setBotData) => {
    setBotData(prevBotData => ({
        ...prevBotData,
        have_memory: checked,
    }));

    setData(prevBotData => ({
        ...prevBotData,
        have_memory: checked,
    }));

};




export {
    handleReferenceChange,
    handleTWRChange,
    handleResponseLengthChange,
    handleSourcesChange,
    handleToneChange,
    handleSentenceWindowChange,
    handleMMRChange,
    handleLatencyChange,
    handleMemoryChange
}